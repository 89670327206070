import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from 'axios'
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";
import './PittSleepQuality.css'

function PittSleepQuality() {

  const [highlightedLabel, setHighlightedLabel] = useState(null);
  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)
            
            if (data.filter(data => data.testId === 'pitt').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'pitt')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)
                if (testDate === currDate) {
                  setSubmittedToday(true)
                  setSubmitted(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }
            
        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
    
  }, [])

  const baseUrl = process.env.REACT_APP_baseUrl;
  const baseUrlAWS =`${baseUrl}api/myprogress`
  let navigate = useNavigate();

  const[submitted, setSubmitted] = useState(false)
  const[score, setScore] = useState()

  // sleep measures
  const [timeToBed, setTimeToBed] = useState("");
  const [durationToBed, setDurationToBed] = useState('');
  const [timeWakeUp, setTimeWakeUp] = useState("");
  const [totalSleep, setTotalSleep] = useState('');

  // responses for sleep measures question 5
  const [cannotSleept30, setCannotSleept30] = useState("notPastMonth");
  const [wakeNightOrMorning, setWakeNightOrMorning] = useState("notPastMonth");
  const [useBathroom, setUseBathroom] = useState("notPastMonth");
  const [cannotBreatheComfortably, setCannotBreatheComfortably] =
    useState("notPastMonth");
  const [coughSnoreLoudly, setCoughSnoreLoudly] = useState("notPastMonth");
  const [tooCold, setTooCold] = useState("notPastMonth");
  const [tooHot, setTooHot] = useState("notPastMonth");
  const [badDreams, setBadDreams] = useState("notPastMonth");
  const [havePain, setHavePain] = useState("notPastMonth");
  const [sleepOther, setSleepOther] = useState("notPastMonth");

  //6,7,8,9
  const [sleepQual, setSleepQual] = useState("veryGood")
  const [medicine, setMedicine] = useState("notPastMonth")
  const [stayAwake, setStayAwake] = useState("notPastMonth")
  const [enthusiasm, setEnthusiasm] = useState("noProblem")

  // responses for question 10
  const [partner, setPartner] = useState("NA")
  const [snoring, setSnoring] = useState("NA")
  const [pauses, setPauses] = useState("NA")
  const [twitching, setTwitching] = useState("NA")
  const [confusion, setConfusion] = useState("NA")
  const [restlessness, setRestlessness] = useState("NA")

  //for calculations
  const dict = {
    notPastMonth: 0,
    lessThanOnceWeek: 1,
    onceOrTwiceWeek: 2,
    ThreeOrMoreWeek: 3,
    veryGood: 0,
    fairlyGood: 1,
    fairlyBad: 2,
    veryBad: 3,
    noProblem: 0,
    slightProblem: 1,
    somewhatProblem: 2,
    bigProblem: 3
  }


  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

  const calculateScore = () => {
    
    //component 1
    const comp1 = dict[sleepQual]

    //component 2
    var sleepLatency = 0
    if (durationToBed > 60) {
      sleepLatency = 3
    } else if (durationToBed > 30) {
      sleepLatency = 2
    } else if (durationToBed > 15) {
      sleepLatency = 1
    }

    var sleepLatency2 = dict[cannotSleept30]
    const totalLatency = sleepLatency + sleepLatency2

    var sl = 0
    if (totalLatency > 4) {
      sl = 3
    } else if (totalLatency > 2) {
      sl = 2
    } else if (totalLatency > 0) {
      sl = 1
    }

    const comp2 = sl

    //component 3
    var sd = 0
    if (totalSleep < 5) {
      sd = 3
    } else if (totalSleep < 6) {
      sd = 2
    } else if (totalSleep < 7) {
      sd = 1
    } 

    const comp3 = sd
    

    //component 4
    var timeBed = timeToBed.split(':')
    var timeUp = timeWakeUp.split(':')
    var minsInBed = 0

    if ((+timeBed[0]) > 11) {
      let up = (+timeUp[0]) * 60 + (+timeUp[1]) + 1440
      let bed = (+timeBed[0]) * 60 + (+timeBed[1])
      minsInBed = up - bed
    } else {
      let up = (+timeUp[0]) * 60 + (+timeUp[1])
      let bed = (+timeBed[0]) * 60 + (+timeBed[1])
      minsInBed = up - bed
    }

    var minsSlept = totalSleep * 60
    const sleepEfficiency = (minsSlept/minsInBed) * 100
    var comp4 = 0
    if (sleepEfficiency < 65) {
      comp4 = 3
    } else if (sleepEfficiency < 75) {
      comp4 = 2
    } else if (sleepEfficiency < 85) {
      comp4 = 1
    }

    //component 5
    const c5 = dict[wakeNightOrMorning] + dict[useBathroom] + dict[cannotBreatheComfortably] + dict[coughSnoreLoudly] + dict[tooCold] + dict[tooHot] + dict[badDreams] + dict[havePain] + dict[sleepOther]
    
    var disturbances = 0
    if (c5 > 18) {
      disturbances = 3
    } else if (c5 > 9) {
      disturbances = 2
    } else if (c5 > 0) {
      disturbances = 1
    }

    const comp5 = disturbances

    //component 6
    const comp6 = dict[medicine]

    //component 7
    const q8 = dict[stayAwake]
    const q9 = dict[enthusiasm]

    var dysfunction = 0
    if (q8 + q9 > 4) {
      dysfunction = 3
    } else if (q8 + q9 > 2){
      dysfunction = 2 
    } else if (q8 + q9 > 0){
      dysfunction = 1
    }

    const comp7 = dysfunction

    return comp1 + comp2 + comp3 + comp4 + comp5 + comp6 + comp7 
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const s = calculateScore()
    setScore(s)
    setSubmitted(true)

    let testRes = {}
    testRes["user"] = userId
    testRes["testId"] = "pitt"
    testRes["score"] = s

    submitData(testRes)
    console.log(testRes)

  };

  // call API to submit the form data 
  const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }
  return (
    <div className="container pitt-sleep-container">
      <div>
        <h3>Pittsburgh Sleep Quality Index</h3>
        <div className="instructions">
          Instructions: The following questions relate to your usual sleep habits during the past month only. Your answers should indicate the most accurate reply for the majority of days and nights in the past month.
        </div>
      </div>
      <div>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '1' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '1' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              1. During the past month, what time have you usually gone to bed at night?
            </Form.Label>
            <Form.Control
              type="time"
              value={timeToBed}
              onChange={(e) => setTimeToBed(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('1')}
              onBlur={() => setHighlightedLabel(null)}
            />
          </Form.Group>

          <Form.Group className="form-group" style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
            <Form.Label
              style={{
                color: highlightedLabel === '2' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '2' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              2. During the past month, how long (in minutes) has it usually taken you to fall asleep each night?
            </Form.Label>
            <Form.Control
              type="number"
              value={durationToBed}
              onChange={(e) => setDurationToBed(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('2')}
              onBlur={() => setHighlightedLabel(null)}
            />
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '3' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '3' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              3. During the past month, what time have you usually gotten up in the morning?
            </Form.Label>
            <Form.Control
              type="time"
              value={timeWakeUp}
              onChange={(e) => setTimeWakeUp(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('3')}
              onBlur={() => setHighlightedLabel(null)}
            />
          </Form.Group>

          <Form.Group className="form-group" style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
            <Form.Label
              style={{
                color: highlightedLabel === '4' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '4' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              4. During the past month, how many hours of actual sleep did you get at night?
            </Form.Label>
            <Form.Control
              type="number"
              value={totalSleep}
              onChange={(e) => setTotalSleep(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('4')}
              onBlur={() => setHighlightedLabel(null)}
            />
          </Form.Group>

          <Form.Label
            style={{
              color: highlightedLabel === '5' ? '#04723A' : '#333',
              fontWeight: highlightedLabel === '5' ? 'bold' : 'normal', // Bold when selected
            }}
          >
            5. During the past month, how often have you had trouble sleeping because you...
          </Form.Label>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '5a' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '5a' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              a) Cannot get to sleep within 30 minutes
            </Form.Label>
            <Form.Control
              as="select"
              value={cannotSleept30}
              onChange={(e) => setCannotSleept30(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('5a')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '5b' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '5b' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              b) Wake up in the middle of the night or early morning
            </Form.Label>
            <Form.Control
              as="select"
              value={wakeNightOrMorning}
              onChange={(e) => setWakeNightOrMorning(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('5b')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '5c' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '5c' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              c) Have to get up to use the bathroom
            </Form.Label>
            <Form.Control
              as="select"
              value={useBathroom}
              onChange={(e) => setUseBathroom(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('5c')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '5d' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '5d' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              d) Cannot breathe comfortably
            </Form.Label>
            <Form.Control
              as="select"
              value={cannotBreatheComfortably}
              onChange={(e) => setCannotBreatheComfortably(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('5d')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '5e' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '5e' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              e) Cough or snore loudly
            </Form.Label>
            <Form.Control
              as="select"
              value={coughSnoreLoudly}
              onChange={(e) => setCoughSnoreLoudly(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('5e')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '5f' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '5f' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              f) Feel too cold
            </Form.Label>
            <Form.Control
              as="select"
              value={tooCold}
              onChange={(e) => setTooCold(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('5f')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '5g' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '5g' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              g) Feel too hot
            </Form.Label>
            <Form.Control
              as="select"
              value={tooHot}
              onChange={(e) => setTooHot(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('5g')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '5h' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '5h' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              h) Had bad dreams
            </Form.Label>
            <Form.Control
              as="select"
              value={badDreams}
              onChange={(e) => setBadDreams(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('5h')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '5i' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '5i' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              i) Have a pain
            </Form.Label>
            <Form.Control
              as="select"
              value={havePain}
              onChange={(e) => setHavePain(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('5i')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '5j' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '5j' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              j) Other reason(s)
            </Form.Label>
            <Form.Control
              as="select"
              value={sleepOther}
              onChange={(e) => setSleepOther(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('5j')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group" style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
            <Form.Label
              style={{
                color: highlightedLabel === '6' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '6' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              6. During the past month, how would you rate your sleep quality overall?
            </Form.Label>
            <Form.Control
              as="select"
              value={sleepQual}
              onChange={(e) => setSleepQual(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('6')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="veryGood">Very good</option>
              <option value="fairlyGood">Fairly good</option>
              <option value="fairlyBad">Fairly bad</option>
              <option value="veryBad">Very bad</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '7' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '7' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              7. During the past month, how often have you taken medicine to help you sleep (prescribed or "over the counter")?
            </Form.Label>
            <Form.Control
              as="select"
              value={medicine}
              onChange={(e) => setMedicine(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('7')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group" style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
            <Form.Label
              style={{
                color: highlightedLabel === '8' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '8' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              8. During the past month, how often have you had trouble staying awake while driving, eating meals, or engaging in social activity?
            </Form.Label>
            <Form.Control
              as="select"
              value={stayAwake}
              onChange={(e) => setStayAwake(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('8')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '9' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '9' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              9. During the past month, how much of a problem has it been for you to keep up enough enthusiasm to get things done?
            </Form.Label>
            <Form.Control
              as="select"
              value={enthusiasm}
              onChange={(e) => setEnthusiasm(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('9')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="noProblem">No problem at all</option>
              <option value="slightProblem">Only a slight problem</option>
              <option value="somewhatProblem">Somewhat of a problem</option>
              <option value="bigProblem">A very big problem</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group" style={{ backgroundColor: '#f0f0f0', borderRadius: '8px' }}>
            <Form.Label
              style={{
                color: highlightedLabel === '10' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '10' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              10. Do you have a bed partner or room mate?
            </Form.Label>
            <Form.Control
              as="select"
              value={partner}
              onChange={(e) => setPartner(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('10')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="NA">No bed partner or room mate</option>
              <option value="otherRoom">Partner/room mate in other room</option>
              <option value="sameRoom">Partner in same room, but not same bed</option>
              <option value="sameBed">Partner in same bed</option>
            </Form.Control>
          </Form.Group>

          <Form.Label
            style={{
              color: highlightedLabel === '11' ? '#007bff' : '#333',
            }}
          >
            If you have a room mate or bed partner, ask him/her how often in the past
            month you have had...
          </Form.Label>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '11a' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '11a' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              a) Loud snoring
            </Form.Label>
            <Form.Control
              as="select"
              value={snoring}
              onChange={(e) => setSnoring(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('11a')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="NA">No bed partner or room mate</option>
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '11b' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '11b' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              b) Long pauses between breaths while asleep
            </Form.Label>
            <Form.Control
              as="select"
              value={pauses}
              onChange={(e) => setPauses(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('11b')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="NA">No bed partner or room mate</option>
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '11c' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '11c' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              c) Legs twitching or jerking while you sleep
            </Form.Label>
            <Form.Control
              as="select"
              value={twitching}
              onChange={(e) => setTwitching(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('11c')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="NA">No bed partner or room mate</option>
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '11d' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '11d' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              d) Episodes of disorientation or confusion during sleep
            </Form.Label>
            <Form.Control
              as="select"
              value={confusion}
              onChange={(e) => setConfusion(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('11d')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="NA">No bed partner or room mate</option>
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="form-group">
            <Form.Label
              style={{
                color: highlightedLabel === '11e' ? '#04723A' : '#333',
                fontWeight: highlightedLabel === '11e' ? 'bold' : 'normal', // Bold when selected
              }}
            >
              e) Other restlessness while you sleep
            </Form.Label>
            <Form.Control
              as="select"
              value={restlessness}
              onChange={(e) => setRestlessness(e.target.value)}
              className="form-control pitt-sleep"
              onFocus={() => setHighlightedLabel('11e')}
              onBlur={() => setHighlightedLabel(null)}
            >
              <option value="NA">No bed partner or room mate</option>
              <option value="notPastMonth">Not during the past month</option>
              <option value="lessThanOnceWeek">Less than once a week</option>
              <option value="onceOrTwiceWeek">Once or twice a week</option>
              <option value="ThreeOrMoreWeek">Three or more times a week</option>
            </Form.Control>
          </Form.Group>

          <div style={{ display: "flex", justifyContent: "center", marginTop: "30px"}}>
            {(!submittedToday && !submitted) && (
              <div style={{ paddingBottom: "120px" }}>
                <Button type="submit" variant="dark" style={{ minWidth: "225px"}}>
                  Submit
                </Button>
              </div>
            )}
            {(submittedToday || submitted) && (
              <div>
                <Button type="submit" variant="dark" style={{ minWidth: "225px" }} disabled>
                  Submit
                </Button>
                <p style={{ color: "gray", fontSize: "14px", textAlign: 'center' }}>(Already submitted today)</p>
              </div>
            )}
          </div>
        </Form>
      </div>
      <div>
        {submitted && (
          <div>
            <div className="score">Score: {score}/21</div>
            <p className="score-description">(Where higher values represent worse sleep quality)</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default PittSleepQuality;