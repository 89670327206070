import React, { useState, useEffect, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

// Import components correctly
import Testchart from './Reports/report-components/Testchart';
import Sliderchart from './Reports/report-components/Sliderchart';

// Register ChartJS components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function Reports() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [firstName, setFirstName] = useState("");

    const userId = useMemo(() => {
        let user = localStorage.getItem("mysUser");
        user = user ? JSON.parse(user) : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
        return user.userId;
    }, []);

    useEffect(() => {
        const fetchUserData = async () => {
            setIsLoading(true);
            try {
                const userData = localStorage.getItem("mysUser");
                if (userData) {
                    const { firstName } = JSON.parse(userData);
                    setFirstName(firstName);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchUserData();
    }, []);

    const getStyles = () => ({
        container: {
            backgroundColor: 'white',
            borderRadius: '8px',
            padding: '24px',
            margin: '2rem',
            marginTop: '3rem',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            position: 'relative'
        },
        returnButton: {
            position: 'absolute',
            top: '20px',
            right: '20px',
            padding: '8px 16px',
            backgroundColor: '#6c757d',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            fontSize: '0.9rem',
            zIndex: 1000
        },
        title: {
            marginTop: '1rem',
            marginBottom: '2rem'
        },
        contentContainer: {
            maxWidth: '1200px',
            margin: '0 auto'
        },
        sectionTitle: {
            color: '#212529',
            fontSize: '1.1rem',
            fontWeight: 'bold',
            marginBottom: '16px'
        },
        chartContainer: {
            marginTop: '32px',
            marginBottom: '20px',
            padding: '24px',
            backgroundColor: '#f8f9fa',
            borderRadius: '8px'
        }
    });

    return (
        <div style={getStyles().container}>
            <button 
                onClick={() => navigate('/progress')}
                style={getStyles().returnButton}
            >
                Return to My Progress
            </button>
            <h2 style={getStyles().title}>Progress Reports</h2>
            <div style={getStyles().contentContainer}>
                {isLoading ? (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                ) : (
                    <Form>
                        <div className="inlineContainer">
                            Hi {firstName}, View your results and reports!
                        </div>

                        <div style={getStyles().chartContainer}>
                            <div className="inlineContainer">
                                <Form.Text style={getStyles().sectionTitle}>
                                    Test Results
                                </Form.Text>
                                <p style={{color: "gray", fontSize: "14px"}}>
                                    (Slide to View More Graphs)
                                </p>
                            </div>
                            <Form.Group>
                                <Testchart />
                            </Form.Group>
                        </div>

                        <div style={getStyles().chartContainer}>
                            <div className="inlineContainer">
                                <Form.Text style={getStyles().sectionTitle}>
                                    Daily Progress Metrics
                                </Form.Text>
                                <p style={{color: "gray", fontSize: "14px"}}>
                                    (Slide to View More Graphs)
                                </p>
                            </div>
                            <Form.Group>
                                <Sliderchart />
                            </Form.Group>
                        </div>
                    </Form>
                )}
            </div>
        </div>
    );
}

export default Reports; 