import React, { useState, useEffect, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
} from 'chart.js';
import xavierMouton from './preview-images/xavier-mouton.jpg';
import thiagoCerqueira from './preview-images/thiago-cerqueira.jpg';
import nathanDumlao from './preview-images/nathan-dumlao.jpg';
import josephGonzalez from './preview-images/joseph-gonzalez.jpg';
import maelBalland from './preview-images/maël-balland.jpg';
import jordanOpel from './preview-images/jordan-opel.jpg';
import bruceMars from './preview-images/bruce-mars.jpg';
import katherineHanlon from './preview-images/katherine-hanlon.jpg';
import samSabourin from './preview-images/sam-sabourin.jpg';
import dmitriiVaccinium from './preview-images/dmitrii-vaccinium.jpg';
import hollyMandarich from './preview-images/holly-mandarich.jpg';
import cdc from './preview-images/cdc.jpg';
import orenAtias from './preview-images/oren-atias.jpg';
import { format, startOfMonth, endOfMonth, eachDayOfInterval } from 'date-fns';

// Register ChartJS components including Filler
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

const previewImages = {
    'Xavier-Mouton': {image: xavierMouton, credit: 'Photo by Xavier Mouton on Unsplash'},
    'Thiago-Cerqueira': {image: thiagoCerqueira, credit: 'Photo by Thiago Cerqueira on Unsplash'},
    'Nathan-Dumlao': {image: nathanDumlao, credit: 'Photo by Nathan Dumlao on Unsplash'},
    'Joseph-Gonzalez': {image: josephGonzalez, credit: 'Photo by Joseph Gonzalez on Unsplash'},
    'Maël-Balland': {image: maelBalland, credit: 'Photo by Maël Balland on Unsplash'},
    'Jordan-Opel': {image: jordanOpel, credit: 'Photo by Jordan Opel on Unsplash'},
    'Bruce-Mars': {image: bruceMars, credit: 'Photo by Bruce Mars on Unsplash'},
    'Katherine-Hanlon': {image: katherineHanlon, credit: 'Photo by Katherine Hanlon on Unsplash'},
    'Sam-Sabourin': {image: samSabourin, credit: 'Photo by Sam Sabourin on Unsplash'},
    'Dmitrii-Vaccinium': {image: dmitriiVaccinium, credit: 'Photo by Dmitrii Vaccinium on Unsplash'},
    'Holly-Mandarich': {image: hollyMandarich, credit: 'Photo by Holly Mandarich on Unsplash'},
    'CDC': {image: cdc, credit: 'Photo by CDC on Unsplash'},
    'Oren-Atias': {image: orenAtias, credit: 'Photo by Oren Atias on Unsplash'}
};

const Calendar = ({ submissionsPromise }) => {
    const [submissions, setSubmissions] = useState([]);

    useEffect(() => {
        submissionsPromise.then(data => {
            setSubmissions(data);
        });
    }, [submissionsPromise]);

    const today = new Date();
    const monthStart = startOfMonth(today);
    const monthEnd = endOfMonth(today);
    const days = eachDayOfInterval({ start: monthStart, end: monthEnd });

    // Get the day of week for the first day (0 = Sunday, 1 = Monday, etc)
    const firstDayOfMonth = monthStart.getDay();
    
    // Create array for empty spaces before first day
    const emptyDays = Array(firstDayOfMonth).fill(null);

    const getUTCDay = (date) => {
        // If it's a string (from API), use it directly
        if (typeof date === 'string') {
            const utcDate = new Date(date);
            return new Date(Date.UTC(
                utcDate.getUTCFullYear(),
                utcDate.getUTCMonth(),
                utcDate.getUTCDate()
            ));
        }
        
        // If it's a Date object (from calendar), convert to UTC
        return new Date(Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate()
        ));
    };

    return (
        <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(7, minmax(0, 1fr))',
            gap: '2px',
            padding: '8px',
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            width: '100%',
            maxWidth: '600px',
            minWidth: '280px',
            margin: '0 auto',
            height: 'auto',
            aspectRatio: '1.4/1'
        }}>
            {['S', 'M', 'T', 'W', 'T', 'F', 'S'].map(day => (
                <div key={day} style={{
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: '#666',
                    padding: '2px',
                    fontSize: 'clamp(10px, 1.5vw, 14px)'
                }}>
                    {day}
                </div>
            ))}
            {/* Render empty cells for days before the first of the month */}
            {emptyDays.map((_, index) => (
                <div key={`empty-${index}`} style={{
                    aspectRatio: '1',
                    backgroundColor: 'transparent',
                    border: '1px solid transparent'
                }} />
            ))}
            {/* Render actual days */}
            {days.map(day => {
                const utcDay = getUTCDay(day);
                
                const isSubmitted = submissions.some(date => {
                    const submissionUTC = getUTCDay(date);
                    return submissionUTC.getTime() === utcDay.getTime();
                });

                return (
                    <div key={day.toString()} style={{
                        aspectRatio: '1',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: isSubmitted ? '#4CAF50' : '#fff',
                        color: isSubmitted ? '#fff' : '#000',
                        borderRadius: '4px',
                        fontSize: 'clamp(12px, 1.8vw, 16px)',
                        border: '1px solid #dee2e6',
                        width: '100%',
                        height: '100%',
                        minWidth: 0,
                        minHeight: 0,
                        padding: '2px'
                    }}>
                        {format(day, 'd')}
                    </div>
                );
            })}
        </div>
    );
};

function Progress() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [testData, setTestData] = useState([]);
    const [previewData, setPreviewData] = useState({
        daily: null,
        tests: []
    });

    const userId = useMemo(() => {
        let user = localStorage.getItem("mysUser");
        user = user ? JSON.parse(user) : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
        return user.userId;
    }, []);

    const baseUrlAWS = process.env.REACT_APP_baseUrl;
    const baseUrl = `${baseUrlAWS}api/myprogress`;

    const tests = [
        { id: 'pitt', name: 'Pittsburgh Sleep Quality Index' },
        { id: 'stress', name: 'Perceived Stress Scale' },
        { id: 'digit', name: 'Digit Memory Test' },
        { id: 'reactiontime', name: 'Reaction Time Test' },
        { id: 'selfreaction', name: 'Self Reaction Test' },
        { id: 'happiness', name: 'Happiness Scale' },
        { id: 'qol', name: 'Quality of Life Scale' },
        { id: 'appearance', name: 'Appearance Scale' },
        { id: 'sex', name: 'Sex Questionnaire' },
        { id: 'brainfog', name: 'Brain Fog Scale' },
        { id: '36item_overall', name: 'General Health Inventory' }
    ];

    const calculateTestStats = (testId) => {
        const testScores = testData.filter(data => data.testId === testId); 
        
        if (!testScores || testScores.length === 0) {
            return { daysSince: 'Never', diffDays: Infinity };
        }

        // Find the most recent submission
        const lastDate = new Date(Math.max(...testScores.map(score => new Date(score.submission_date))));
        const today = new Date();
        const diffTime = Math.abs(today - lastDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        
        const daysSince = diffDays === 0 ? 'Today' : 
                         diffDays === 1 ? 'Yesterday' :
                         `${diffDays} days ago`;

        return { daysSince, diffDays };
    };

    const getStyles = () => ({
        dashboardContainer: {
            padding: '24px',
            height: '100vh',
            overflow: 'hidden',
            backgroundColor: '#f8f9fa',
            display: 'flex',
            flexDirection: 'column'
        },
        headerSection: {
            marginBottom: '24px',
            flex: '0 0 auto'
        },
        cardsContainer: {
            flex: 1,
            minHeight: 0,
            display: 'flex',
            gap: '1rem',
            padding: '0.5rem',
            marginBottom: '20px'
        },
        cardCol: (isHovered, anyCardHovered) => ({
            flex: isHovered ? 1.5 : (anyCardHovered ? 0.75 : 1),
            transition: 'all 0.3s ease',
            height: '100%',
            minWidth: '250px',
        }),
        card: {
            height: '95%',
            width: '100%',
            border: '1px solid rgba(0,0,0,0.12)',
            borderRadius: '8px',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            cursor: 'pointer',
            '&:hover': {
                transform: 'translateY(-5px)',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
            }
        },
        cardBody: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            padding: '24px',
            minHeight: '350px' // Adjust this as needed
        },
        cardTitle: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '2rem',
            fontSize: '1.5rem',
            fontWeight: '500'
        },
        cardDescription: {
            color: '#666',
            fontSize: '1.1rem',
            marginTop: '2rem',
            position: 'absolute',
            bottom: '2rem',
            left: '1.5rem',
            right: '1.5rem'
        },
        chartContainer: {
            flexGrow: 1, // Takes up remaining space
            minHeight: '200px', // Ensures it doesn’t shrink too much
            position: 'relative'
        },
        statusBadge: {
            padding: '4px 12px',
            borderRadius: '20px',
            fontSize: '0.9rem',
            backgroundColor: props => props.submitted ? '#e8f5e9' : '#fff3e0',
            color: props => props.submitted ? '#2e7d32' : '#ef6c00'
        },
        testList: {
            listStyle: 'none',
            padding: '0.5rem',
            margin: 0,
            height: 'calc(100% - 200px)',
            overflowY: 'hidden',
            position: 'relative'
        },
        testItem: (index) => ({
            padding: '12px 16px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '1rem',
            opacity: Math.max(0.2, 1 - (index * 0.15)),
            background: index === 0 ? 'rgba(220, 220, 220, 0.2)' : 'transparent',
            borderRadius: '6px',
            marginBottom: '8px',
            transition: 'all 0.2s ease'
        }),
        testName: {
            fontWeight: index => index === 0 ? '500' : '400',
            color: '#333',
            flex: '1',
            marginRight: '12px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        daysSince: {
            color: '#666',
            fontSize: '0.9rem',
            whiteSpace: 'nowrap'
        },
        fadeOverlay: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '150px',
            background: 'linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%)',
            pointerEvents: 'none'
        }
    });

    const previewChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [
            {
                label: 'Health Score',
                data: [85, 88, 86, 90, 87, 92],
                borderColor: '#404040',
                backgroundColor: 'rgba(64, 64, 64, 0.1)',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'Sleep Quality',
                data: [75, 78, 80, 77, 82, 85],
                borderColor: '#808080',
                backgroundColor: 'rgba(128, 128, 128, 0.1)',
                borderWidth: 2,
                fill: true,
            },
            {
                label: 'Stress Level',
                data: [65, 60, 68, 63, 58, 55],
                borderColor: '#A9A9A9',
                backgroundColor: 'rgba(169, 169, 169, 0.1)',
                borderWidth: 2,
                fill: true,
            }
        ]
    };

    const previewChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
                grid: {
                    color: 'rgba(200, 200, 200, 0.1)',
                },
                ticks: {
                    color: '#666'
                }
            },
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    color: '#666'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    color: '#666',
                    usePointStyle: true,
                    padding: 20
                }
            }
        },
        elements: {
            line: {
                tension: 0.4
            },
            point: {
                radius: 3
            }
        }
    };

    useEffect(() => {
        const fetchPreviewData = async () => {
            setIsLoading(true);
            try {
                const baseUrl = process.env.REACT_APP_baseUrl;
                const response = await axios.get(
                    `${baseUrl}/api/myprogress/read-all-test-scores-by-user/${userId}/all/`,
                    { headers: { "Content-Type": "application/json" } }
                );

                //response.data.all_scores_responses is the array of test scores
                if (response.status === 200 && Array.isArray(response.data.all_scores_responses)) {
                    setTestData(response.data.all_scores_responses);
                    
                    // Sort tests by completion status and days since last completion
                    const sortedTests = [...tests].sort((a, b) => {
                        const aScores = response.data.all_scores_responses.filter(score => score.testId === a.id);
                        const bScores = response.data.all_scores_responses.filter(score => score.testId === b.id);
                        
                        // If neither test has been completed
                        if (aScores.length === 0 && bScores.length === 0) return 0;
                        
                        // Put never completed tests at the top
                        if (aScores.length === 0) return -1;
                        if (bScores.length === 0) return 1;
                        
                        // Get the most recent dates for each test
                        const aLatest = new Date(Math.max(...aScores.map(score => new Date(score.submission_date))));
                        const bLatest = new Date(Math.max(...bScores.map(score => new Date(score.submission_date))));
                        
                        // Sort by days since completion (older dates first)
                        return aLatest - bLatest;
                    });

                    setPreviewData(prev => ({
                        ...prev,
                        tests: sortedTests
                    }));
                }

            } catch (error) {
                console.error('Error fetching preview data:', error);
                setTestData([]);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPreviewData();
    }, [userId]);

    const dailySubmissionsData = useMemo(async () => {
        try {
            const response = await axios.get(
                `${baseUrl}/read-all-progress-by-user/${userId}/31/`,
                { headers: { "Content-Type": "application/json" } }
            );
            if (response.status === 200) {
                const progressData = response.data.all_progress_responses || [];
                console.log(progressData);
                return progressData.map(entry => {
                    const date = new Date(entry.submission_date);
                    return date.toISOString();
                });
            }
            return [];
        } catch (error) {
            console.error('Error fetching daily progress:', error);
            return [];
        }
    }, [userId, baseUrl]);

    // Add state for hover
    const [hoveredCard, setHoveredCard] = useState(null);
    
    // Get random image and its credit
    const [randomImage, imageKey] = useMemo(() => {
        const keys = Object.keys(previewImages);
        const randomKey = keys[Math.floor(Math.random() * keys.length)];
        return [previewImages[randomKey].image, randomKey];
    }, []);

    return (
        <div style={getStyles().dashboardContainer}>
            <div style={getStyles().headerSection}>
                <h2 style={{ 
                    margin: 0,
                    fontSize: '1.75rem',
                    fontWeight: '500',
                    color: '#333'
                }}>
                    My Progress Dashboard
                </h2>
            </div>
            
            {isLoading ? (
                <div style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div className="spinner-border text-primary" role="status" style={{ width: '3rem', height: '3rem' }}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <div style={getStyles().cardsContainer}>
                    {/* Daily Progress Card */}
                    <div 
                        style={getStyles().cardCol(hoveredCard === 'daily', hoveredCard !== null)}
                        onMouseEnter={() => setHoveredCard('daily')}
                        onMouseLeave={() => setHoveredCard(null)}
                    >
                        <Card 
                            style={getStyles().card}
                            onClick={() => navigate('/progress/daily')}
                        >
                            <Card.Body style={getStyles().cardBody}>
                                <Card.Title style={getStyles().cardTitle}>
                                    Progress Survey
                                </Card.Title>
                                <div style={{ ...getStyles().chartContainer, overflow: 'hidden',
                                    height: '65vh'}}>
                                    <Calendar submissionsPromise={dailySubmissionsData} />
                                </div>
                                <Card.Text style={getStyles().cardDescription}>
                                    Track your general health metrics including weight, blood pressure, and physical measurements
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    {/* Tests Card */}
                    <div 
                        style={getStyles().cardCol(hoveredCard === 'tests', hoveredCard !== null)}
                        onMouseEnter={() => setHoveredCard('tests')}
                        onMouseLeave={() => setHoveredCard(null)}
                    >
                        <Card 
                            style={getStyles().card}
                            onClick={() => navigate('/progress/tests')}
                        >
                            <Card.Body style={getStyles().cardBody}>
                                <Card.Title style={getStyles().cardTitle}>
                                    Health Tests
                                </Card.Title>
                                <div style={getStyles().testList}>
                                    {previewData.tests.map((test, index) => (
                                        <div 
                                            key={test.id}
                                            style={getStyles().testItem(index)}
                                        >
                                            <span style={getStyles().testName}>
                                                {test.name}
                                            </span>
                                            <span style={getStyles().daysSince}>
                                                {calculateTestStats(test.id).daysSince}
                                            </span>
                                        </div>
                                    ))}
                                    <div style={getStyles().fadeOverlay} />
                                </div>
                                <Card.Text style={getStyles().cardDescription}>
                                    Complete health assessments and track your results
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>

                    {/* Reports Card */}
                    <div 
                        style={getStyles().cardCol(hoveredCard === 'reports', hoveredCard !== null)}
                        onMouseEnter={() => setHoveredCard('reports')}
                        onMouseLeave={() => setHoveredCard(null)}
                    >
                        <Card 
                            style={getStyles().card}
                            onClick={() => navigate('/progress/reports')}
                        >
                            <Card.Body style={getStyles().cardBody}>
                                <Card.Title style={getStyles().cardTitle}>
                                    Progress Reports
                                </Card.Title>
                                <div style={{
                                    ...getStyles().chartContainer,
                                    backgroundImage: `url(${randomImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    height: '65vh'
                                }}>
                                </div>
                                <p style={{ 
                                    color: 'gray', 
                                    fontSize: '12px', 
                                    marginTop: '8px', 
                                    marginBottom: '8px',
                                    textAlign: 'right',
                                    fontStyle: 'italic'
                                }}>
                                    {previewImages[imageKey].credit}
                                </p>
                                <p style={{ 
                                    fontSize: '17px',
                                    color: '#666',
                                    marginBottom: '0'
                                }}>
                                    View detailed reports and track your progress over time. Monitor your health metrics, test results, and overall wellness journey.
                                </p>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Progress;
