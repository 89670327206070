import React, {useState, useMemo, useEffect} from "react";
import axios from "axios";
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

//each index corresponds to the score of the question. Initialized at 0.
const current = [0,0,0,0,0,0,0,0,0,0];
const baseUrl = process.env.REACT_APP_baseUrl;
const baseUrlAWS =`${baseUrl}api/myprogress`

function SexTest() {

  const [selectedQuestions, setSelectedQuestions] = useState({});
  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'sex').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'sex')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)

                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

  //used to calculate total value
  const [total, setTotal] = useState(0);  

  //array of questions
  const questions = ["1. How often do you think about sex?", "2. How often do you experience sexual fantasies or daydreams?",
  "3. How often do you feel a strong desire for sexual activity?",  "4. How often do you initiate sexual activity with your partner?", "5. How often do you find yourself in the mood for sexual activity?",
 "6. How often do you experience sexual urges?", "7. How often do you feel a need for physical intimacy with a partner?", "8. How often do you feel an interest in sexual activity with your partner?", 
 "9. How often do you feel a longing for sexual connection with your partner?", "10. How often do you desire sexual contact with your partner?"]

 //calculate total. Add up all the numbers in the array and divide by 28. Round to 1 decimal place
  const calculateTotal = async () => {
    let n = 0; 
    let unansweredQ = false
    console.log(current); 
    for (let i = 0; i<current.length; i++) {
        n+= current[i]; 
        if (current[i] === 0) {
          unansweredQ = true
        }
    }

    if (unansweredQ) {
      return
    }

    setTotal(n); 

    let testRes = {}
    testRes["user"] = userId
    testRes["testId"] = "sex"
    testRes["score"] = n

    submitData(testRes)
    console.log(testRes)
  }

  //updates the array by updating the element at given index
  const handleAgree = (index, num) => {
      current[index.index] = num;
      setSelectedQuestions((prev) => ({
        [index.index]: num, // Mark the question as selected
      }));

  }

  // call API to submit the form data 
  const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }

   //I used BootStrap CSS so the classNames won't work unless it is installed and imported in index.js 

  return (
    <div className="App">
        <div className="container-fluid m-4">
          <div className="row" style={{ position: "fixed", 
            zIndex: 1100,  // Increased z-index to be above questions
            top: 118, 
            left: 20,  // Changed from 30 to 0 for full coverage
            width: "100%", 
            padding: "8px 0px 2px 2px",
            backgroundColor: 'white',  
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Shadow for separation
            }}>
            <div className="col-5">
              <h5><strong>Questions</strong></h5>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Never</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Rarely</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Occasionally</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Sometimes</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Often</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Very Often</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Always</p>
            </div>
          </div>
        {questions.map((item, index) => {
          const score = [1,2,3,4,5,6,7]
          return <div style={{marginTop: index === 0 ? "50px" : "0px",}}> 
            <div className ="row d-flex align-items-center" style={{backgroundColor: index % 2 !== 0 ? "#f0f0f0" : "transparent", borderRadius: '8px', padding: '5px 20px',}} key = {index}><div className="col-5 mt-2"><p className = "h6" key = {index} style={{
            fontWeight: selectedQuestions[index] ? "bold" : "normal",
            color: selectedQuestions[index] ? "#04723A" : "#000000", }}>{item}</p></div>
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" name = {index}
              style={{
                borderRadius: 50,
                width: 20,
                height: 20,
                borderWidth: 1,
                borderColor: '#000000',
              }} id ="stronglydisagree" onClick = {() => handleAgree({index}, score[0])}/>        
            </div>
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" name = {index}
              style={{
                borderRadius: 50,
                width: 20,
                height: 20,
                borderWidth: 1,
                borderColor: '#000000',
              }} id ="moderatelydisagree" onChange = {() => handleAgree({index}, score[1])}/>
            </div>
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" name = {index}
              style={{
                borderRadius: 50,
                width: 20,
                height: 20,
                borderWidth: 1,
                borderColor: '#000000',
              }} id ="slightlydisagree" onChange = {() => handleAgree({index}, score[2])}/>
            </div>
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" name = {index}
              style={{
                borderRadius: 50,
                width: 20,
                height: 20,
                borderWidth: 1,
                borderColor: '#000000',
              }} id ="nuetral" onChange = {() => handleAgree({index}, score[3])}/>
            </div>
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" name = {index}
              style={{
                borderRadius: 50,
                width: 20,
                height: 20,
                borderWidth: 1,
                borderColor: '#000000',
              }} id ="slightlyagree" onChange = {() => handleAgree({index}, score[4])}/>
            </div>
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" name = {index}
              style={{
                borderRadius: 50,
                width: 20,
                height: 20,
                borderWidth: 1,
                borderColor: '#000000',
              }} id ="moderatelyagree" onChange = {() => handleAgree({index}, score[5])}/>
            </div>
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" name = {index}
              style={{
                borderRadius: 50,
                width: 20,
                height: 20,
                borderWidth: 1,
                borderColor: '#000000',
              }} id ="stronglyagree" onChange = {() => handleAgree({index}, score[6])}/>
            </div>
            </div>
          </div>
        })}
        <div className="row mt-4" style={{ paddingBottom: "120px" }}>
          <div className="col d-flex flex-column align-items-center text-center ">
            {(!submittedToday && !total) && (
              <button className="btn btn-primary" onClick = {() => calculateTotal()}>Calculate Score</button>
            )}
            {(submittedToday || total) && (
              <button className="btn btn-primary">Already Submitted Today</button>
            )}
            <div>{total ? <h3>Your score: {total}</h3> : <div></div>}</div>
            </div>
        </div>
        </div>
    </div>
  );
}

export default SexTest;
