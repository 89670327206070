import axios from 'axios'
import {getCookie} from "../../utils/cookie"
const baseUrl = process.env.REACT_APP_baseUrl;
const baseUrlAWS =`${baseUrl}api/log`

const baseUrlUser = `${baseUrl}api/users`

export const getLogStatusColor = async (userId, start_date,end_date) => {
    return axios.get(
        `${baseUrlAWS}/get-log_status-color/${userId}/${start_date}/${end_date}`)
        .then(response => response)
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })
}

export const getLoggedData = async (userId, date) => {
    try {
        // Format the date to ensure consistency
        const formattedDate = new Date(date).toDateString();
        const response = await axios.get(
            `${baseUrlAWS}/get-logged-data/${userId}/${formattedDate}`
        );
        return response;
    } catch (error) {
        if (error.response) {
            if (error.response.status === 404) {
                // Return a standardized response for no data
                return {
                    data: null,
                    status: 404
                };
            } else if (error.response.status === 500) {
                return {
                    data: null,
                    status: 500
                };
            }
        }
        console.error('Error fetching log data:', error);
        throw error;
    }
}

export const deleteData = async (log_id) => {
    const csrfToken = getCookie("csrftoken");
    console.log("Deleting log with ID:", log_id);

    try {
        await axios.delete(`${baseUrlAWS}/delete-log-response/${log_id}/`, {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
        });
        console.log("Log deleted successfully");
    } catch (error) {
        if (error.response && error.response.status === 500) {
            return null;
        } else {
            console.error("Error deleting log:", error);
            throw error;
        }
    }
};


export const updateData =  async (formData, log_id) => {
    const csrfToken = getCookie("csrftoken")
    // console.log(formData);
    axios.put(
        `${baseUrlAWS}/log-response/${log_id}/`,
        formData,
        {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
        })
        .then(response => {
            console.log(response.data)
        })
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })
}

// call API to submit the form data 
export const submitData =  async (formData) => {
    const csrfToken = getCookie("csrftoken")
    // console.log(JSON.stringify(formData))
    axios.post(
        `${baseUrlAWS}/log-response/`,
        formData,
        {
            headers: {
                "Content-Type": "application/json",
                "X-CSRFToken": csrfToken,
            },
        })
        .then(response => {
            console.log(response.data)
        })
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })
}

//call API to retrieve log dates
export const fetchDates = (userId) => {
    return axios.get(
        `${baseUrlAWS}/get-logged-dates/${userId}`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => response)
        .catch(error => {
            if (error.response && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })

}

//fetch user data
export const fetchUserData = (userId) => {
    return axios.get(
        `${baseUrlUser}/get-user-data/${userId}`,
        {
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then(response => response.data)
        .catch(error => {
            if (error.resposne && error.response.status === 500) {
                return null
            } else {
                console.error('Error fetching form data:', error)
                throw error
            }
        })

}