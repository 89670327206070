import React, { useState, useMemo, useEffect } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import axios from 'axios'
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

export default function QoLScale() {

  const [submittedToday, setSubmittedToday] = useState(true)
  const [selectedValues, setSelectedValues] = useState(Array(16).fill(''));
  const [showScore, setShowScore] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const [highlightedQuestionIndex, setHighlightedQuestionIndex] = useState(null);

  const categories = [
    { name: 'Delighted', value: 7 },
    { name: 'Pleased', value: 6 },
    { name: 'Mostly Satisfied', value: 5 },
    { name: 'Mixed', value: 4 },
    { name: 'Mostly Dissatisfied', value: 3 },
    { name: 'Unhappy', value: 2 },
    { name: 'Terrible', value: 1 },
  ];

  // Calculate max score based on number of questions and highest category value
  const maxScore = useMemo(() => {
    const maxCategoryValue = Math.max(...categories.map(cat => cat.value));
    return 16 * maxCategoryValue;
  }, [16]);

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'qol').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'qol')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)

                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])

  const baseUrl = process.env.REACT_APP_baseUrl;
  const baseUrlAWS =`${baseUrl}api/myprogress`

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

 const questions = [
   '1. Material comforts home, food, conveniences, financial security',
   '2. Health - being physically fit and vigorous',
   '3. Relationships with parents, siblings & other relatives - communicating, visiting, helping',
   '4. Having and rearing children',
   '5. Close relationships with spouse or significant other',
   '6. Close friends',
   '7. Helping and encouraging others, volunteering, giving advice',
   '8. Participating in organizations and public affairs',
   '9. Learning - attending school, improving understanding, getting additional knowledge',
   '10. Understanding yourself - knowing your assets and limitations - knowing what life is about',
   '11. Work - job or at home',
   '12. Expressing yourself creatively',
   '13. Socializing - meeting other people, doing things, parties, etc.',
   '14. Reading, listening to music, or observing entertainment',
   '15. Participating in active recreation',
   '16. Independence, doing for yourself',
 ];

 

 const handleAnswerChange = (questionIndex, categoryIndex) => {
   const updatedValues = [...selectedValues];
   updatedValues[questionIndex] = categoryIndex;
   setSelectedValues(updatedValues);
   setHighlightedQuestionIndex(questionIndex);
 };

 const calculateTotalScore = () => {
   let score = 0;
   let unansweredQ = false
   selectedValues.forEach((selectedValue, questionIndex) => {
     const category = categories.find((cat) => cat.name === selectedValue);
     if (category) {
       score += category.value;
     } else {
        unansweredQ = true
     }
   });

   if (unansweredQ) {
    return -1
   } else {
    return score
   }
 };

 const handleSubmit = () => {
   const score = calculateTotalScore();
   
   if (score === -1) {
     // Highlight unanswered questions
     const unanswered = selectedValues.map((value, index) => 
       value === '' ? index : null
     ).filter(index => index !== null);
     setUnansweredQuestions(unanswered);
     return;
   }

   setTotalScore(score);
   setShowScore(true);

   let testRes = {
     user: userId,
     testId: "qol",
     score: score
   };

   submitData(testRes);
   console.log(testRes);
 };

  // call API to submit the form data 
  const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }

 return (
   <View style={{ flex: 1, padding: "0 0 20px 20px;" }}>
    {/* Fixed Header */}
      <View style={{ 
        position: 'fixed', 
        top: 80, 
        left: 0, 
        right: 0, 
        backgroundColor: 'white', 
        padding: 10, 
        zIndex: 100,
        elevation: 5 // For Android shadow effect
      }}>
        <Text style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 10, textAlign: 'center', marginTop: 50 }}>
          Quality of Life Scale
        </Text>
        <Text style={{ marginBottom: 20, fontWeight: 'bold', textAlign: 'center' }}>
          Please answer the following questions based on your feelings.
        </Text>
        
        {/* Category headers */}
        <View style={{ 
          flexDirection: 'row', 
          marginBottom: 14, 
          width: '60%', 
          justifyContent: 'space-evenly', 
          marginLeft: '35%' 
        }}>
          {categories.map((category, categoryIndex) => (
            <Text key={categoryIndex} style={{ 
              textAlign: 'center', 
              flex: 1,
              fontSize: 12,
            }}>
              {category.name}
            </Text>
          ))}
        </View>
      </View>
    
    <ScrollView contentContainerStyle={{ paddingTop: 150 }}>
     {questions.map((question, questionIndex) => (
       <View
         key={questionIndex}
         style={{
           flexDirection: 'row',
           alignItems: 'center',
           marginBottom: 14,
           backgroundColor: unansweredQuestions.includes(questionIndex) ? '#ffcccc' : (questionIndex % 2 === 1 ? '#f0f0f0' : '#ffffff'),
           minHeight: 50,
           paddingVertical: 8,
           paddingHorizontal: 10,
           borderRadius: 5,
         }}
       >
         <Text style={{ width: '38%', textAlignVertical: 'center',
          fontWeight: highlightedQuestionIndex === questionIndex ? 'bold' : 'normal', // Make it bold when selected
          color: highlightedQuestionIndex === questionIndex ? '#04723A' : '#000000', // Change color when selected
          }}>{question}</Text>
         <View style={{ 
           flexDirection: 'row', 
           justifyContent: 'stretch', 
           flex: 1, 
           width: "60%",
         }}>
           {categories.map((category, categoryIndex) => {
             const isSelected = selectedValues[questionIndex] === category.name;
             return (
               <TouchableOpacity
                 key={categoryIndex}
                 style={{
                   alignItems: 'center',
                   justifyContent: 'center',
                   borderRadius: 50,
                   width: 24,
                   height: 24,
                   borderWidth: 1,
                   borderColor: '#000000',
                   backgroundColor: isSelected ? '#c0c0c0' : '#ffffff',
                 }}
                 onPress={() => handleAnswerChange(questionIndex, category.name)}
               >
                 {isSelected && (
                   <View
                     style={{
                       width: 14,
                       height: 14,
                       borderRadius: 7,
                       backgroundColor: '#000000',

                     }}
                   />
                 )}
               </TouchableOpacity>
             );
           })}
         </View>
       </View>
     ))}
    </ScrollView>
    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", paddingBottom: "130px"}}>
    {(!showScore && !submittedToday) && (
      <TouchableOpacity
        style={{
          alignItems: 'center',
          backgroundColor: '#ffffff',
          borderColor: '#000000',
          borderWidth: 1,
          borderRadius: 5,
          paddingVertical: 5,
          paddingHorizontal: 5,
          width: '10%',
          alignSelf: 'center',
          marginTop: 10,
        }}
        onPress={handleSubmit}
      >
        <Text style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 12 }}>Submit</Text>
      </TouchableOpacity>
    )}
    {(showScore || submittedToday) && (
      <TouchableOpacity
        style={{
          alignItems: 'center',
          backgroundColor: '#ffffff',
          borderColor: '#000000',
          borderWidth: 1,
          borderRadius: 5,
          paddingVertical: 5,
          paddingHorizontal: 5,
          width: '10%',
          alignSelf: 'center',
          marginTop: 10,
        }}
      >
        <Text style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 12 }}>Already Submitted Today</Text>
      </TouchableOpacity>
    )}
    {showScore && (
      <Text style={{ marginTop: 10, textAlign: 'center', fontWeight: 'bold'}}>
        Total Score: {totalScore} / {maxScore} 
      </Text>
    )}
    </div>
   </View>
 )
}
