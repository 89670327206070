import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios'
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

const baseUrl = process.env.REACT_APP_baseUrl;
const MemoryTest = () => {

  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'digit').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'digit')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)

                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])

  const baseUrlAWS = `${baseUrl}api/myprogress`

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

  const [numDigits, setNumDigits] = useState(3);
  const [digits, setDigits] = useState([]);
  const [highestDigits, setHighestDigits] = useState(0);
  const [userInput, setUserInput] = useState('');
  const [failCount, setFailCount] = useState(0);
  const [startTime, setStartTime] = useState(null);
  const [gameState, setGameState] = useState('INSTRUCTIONS');
  const [digitIndex, setDigitIndex] = useState(-1);
  const [message, setMessage] = useState('');
  const [hasSeenInstructions, setHasSeenInstructions] = useState(false); // New state variable


  const [practice, setPractice] = useState(true)

  useEffect(() => {
    if (gameState === 'SHOWING') {
      const timeout = setTimeout(() => {
        setDigitIndex((prevIndex) => {
          const newIndex = prevIndex + 1;
          if (newIndex >= numDigits) {
            setGameState('USER_INPUT');
            setDigitIndex(-1);
          }
          return newIndex;
        });
      }, 500);
      return () => clearTimeout(timeout);
    }
  }, [gameState, digitIndex, numDigits]);

  useEffect(() => {
    if (failCount >= 3) {
      setGameState('GAME_OVER');
      //not sure
      let testRes = {}
      testRes["user"] = userId
      testRes["testId"] = "digit"
      testRes["score"] = highestDigits
      submitData(testRes)
      console.log(testRes)
    }
  }, [failCount]);

  const generateDigits = (length) => {
    return Array.from({ length }, () => Math.floor(Math.random() * 10));
  };

  const startGame = () => {
    setPractice(false)
    setHasSeenInstructions(true);
    if (numDigits <= 20) {
      setDigits(generateDigits(numDigits));
      setStartTime(new Date());
      setGameState('SHOWING');
      setDigitIndex(0);
      setMessage('');
    }
  };

  const startPractice = () => {
    setPractice(true)
    setHasSeenInstructions(true);
    if (numDigits <= 20) {
      setDigits(generateDigits(numDigits));
      setStartTime(new Date());
      setGameState('SHOWING');
      setDigitIndex(0);
      setMessage('');
    }
  };

  const checkAnswer = () => {
    const userDigits = userInput.split(',').map(Number);
    if (JSON.stringify(userDigits) === JSON.stringify(digits)) {
      if (numDigits === 20) {
        setMessage('Correct.');

        if (practice === false) {
          setGameState('GAME_OVER');

          //not sure
          let testRes = {}
          testRes["user"] = userId
          testRes["testId"] = "digit"
          testRes["score"] = 20
          submitData(testRes)
          console.log(testRes)
        }

      } else {
        setMessage('Correct.');

        if (practice === false) {
          setHighestDigits(numDigits);
          setNumDigits(numDigits + 1);
          setFailCount(0);
        }
      }
    } else {
      setMessage('Incorrect.');
      if (practice === false) {
        setFailCount(failCount + 1)
      }
    }
    setUserInput('');
    setGameState('INSTRUCTIONS');
  };

  // call API to submit the form data 
  const submitData = (testData) => {
    if (practice === false) {
      const csrfToken = getCookie("csrftoken")
      console.log(JSON.stringify(testData))
      axios.post(
          `${baseUrlAWS}/create-progress-test-score-entry/`,
          testData,
          {
              headers: {
                  "Content-Type": "application/json",
                  "X-CSRFToken": csrfToken,
              },
          })
          .then(response => {
              console.log(response)
          })
          .catch(error => {
              if (error.resposne && error.response.status === 500) {
                  return null
              } else {
                  console.error('Error fetching form data:', error)
                  throw error
              }
          })
    }
  }

  const containerStyle = {
    justifyContent: 'center',
    alignItems: 'center',
  };

  const digitStyle = {
    fontSize: '3em',
    marginRight: '10px',
  };

  const numberDisplayStyle = {
    color: 'gray',
    fontSize: 20,
    marginTop: '1rem'
  };

  const inputStyle = {
    margin: '1em 0',
    padding: '0.5em',
    fontSize: '1em',
    textAlign: 'center',
  };

  const buttonContainer = {
    display: "inline-flex", // Ensures buttons are in one row
    alignItems: "center", 
    justifyContent: "center",
    marginTop: 20,
    gap: 15, // Space between buttons
  };

  const buttonStyle = {
    backgroundColor: "#007BFF",
    paddingVertical: 15, // Adjust for height
    paddingHorizontal: 25, // Adjust for width
    borderRadius: 50, // Fully rounded buttons
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)", // Shadow for 3D effect
    border: "none", // Removes default border
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "120px", // Ensures uniform width
  };

  const buttonText = {
    color: "white",
    fontSize: 18,
    fontWeight: "bold", // Bold text
    textAlign: "center",
    margin: 0, // Removes default margin
  };

  const instructionStyle = {
    textAlign: 'left',
    color: '#666', // Light gray for subtitle
    fontSize: '18px', // Smaller font for instructions
  };

  const formContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '1em',
  };

  const inputContainerStyle = {
    display: 'flex',
    flexDirection: 'column', // Stack elements vertically
    alignItems: 'centre',
    gap: '0.5em', // Add spacing between input and button
    marginBottom: '1em',
  };

  const submitButtonStyle = {
    ...buttonStyle,
    marginRight: '0.5em',
  };

  const titleText = {
    fontSize: '32px', // Larger font for the title
    marginBottom: '5px', // Space between title and description
  };
  return (
    <div style={containerStyle}>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}>
        {gameState !== 'SHOWING' && <p>{message}</p>}
      </div>
      {gameState === 'INSTRUCTIONS' && !hasSeenInstructions && (
        <div>
          <div style={titleText}>
            <p>Digit Memory Test</p>
          </div>
          <div style={instructionStyle}>
            <p>
              You will be shown a sequence of digits, one at a time. Afterwards, you will be asked to type the digits you
              just saw, so do your best to remember them.
            </p>
            <p>The test will continue for 20 digit sequences or until you make 3 Incorrect guesses.</p>
          </div>
        </div>
      )}
      {gameState === 'INSTRUCTIONS' && (
        <div>
          {!submittedToday && (
            <div style={{ ...buttonContainer }}>
              <button style={{ ...buttonStyle, }} onClick={startGame}>
                <p style={{ ...buttonText, }}>Start</p>
              </button>
              <button style={{ ...buttonStyle, }} onClick={startPractice}>
              <p style={{ ...buttonText, }}>Practice</p>
              </button>
            </div>
          )}
          {submittedToday && (
            <div style={{ ...buttonContainer }}>
              <button style={{ ...buttonStyle, }}>
              <p style={{ ...buttonText, }}>Start</p>
              </button>
              <button style={{ ...buttonStyle, }}>
              <p style={{ ...buttonText, }}>Practice</p>
              </button>
              <p style={{color: "gray", fontSize: "20px", textAlign: 'center'}}>(Already submitted today)</p>
            </div>
          )}
        </div>
      )}
      {gameState === 'USER_INPUT' && (
        <div style={formContainerStyle}>
          <p>Please type each number separated by a comma. For example '5,3,6'</p>
          <div style={inputContainerStyle}>
            <input
              style={{ ...inputStyle, marginRight: '0.5em' }}
              type="text"
              value={userInput}
              onChange={(e) => {
                const value = e.target.value;
                // Allow only digits and commas
                if (/^[0-9,]*$/.test(value)) {
                  setUserInput(value);
                }
              }}
              autoFocus
            />
            <button
              style={submitButtonStyle}
              onClick={() => {
                // Check if the input is separated by commas
                if (userInput.trim() === '' || !userInput.includes(',')) {
                  alert('Please separate the digits with commas and try again.');
                } else {
                  checkAnswer();
                }
              }}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <div style={{...numberDisplayStyle,  display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {gameState === 'SHOWING' &&
          digits.map((digit, i) => (
            <p style={{ ...digitStyle, marginLeft: `${i > 0 ? '10px' : '0'}` }} key={i}>
              {i === digitIndex ? digit : ''}
            </p>
          ))}
      </div>
      {gameState === 'GAME_OVER' && (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
          <p>Thank you for participating. You were able to correctly recall up to {highestDigits} digits.</p>
          <p>The test started at {startTime.toString()}</p>
        </div>
      )}
    </div>
  );
};

export default MemoryTest;
