import React, { useState } from 'react'

export default function InputCheckBox({ label, options, name, onChange, value, error }) {
    const containerStyle = {
        maxWidth: '700px',
        margin: '0 auto',
        marginBottom: '20px'
    };

    const labelStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '45px',
        margin: 0,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
        fontSize: '16px',
        fontWeight: '500',
        color: '#2c3e50',
        paddingLeft: '10px'
    };

    const optionsContainerStyle = {
        display: 'flex',
        flexDirection: 'row',
        gap: '20px',
        justifyContent: 'center'
    };

    const optionStyle = {
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
    };

    const handleChange = (event, index) => {
        const optionValue = event.target.checked;
        // Convert to number: 1 for Yes, 0 for No
        const newValue = Number(optionValue);
        onChange(name[index], newValue);
    };

    return (
        <div style={containerStyle}>
            <label style={labelStyle}>{label}</label>
            <div style={optionsContainerStyle}>
                {options.map((option, index) => (
                    <div key={index} style={optionStyle}>
                        <input
                            type="radio"
                            id={`radio-${name[index]}`}
                            name={label}
                            // value={index}
                            // checked={value === index}
                            onChange={(e)=>{handleChange(e,index)}}
                            style={{width: '20px', height: '20px', cursor: 'pointer'}}
                        />
                        <label htmlFor={`radio-${name[index]}`}>
                            {option}
                        </label>
                    </div>
                ))}
            </div>
            {error && 
                <p style={{color: 'red', fontSize: '16px', marginBottom: '0', textAlign: 'center'}}>
                    Error: Please select an option.
                </p>
            }
        </div>
    );
}
