import React, { useState, useMemo, useEffect } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import axios from 'axios'
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

const MemoryTestForm = () => {

  const [highlightedQuestionIndex, setHighlightedQuestionIndex] = useState(null);
  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'selfreaction').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'selfreaction')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)
                if (testDate === currDate) {
                  setSubmittedToday(true)
                  setShowScore(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])
  const baseUrl = process.env.REACT_APP_baseUrl;
  const baseUrlAWS =`${baseUrl}api/myprogress`

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

 const questions = [
   '1. I can easily remember what has been said or done in the past 24 hours.',
   '2. I can easily remember information for a short period of time (e.g., a phone number).',
   '3. I can easily hold information in my head while working with it (perform mental arithmetic, take notes while listening in class).',
   '4. I can easily recall information that I learn.',
   '5. When I read, I remember the important facts.',
   '6. I am easily able to recall events, things I have done or places I have been, in detail.',
   '7. I can easily recognize someone’s face that I have seen before.',
   '8. It is easy for me to remember movies I have seen.',
   '9. I use specific memory strategies, such as saying things over and over or making mental pictures.',
   '10. It is easy for me to remember where I put things.',
 ];

 const categories = [
   { name: 'Never', value: 3 },
   { name: 'Sometimes', value: 2 },
   { name: 'Often', value: 1 },
   { name: 'Always', value: 0 },
 ];

 const [selectedValues, setSelectedValues] = useState(Array(questions.length).fill(''));
 const [showScore, setShowScore] = useState(false);
 const [totalScore, setTotalScore] = useState(0);

 const handleAnswerChange = (questionIndex, categoryIndex) => {
   const updatedValues = [...selectedValues];
   updatedValues[questionIndex] = categoryIndex;
   setSelectedValues(updatedValues);
   setHighlightedQuestionIndex(questionIndex);
 };

 const calculateTotalScore = () => {
   let score = 0;
   let unansweredQ = false
   selectedValues.forEach((selectedValue, questionIndex) => {
     const category = categories.find((cat) => cat.name === selectedValue);
     if (category) {
       score += category.value;
     } else {
        //unanswered question
        unansweredQ = true
     }
   });

   if (unansweredQ) {
    return -1
   } else {
    return score
   }
 };

 const handleSubmit = () => {
   const score = calculateTotalScore();
   
   if (score === -1) {
      return
   } 

   setTotalScore(score);
   setShowScore(true);

   let testRes = {}
   testRes["user"] = userId
   testRes["testId"] = "selfreaction"
   testRes["score"] = score

   submitData(testRes)
   console.log(testRes)

 };

 // call API to submit the form data 
 const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }

 return (
   <View style={{ flex: 1, padding: 20 }}>
    {/* Fixed Header */}
          <View style={{ 
            position: 'fixed', 
            top: 100, 
            left: 0, 
            right: 0, 
            backgroundColor: 'white', 
            padding: 10, 
            zIndex: 100,
            elevation: 5 // For Android shadow effect
          }}>
        <Text style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 10, textAlign: 'center', marginTop: 50 }}>
        Memory Self-Test
        </Text>
        <Text style={{ marginBottom: 10, fontWeight: 'bold', textAlign: 'center' }}>
        Read the items below and click on the answer choice button that best describes how true this statement is for you.
        </Text>
        <View style={{ flexDirection: 'row', marginBottom: 14, width: '60%', justifyContent: 'space-between', marginLeft: '38%'  }}>
        {categories.map((category, categoryIndex) => (
          <Text key={categoryIndex} style={{ width: '26rem', textAlign: 'center', flex: 1, }}>
            {category.name}
          </Text>
        ))}
        </View>
      </View>

      <ScrollView contentContainerStyle={{ paddingTop: 150 }}>
        {questions.map((question, questionIndex) => (
          <View
            key={questionIndex}
            style={{
              flexDirection: 'row',
              marginBottom: 14,
              backgroundColor: questionIndex % 2 === 1 ? '#f0f0f0' : '#ffffff',
              minHeight: 50,
              paddingVertical: 8,
              paddingHorizontal: 10,
              borderRadius: 5,
            }}
          >
            <Text style={{ width: '40%', fontWeight: highlightedQuestionIndex === questionIndex ? 'bold' : 'normal', // Make it bold when selected
              color: highlightedQuestionIndex === questionIndex ? '#04723A' : '#000000', // Change color when selected
              flexWrap: 'wrap',  }}>{question}</Text>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1, marginLeft: '5%', marginRight: '7%' }}>
              {categories.map((category, categoryIndex) => {
                const isSelected = selectedValues[questionIndex] === category.name;
                return (
                  <TouchableOpacity
                    key={categoryIndex}
                    style={{
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: 50,
                      width: 20,
                      height: 20,
                      borderWidth: 1,
                      borderColor: '#000000',
                      backgroundColor: isSelected ? '#c0c0c0' : '#ffffff',
                      marginLeft: categoryIndex === 0 ? 0 : '8.6%',
                    }}
                    onPress={() => handleAnswerChange(questionIndex, category.name)}
                  >
                    {isSelected && (
                      <View
                        style={{
                          width: 12,
                          height: 12,
                          borderRadius: 6,
                          backgroundColor: '#000000',
                        }}
                      />
                    )}
                  </TouchableOpacity>
                );
              })}
            </View>
          </View>
        ))}

        <div style={{display: "flex", flexDirection: "column", justifyContent: "center", paddingBottom: "120px"}}>
          {(!showScore && !submittedToday) && (
            <TouchableOpacity
              style={{
                alignItems: 'center',
                backgroundColor: '#ffffff',
                borderColor: '#000000',
                borderWidth: 1,
                borderRadius: 5,
                paddingVertical: 5,
                paddingHorizontal: 5,
                width: '10%',
                alignSelf: 'center',
                marginTop: 10,
              }}
              onPress={handleSubmit}
            >
              <Text style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 12 }}>Submit</Text>
            </TouchableOpacity>
          )}
          {(showScore || submittedToday) && (
              <TouchableOpacity
                style={{
                  alignItems: 'center',
                  backgroundColor: '#ffffff',
                  borderColor: '#000000',
                  borderWidth: 1,
                  borderRadius: 5,
                  paddingVertical: 5,
                  paddingHorizontal: 5,
                  width: '10%',
                  alignSelf: 'center',
                  marginTop: 10,
                }}
              >
                <Text style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 12 }}>Already Submitted Today</Text>
              </TouchableOpacity>
          )}
          {showScore && (
            <>
              <Text style={{ marginTop: 10, textAlign: 'center', fontWeight: 'bold' }}>
                Total Score: {totalScore} / 40 
              </Text>
            </>
          )}
        </div>
        </ScrollView>
   </View>
 )
}
export default MemoryTestForm;