import React, { useState, useEffect, useMemo } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, Dimensions  } from 'react-native';
import axios from 'axios'
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

const { width, height } = Dimensions.get("window"); // Get screen dimensions

const TestComponent = () => {

  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'reactiontime').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'reactiontime')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)

                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])
  const baseUrl = process.env.REACT_APP_baseUrl;
  const baseUrlAWS =`${baseUrl}api/myprogress`

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

  const [showBoy, setShowBoy] = useState(false);
  const [start, setStart] = useState(false);
  const [boyAppearances, setBoyAppearances] = useState(0);
  const [correctClicks, setCorrectClicks] = useState(0);
  const [incorrectClicks, setIncorrectClicks] = useState(0);
  const [reactionTimes, setReactionTimes] = useState([]);
  const [startReaction, setStartReaction] = useState(null);
  const [intervalArray, setIntervalArray] = useState([]);
  const [positions, setPositions] = useState([])
  const [boyPosition, setBoyPosition] = useState({ top: 50, left: 50 });

  const [practice, setPractice] = useState(true)

  const [color, setColor] = useState("blue");
  // useEffect(() => {
  //   let newPositions = Array.from({ length: 20 }, () => Math.floor(Math.random() * 8) + 1);
  //   let timingArr = Array.from({ length: 20 }, () => Math.random() * 1 + 1.5);
  //   let scale = 60 / timingArr.reduce((sum, val) => sum + val, 0);
  //   let scaledTimings = timingArr.map((val) => val * scale);

  //   setPositions(newPositions);
  //   setIntervalArray(scaledTimings);
  // }, []);

  useEffect(() => {

    // let pos = []
    // for (let j = 0; j < 20; j++) {
    //   let min = Math.ceil(1)
    //   let max = Math.floor(9)
    //   pos.push(Math.floor(Math.random() * (max - min) + min))
    // }
    // console.log(pos)
    // setPositions(pos)

    let arr = [];
    let sum = 0;
    for (let i = 0; i < 20; i++) {
      let randomValue = Math.random() * 1 + 1.5; 
      sum += randomValue;
      arr.push(randomValue);
    }
    let scale = 60 / sum;
    arr = arr.map(val => val * scale);
    setIntervalArray(arr);
  }, []);

  useEffect(() => {
    let uniquePositions = new Set();
    let newPositions = [];
  
    while (newPositions.length < 20) {
      let newPos = {
        top: Math.random() * (height - 600),
        left: Math.random() * (width - 200),
      };
  
      let key = `${Math.floor(newPos.top)}-${Math.floor(newPos.left)}`;
      if (!uniquePositions.has(key)) {
        uniquePositions.add(key);
        newPositions.push(newPos);
      }
    }
    let timingArr = Array.from({ length: 20 }, () => Math.random() * 1 + 1.5);
    let scale = 60 / timingArr.reduce((sum, val) => sum + val, 0);
    let scaledTimings = timingArr.map((val) => val * scale);

    setPositions(newPositions);
    setIntervalArray(scaledTimings);
  }, []);

  useEffect(() => {
    if (!start) return;
  
    if (boyAppearances >= 20) {
      console.log("test done");
      let testRes = {
        user: userId,
        testId: "reactiontime",
        score: score
      };
      
      if (!practice) {
        submitData(testRes);
      }
      
      console.log(testRes);
      return;
    }
  
    let timer = setTimeout(() => {
      setShowBoy(true);
      setBoyPosition(positions[boyAppearances]); // Assign new random position
      setStartReaction(Date.now());
      setBoyAppearances((prev) => prev + 1);
    }, intervalArray[boyAppearances] * 1000);
  
    return () => clearTimeout(timer);
  }, [start, boyAppearances]);
  

  useEffect(() => {
    let timer;
    if (showBoy) {
      timer = setTimeout(() => {
        setShowBoy(false);

      }, 1000); 
    }
  
    return () => clearTimeout(timer);
  }, [showBoy]);
  

  const onPressButton = () => {
    if (!start || !showBoy || startReaction === null) {
      console.log("Incorrect Click")
      setColor("red")
      setIncorrectClicks(prev => prev + 1);
      return;
    }
    const reactionTime = Date.now() - startReaction;
    console.log("Correct Click")
    setColor("green")
    setCorrectClicks(prev => prev + 1);
    setReactionTimes(prev => [...prev, reactionTime]);
    setStartReaction(null);
  };
  
  const startTest = () => {
    setPractice(false)
    setStart(true);
  };

  const startPractice = () => {
    setStart(true);
  };

  // call API to submit the form data 
  const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.resposne && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }

  if (!start || boyAppearances < 20) {
    return (
      <div style={{padding: "10px 5px 20px"}}>
        <View style={styles.container2}>
          {start && (
            <>
            <Text style={{fontSize: 20}}>Please click on the button below when you see the boy.</Text>
            <Text style={{fontSize: 20}}>Correct Clicks: {correctClicks}</Text>
          </>
          )}
          {start && (
            <TouchableOpacity style={{backgroundColor: color, top: '60%', paddingVertical: 10, paddingHorizontal: 40, borderRadius: 25, marginTop: '2rem'}} onPress={onPressButton}>
              <Text style={styles.buttonText}>Click Here!</Text>
            </TouchableOpacity>
          )}
          {!start && (
            <div>
              <View>
                <Text style={styles.titleText}>Reaction Time Test</Text>
                <Text style={styles.instructionText}>
                  You will be shown a button and series of 20 pictures... When the picture pops up, click the button once 
                  (you will be penalized for excess clicks). Try to click the button as fast as possible when the picture appears.
                </Text>
              </View>
              {!submittedToday && (
                <View style={styles.buttonContainer}>
                  <TouchableOpacity style={styles.button} onPress={startTest}>
                    <Text style={styles.buttonText}>Start</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.button} onPress={startPractice}>
                    <Text style={styles.buttonText}>Practice</Text>
                  </TouchableOpacity>
                </View>
              )}
              {submittedToday && (
                <View style={styles.buttonContainer}>
                  <TouchableOpacity style={styles.button}>
                    <Text style={styles.buttonText}>Start</Text>
                  </TouchableOpacity>
                  <TouchableOpacity style={styles.button}>
                    <Text style={styles.buttonText}>Practice</Text>
                  </TouchableOpacity>
                  <p style={{color: "gray", fontSize: "20px", textAlign: 'center'}}>(Already submitted today)</p>
                </View>
              )}
            </div>
          )}
        </View>
        <View>
          {showBoy && (
            <View
              style={{
                position: "absolute",
                top: boyPosition.top,
                left: boyPosition.left,
                padding: 10,
                backgroundColor: "#ddd",
                borderRadius: 10,
              }}
            >
              <Image
                source={{ uri: "https://iheartcraftythings.com/wp-content/uploads/2021/11/6-48.jpg" }}
                style={{ width: 150, height: 150 }}
              />
            </View>
          )}
        </View>
        {/* <View style={styles.container2}>
          {showBoy && position1 && (
            <View style={{bottom: '2.5rem', right: '75%', position: 'absolute', padding: 20, backgroundColor: '#ddd', borderRadius: 10}}>
              <Image
                source={{ uri: 'https://iheartcraftythings.com/wp-content/uploads/2021/11/6-48.jpg' }}
                style={{ width: 200, height: 200 }}
              />
            </View>
          )}

          {showBoy && position2 && (
            <View style={{bottom: '2.5rem', left: '75%', position: 'absolute', padding: 20, backgroundColor: '#ddd', borderRadius: 10}}>
              <Image
                source={{ uri: 'https://iheartcraftythings.com/wp-content/uploads/2021/11/6-48.jpg' }}
                style={{ width: 200, height: 200 }}
              />
            </View>
          )}

          {showBoy && position3 && (
            <View style={{bottom: '28.5rem', right: '75%', position: 'absolute', padding: 20, backgroundColor: '#ddd', borderRadius: 10}}>
              <Image
                source={{ uri: 'https://iheartcraftythings.com/wp-content/uploads/2021/11/6-48.jpg' }}
                style={{ width: 200, height: 200 }}
              />
            </View>
          )}

          {showBoy && position4 && (
            <View style={{bottom: '28.5rem', left: '75%', position: 'absolute', padding: 20, backgroundColor: '#ddd', borderRadius: 10}}>
              <Image
                source={{ uri: 'https://iheartcraftythings.com/wp-content/uploads/2021/11/6-48.jpg' }}
                style={{ width: 200, height: 200 }}
              />
            </View>
          )}

          {showBoy && position5 && (
            <View style={{bottom: '28.5rem', position: 'absolute', padding: 20, backgroundColor: '#ddd', borderRadius: 10}}>
              <Image
                source={{ uri: 'https://iheartcraftythings.com/wp-content/uploads/2021/11/6-48.jpg' }}
                style={{ width: 200, height: 200 }}
              />
            </View>
          )}

          {showBoy && position6 && (
            <View style={{bottom: '2.5rem', position: 'absolute', padding: 20, backgroundColor: '#ddd', borderRadius: 10}}>
              <Image
                source={{ uri: 'https://iheartcraftythings.com/wp-content/uploads/2021/11/6-48.jpg' }}
                style={{ width: 200, height: 200 }}
              />
            </View>
          )}

          {showBoy && position7 && (
            <View style={{bottom: '15rem', left: '75%', position: 'absolute', padding: 20, backgroundColor: '#ddd', borderRadius: 10}}>
              <Image
                source={{ uri: 'https://iheartcraftythings.com/wp-content/uploads/2021/11/6-48.jpg' }}
                style={{ width: 200, height: 200 }}
              />
            </View>
          )}

          {showBoy && position8 && (
            <View style={{bottom: '15rem', right: '75%', position: 'absolute', padding: 20, backgroundColor: '#ddd', borderRadius: 10}}>
              <Image
                source={{ uri: 'https://iheartcraftythings.com/wp-content/uploads/2021/11/6-48.jpg' }}
                style={{ width: 200, height: 200 }}
              />
            </View>
          )}
        </View> */}
      </div>
    );
  }

  const totalReactionTime = reactionTimes.reduce((total, time) => total + time, 0);
  const dummy=((Math.round((totalReactionTime / reactionTimes.length) * 1000) / 1000) / 1000).toFixed(3);
  const averageReactionTimeInSeconds = isNaN(dummy)?0:dummy;
  const missedClicks = 20 - correctClicks;
  const totalClicks = correctClicks + incorrectClicks;
  const accuracy = Math.round((correctClicks / (totalClicks + missedClicks))*1000) / 1000;
  const dummy2=Math.round((accuracy / averageReactionTimeInSeconds)*1000) / 1000;
  const score = isNaN(dummy2)?0:dummy2;
  
  return (
    <View style={styles.result_container}>
      <View style={styles.scoreContainer}>
        <Text style={styles.largeText}>✅ Correct: <Text style={styles.highlight}>{correctClicks}</Text></Text>
        <Text style={styles.largeText}>❌ Incorrect: <Text style={styles.highlight}>{incorrectClicks}</Text></Text>
        <Text style={styles.largeText}>🚫 Misses: <Text style={styles.highlight}>{missedClicks}</Text></Text>
        <Text style={styles.largeText}>🎯 Accuracy: <Text style={styles.highlight}>{accuracy}%</Text></Text>
        <Text style={styles.largeText}>⏱ Avg. Reaction Time: <Text style={styles.highlight}>{averageReactionTimeInSeconds} sec</Text></Text>
        <Text style={styles.largeText}>🏆 Score: <Text style={styles.highlight}>{score}</Text></Text>
      </View>

    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    margin: '25rem',
    flex: 1,
  },
  container2: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  boyBox: {
    position: 'absolute', 
    padding: 20,
    backgroundColor: '#ddd',
    borderRadius: 10,
  },
  buttonContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    marginTop: 20,
    gap: 15, // Adds space between buttons
  },
  button: {
    backgroundColor: "#007BFF",
    paddingVertical: 12,
    paddingHorizontal: 35,
    borderRadius: 25,
    shadowColor: "#000",
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 4 },
    shadowRadius: 5,
    elevation: 5, // Shadow for Android
  },
  buttonText: {
    color: "white",
    fontSize: 18,
    fontWeight: "bold",
    textAlign: "center",
  },
  titleText: {
    fontSize: 30,
  },
  instructionText: {
    color: 'gray',
    fontSize: 20,
    marginTop: '1rem'
  },
  largeText: {
    fontSize: 30,
  },
  result_container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f5f5f5', // Light background for contrast
    padding: 20,
  },
  scoreContainer: {
    backgroundColor: '#ffffff',
    padding: 20,
    borderRadius: 15,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 5 },
    shadowRadius: 10,
    elevation: 5, // Shadow for Android
    width: '90%',
    alignItems: 'center',
    marginVertical: 20,
  },
  highlight: {
    fontWeight: 'bold',
    color: '#007BFF',
  },
});

export default TestComponent;
