// src/SignUp.js
import { signUp, federatedSignIn, exchangeCodeForTokens} from "./auth";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./CognitoUI.css";
import TermsAndConditions from "./TermsAndConditions";
import CustomEmailValidation from "./CustomEmailValidation";
import moment from 'moment-timezone';
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa";
import { useAuthContext } from "../../hooks/useAuthContext";

function CognitoSignup() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [success, setSuccess] = useState(false);
  
  const [isPendingRegister, setIsPendingRegister] = useState(false);

  // Added new state for Date of Birth
  const [dob, setDob] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password2, setPassword2] = useState("");
  const [signupError, setSignupError] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [allFields, setAllFields] = useState(false);
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [hasScrolledToBottom, setHasScrolledToBottom] = useState(false);

  // Add new state for timezone
  const [timezone, setTimezone] = useState(moment.tz.guess()); // Automatically guess user's timezone
  const [timezoneSearch, setTimezoneSearch] = useState(""); // New state for timezone search
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

  const dropdownRef = useRef(null); // Create a ref for the dropdown
  const { dispatch } = useAuthContext()
  
  let navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const invite_id = queryParams.get("invite_id");
  const orgtype = queryParams.get("orgtype");

  // New function to calculate age from date of birth
  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();

    // If the birth month and day haven't passed in the current year, subtract 1 from age
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() - 1 < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const handleScroll = (e) => {
    const atBottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight;
    setHasScrolledToBottom(atBottom);
  };

  const handleFederatedSignIn = (provider) => {
    federatedSignIn(provider);
  };
  useEffect(() => {
    const code = queryParams.get('code');
    if (code) {
        exchangeCodeForTokens(code, dispatch);
    }
}, [queryParams]);
  useEffect(() => {
    const code = queryParams.get('code');
    if (code) {
        exchangeCodeForTokens(code, dispatch);
    }
  }, [queryParams]);
  const handleAcceptTerms = async () => {
    if (hasScrolledToBottom) {
      setHasAgreedToTerms(true);
      setIsTermsModalOpen(false);
      if (isPendingRegister) {
        const signUpSuccess = await executeSignUp();
        console.log("sign up success status: " + signUpSuccess);
        if (signUpSuccess) {
          navigateToConfirm();
        } else {
          if (!signupError) {
            setSignupError("Failed to sign up. Please refresh and try again.");
          }
        }
      }
    } else {
      setSignupError(
        "Please scroll to the bottom of the terms and conditions first."
      );
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const signUpUser = async () => {
    setSignupError("");
    try {
      await signUp(email, password, firstName, lastName, timezone);
      return true;
    } catch (err) {
      console.log("Error during sign up:", err);
      if (err.code === "UsernameExistsException") {
        setSignupError("An account with this email already exists. Please try logging in.");
        return false;
      } else {
        setSignupError(err.message);
        return false;
      }
    }
    
  };

  const navigateToConfirm = () => {
    sessionStorage.setItem("recentlySignedUpUsername", email);
    const destination = invite_id
      ? `/confirm-signup?invite_id=${invite_id}&orgtype=${orgtype}`
      : "/confirm-signup";
    navigate(destination);
  };

  

  const handleSignUp = async (e) => {
    e.preventDefault();
    setSignupError(""); 

    // Validate form fields
    if (!firstName || !lastName || !dob || !email || !password || !password2 || !timezone) {
      setSignupError("Please complete all fields.");
      return;
    }

    // Check if age is under 13
    const age = calculateAge(dob);
    if (age < 13) {
      setSignupError("You must be at least 13 years old to sign up.");
      return;
    }

    // Validate password
    const passwordRegex = /^(?!\s+)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]).{8,256}(?<!\s)$/;
    if (!passwordRegex.test(password)) {
      setSignupError("Password must contain at least one uppercase letter, one lowercase letter, one number, one special character, and be at least 8 characters long.");
      return;
    }
    if (password !== password2) {
      setSignupError("Passwords do not match.");
      return;
    }

    // proceed with Terms & Conditions
    setIsTermsModalOpen(true);
    setIsPendingRegister(true);
    setSignupError("Please accept the terms and conditions first.");
    return;
  };

  const executeSignUp = async () => {
    console.log("execute sign up");
    try {
      const resp = await signUpUser();
      return resp;
    } catch (err) {
      if (err.code === "UsernameExistsException") {
        setSignupError("An account with this email already exists. Please try logging in.");
        return false;
      } else {
      setSignupError(err.message);
      return false;
      }
    }
  };

  const handlePasswordDisplay = () => {
    if (displayPassword === true) {
      setDisplayPassword(false);
      setPasswordType("password");
    } else if (displayPassword === false) {
      setDisplayPassword(true);
      setPasswordType("text");
    }
  };

  // **Added Section: Calculate Yesterday's Date**
  const today = new Date();
  today.setDate(today.getDate() - 1); // Subtract one day to exclude today
  const maxDate = today.toISOString().split("T")[0]; // Format the date to YYYY-MM-DD

  // function to get all timezone options 
  const getNearbyTimezoneOptions = () => {
    const timezones = moment.tz.names(); // Get all timezone names
    const groupedTimezones = {};

    timezones.forEach((tz) => {
      const offset = moment.tz(tz).format('Z'); // Get the GMT offset
      const label = `(GMT${offset}) ${tz.replace(/_/g, ' ')}`; // Format label with GMT offset
      const continent = tz.split("/")[0]; // Get continent from timezone string
      if (!groupedTimezones[continent]) {
        groupedTimezones[continent] = [];
      }
      groupedTimezones[continent].push({ value: tz, label }); // Store value and label
    });

    return groupedTimezones; // Return all timezones 
  };

  const handleTimezoneInputClick = () => {
    setIsDropdownOpen(true); // Open the dropdown when the input is clicked
    setShowAllTimezones(false); // Ensure "Other" options are not shown when reopening
    setTimezoneSearch(""); // Clear the search input when reopening the dropdown
  };

  const handleOtherOptionClick = () => {
    setShowAllTimezones((prev) => !prev); // Toggle showing all timezones
  };

  const guessedTimezone = moment.tz.guess(); // Get the guessed timezone
  const guessedTimezoneLabel = `(GMT${moment.tz(guessedTimezone).format('Z')}) ${guessedTimezone.replace(/_/g, ' ')}`; // Format guessed timezone label

  // State to control showing all timezones
  const [showAllTimezones, setShowAllTimezones] = useState(false);

  const getTimezoneOptions = () => {
    const timezones = moment.tz.names(); // Get all timezone names
    const groupedTimezones = {};

    timezones.forEach((tz) => {
      const offset = moment.tz(tz).format('Z'); // Get the GMT offset
      const label = `(GMT${offset}) ${tz.replace(/_/g, ' ')}`; // Format label with GMT offset
      const continent = tz.split("/")[0]; // Get continent from timezone string
      if (!groupedTimezones[continent]) {
        groupedTimezones[continent] = [];
      }
      groupedTimezones[continent].push({ value: tz, label }); // Store value and label
    });

    return groupedTimezones; // Return all timezones grouped by continent
  };

  const timezoneOptions = getTimezoneOptions(); // Get timezone options

  const [selectedTimezone, setSelectedTimezone] = useState(timezone); // Track the selected timezone

  const handleTimezoneSelect = (tz) => {
    setSelectedTimezone(tz); // Set the selected timezone
    setTimezone(tz); // Update the main timezone state
    setIsDropdownOpen(false); // Close the dropdown
    setShowAllTimezones(false); // Reset to not show all timezones
  };

  const filteredTimezones = Object.entries(getNearbyTimezoneOptions()).reduce((acc, [continent, timezones]) => {
    const filtered = timezones.filter(tz => tz.value.toLowerCase().includes(timezoneSearch.toLowerCase()));
    if (filtered.length > 0) {
      acc[continent] = filtered;
    }
    return acc;
  }, {});

  const handleClickOutside = (event) => {
    // Check if the click is outside the dropdown
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false); // Close the dropdown
      setShowAllTimezones(false); // Ensure "Other" options are closed
      setTimezoneSearch(""); // Clear the search input when closing the dropdown
    }
  };

  useEffect(() => {
    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div>
      <br />
      <br />
      <br />
      <br />

      <div className="cognito_login_box">
        <form onSubmit={handleSignUp}>
          <h4 className="cognito_login_title">Register</h4>
          <label className="cognito_login_label">First name:</label>
          <div>
            <input
              className="cognito_lable_box"
              type="text"
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              value={firstName}
            />
          </div>
          <label className="cognito_login_label">Last name:</label>
          <div>
            <input
              className="cognito_lable_box"
              type="text"
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              value={lastName}
            />
          </div>
          <label className="cognito_login_label">Date of Birth:</label>{" "}
          {/* Added Date of Birth field */}
          <div>
            <input
              className="cognito_lable_box"
              type="date"
              onChange={(e) => {
                setDob(e.target.value);
              }}
              value={dob}
              max={maxDate} // **Added Attribute**
              required
            />
          </div>
          <label className="cognito_login_label">Email:</label>
          <div>
            <CustomEmailValidation
              className="cognito_lable_box"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <label className="cognito_login_label">Timezone:</label>
          <div className="timezone-field">
            <input
              className="cognito_lable_box"
              type="text"
              placeholder="Search timezones..."
              value={timezone || moment.tz.guess()} // Show the guessed timezone if none is selected
              onChange={(e) => {
                setTimezoneSearch(e.target.value); // Update search state
                setIsDropdownOpen(true); // Open dropdown on input change
              }}
              onFocus={handleTimezoneInputClick} // Open dropdown on focus
              onClick={handleTimezoneInputClick} // Open dropdown on click
            />
            {isDropdownOpen && (
              <div className="timezone-dropdown" ref={dropdownRef}>
                <div
                  className={`timezone-option ${selectedTimezone === guessedTimezone ? 'selected-timezone' : ''}`} // Add selected class if guessed timezone is selected
                  onClick={() => handleTimezoneSelect(guessedTimezone)} // Make guessed timezone selectable
                  style={{ backgroundColor: selectedTimezone === guessedTimezone ? '#d3d3d3' : '#f0f0f0', cursor: 'pointer' }} // Gray background if selected, light gray otherwise
                >
                  {guessedTimezoneLabel} {/* Show guessed timezone with continent */}
                </div>
                <div
                  className={`timezone-option ${showAllTimezones ? 'selected-timezone' : ''}`} // Add selected class if "Other" is selected
                  onClick={handleOtherOptionClick} // Click handler for "Other"
                  style={{ cursor: 'pointer', fontWeight: 'bold', color: 'black' }} // Bold and black text for "Other"
                >
                  Other
                </div>
                {showAllTimezones && (
                  <div>
                    <input
                      className="cognito_lable_box"
                      type="text"
                      placeholder="Search other timezones..."
                      value={timezoneSearch} // Bind the search input to the state
                      onChange={(e) => setTimezoneSearch(e.target.value)} // Update search state
                    />
                    {Object.entries(timezoneOptions).map(([continent, timezones]) => {
                      // Filter timezones based on the search input
                      const filteredTimezones = timezones.filter(tz => tz.label.toLowerCase().includes(timezoneSearch.toLowerCase()));
                      return filteredTimezones.length > 0 && (
                        <div key={continent}>
                          <strong>{continent}</strong>
                          {filteredTimezones.map((tz) => (
                            <div
                              key={tz.value}
                              className={`timezone-option ${tz.value === selectedTimezone ? 'selected-timezone' : ''}`} // Add selected class if timezone is selected
                              onClick={() => handleTimezoneSelect(tz.value)} // Select timezone
                              style={{
                                backgroundColor: tz.value === selectedTimezone ? '#d3d3d3' : 'transparent', // Gray background if selected
                                cursor: 'pointer', // Change cursor to pointer
                              }}
                            >
                              {tz.label}
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                )}
                {Object.keys(timezoneOptions).length === 0 && (
                  <div className="timezone-option">No results found</div>
                )}
              </div>
            )}
          </div>
          <label className="cognito_login_label">Password:</label>
          <div>
            <input
              className="cognito_lable_box"
              type={passwordType}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              value={password}
            />
          </div>
          <label className="cognito_login_label">Confirm password:</label>
          <div>
            <input
              className="cognito_lable_box"
              type={passwordType}
              onChange={(e) => {
                setPassword2(e.target.value);
              }}
              value={password2}
            />
          </div>
          <div>
            <input
              style={{ marginRight: "10px", marginTop: "10px" }}
              type="checkbox"
              onChange={() => {
                handlePasswordDisplay();
              }}
            />
            {!displayPassword && <span>Show password</span>}
            {displayPassword && <span>Hide password</span>}
          </div>
          <div>
            <p className="cognito_login_error">{signupError}</p>
            <p className="cognito_login_note">
              Note: the password must contain at least one capital letter, one
              lowercase letter, one special character, and a number. It must
              also be at least 8 characters long.
            </p>
            <p className="cognito_login_note">
              Note: Current models are not well calibrated for estimating life
              expectancy of individuals below 18 years of age.
            </p>
          </div>
          <div>
            <button className="cognito_login_submit">Submit</button>
          </div>
          <br></br>

          <h6 className = "cognito_login_text">OR</h6>

          <button className="cognito_login_submit social-login-button" onClick={() => handleFederatedSignIn("Google")}>
          <FcGoogle />
            Sign up with Google
          </button>
          <button className="cognito_login_submit social-login-button" onClick={() => handleFederatedSignIn("Facebook")}>
          <FaFacebook color="#3b5998" />
            Sign up with Facebook
          </button>

        </form>
      </div>
      {isTermsModalOpen && (
        <div className="modal">
          <div className="modal-content" onScroll={handleScroll}>
            <span
              className="close-button"
              onClick={() => setIsTermsModalOpen(false)}
            >
              &times;
            </span>
            <TermsAndConditions
              setHasScrolledToBottom={setHasScrolledToBottom}
            />
            <button onClick={handleAcceptTerms} disabled={!hasScrolledToBottom} style={{marginBottom: "20px"}}>
              Accept
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CognitoSignup;
