import React, { useState, useEffect, useMemo } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { fetchData } from './action';
import { useNavigate } from 'react-router-dom';

function DailyProgress() {
    const navigate = useNavigate();
    const [walkspeed, setWalkSpeed] = useState("");
    const [grip, setGrip] = useState("");
    const [weight, setWeight] = useState("");
    const [systolic, setSystolic] = useState("");
    const [diastolic, setDiastolic] = useState("");
    const [chototal, setChototal] = useState("");
    const [choldl, setCholdl] = useState("");
    const [chohdl, setChohdl] = useState("");
    const [submittedToday, setSubmittedToday] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [firstSubmit, setFirstSubmit] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const userId = useMemo(() => {
        let user = localStorage.getItem("mysUser");
        user = user ? JSON.parse(user) : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
        return user.userId;
    }, []);

    const baseUrlAWS = process.env.REACT_APP_baseUrl;
    const baseUrl = `${baseUrlAWS}api/myprogress`;

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            try {
                const response = await fetchData(userId);
                
                if (response && response.status === 201) {
                    const data = response.data;
                    setWalkSpeed(data.walkspeed || "");
                    setGrip(data.grip || "");
                    setWeight(data.weight || "");
                    setSystolic(data.systolic || "");
                    setDiastolic(data.diastolic || "");
                    setChototal(data.chototal || "");
                    setCholdl(data.choldl || "");
                    setChohdl(data.chohdl || "");

                    const testDate = new Date(data.submission_date.substring(0,10).split('-')).toUTCString().substring(0,16);
                    const currDate = new Date().toUTCString().substring(0,16);
                    setSubmittedToday(testDate === currDate);
                }
            } catch (error) {
                console.error('Error loading data:', error);
            } finally {
                setIsLoading(false);
            }
        };
        loadData();
    }, [userId]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        if (submittedToday) return;

        const totalCholesterol = parseFloat(chototal);
        const ldl = parseFloat(choldl);
        const hdl = parseFloat(chohdl);
        
        if (totalCholesterol <= (ldl + hdl)) {
            setErrorMessage("Total cholesterol must be greater than the sum of LDL and HDL.");
            return;
        } else {
            setErrorMessage("");
        }

        const formData = {
            user: userId,
            walkspeed,
            grip,
            weight,
            systolic,
            diastolic,
            chototal,
            choldl,
            chohdl
        };

        try {
            setFirstSubmit(true);
            const response = await axios.post(
                `${baseUrl}/create-progress-response/`,
                formData,
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 201) {
                setSubmittedToday(true);
            }
        } catch (error) {
            setFirstSubmit(false);
            console.error('Error submitting form:', error);
        }
    };

    const getStyles = () => ({
        container: {
            backgroundColor: 'white',
            borderRadius: '12px',
            padding: '32px',
            margin: '2rem auto',
            maxWidth: '1200px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            position: 'relative',
            fontFamily: 'Montserrat'
        },
        section: {
            marginBottom: '32px',
            backgroundColor: '#f8f9fa',
            padding: '24px',
            borderRadius: '8px',
            transition: 'all 0.2s ease'
        },
        sectionTitle: {
            color: '#000000',
            fontSize: '1.25rem',
            fontWeight: '400',
            marginBottom: '20px',
            borderBottom: '2px solid #dee2e6',
            paddingBottom: '12px',
            letterSpacing: '0.07em'
        },
        inputGroup: {
            marginBottom: '24px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px'
        },
        label: {
            color: '#000000',
            fontSize: '18px',
            fontWeight: '400',
            letterSpacing: '0.07em'
        },
        input: {
            width: '250px',
            padding: '10px',
            border: '1px solid #000000',
            borderRadius: '10px',
            fontSize: '16px',
            color: submittedToday ? '#6c757d' : '#000000',
            backgroundColor: submittedToday ? '#e9ecef' : 'white',
            transition: 'all 0.2s ease',
            cursor: submittedToday ? 'not-allowed' : 'default'
        },
        submitButton: {
            backgroundColor: submittedToday || firstSubmit ? '#6c757d' : '#007bff',
            opacity: submittedToday || firstSubmit ? '0.65' : '1',
            color: 'white',
            border: 'none',
            padding: '10px 24px',
            borderRadius: '10px',
            cursor: submittedToday || firstSubmit ? 'not-allowed' : 'pointer',
            fontSize: '16px',
            fontWeight: '400',
            letterSpacing: '0.07em',
            transition: 'all 0.2s ease'
        },
        returnButton: {
            position: 'absolute',
            top: '40px',
            right: '20px',
            padding: '8px 16px',
            backgroundColor: '#6c757d',
            color: 'white',
            border: 'none', 
            borderRadius: '10px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: '400',
            letterSpacing: '0.07em',
            transition: 'all 0.2s ease',
            '&:hover': {
                opacity: '0.9'
            }
        },
        title: {
            fontSize: '32px',
            fontWeight: '400',
            letterSpacing: '0.07em',
            marginTop: '1rem',
            marginBottom: '2rem',
            textAlign: 'center'
        },
        bloodPressureContainer: {
            display: 'flex',
            alignItems: 'center',
            gap: '16px'
        },
        bloodPressureDivider: {
            color: '#212529',
            fontSize: '1.2rem',
            fontWeight: '500',
            margin: '0 12px'
        }
    });

    const handleBlur = (field, value) => {
        // Add your blur handler logic here
        console.log(`${field} blurred with value: ${value}`);
    };

    return (
        <div style={getStyles().container}>
            <button 
                onClick={() => navigate('/progress')}
                style={getStyles().returnButton}
            >
                Return to My Progress
            </button>
            <h2 style={getStyles().title}>General Health Progress Entry</h2>
            
            {isLoading ? (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <Form onSubmit={handleSubmit}>
                    <div style={getStyles().section}>
                        <div style={getStyles().sectionTitle}>Physical Measurements</div>
                        <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '20px'}}>
                            <div style={getStyles().inputGroup}>
                                <label style={getStyles().label}>Walking Speed (ft/s)</label>
                                <input
                                    type="number"
                                    value={walkspeed}
                                    style={getStyles().input}
                                    min="0"
                                    required
                                    disabled={submittedToday}
                                    onBlur={(e) => handleBlur('walkspeed', e.target.value)}
                                    onChange={(e) => {
                                        if (!submittedToday) {
                                            const newValue = e.target.value;
                                            if(newValue >= 0) {
                                                setWalkSpeed(newValue);
                                            }
                                        }
                                    }}
                                />
                            </div>

                            <div style={getStyles().inputGroup}>
                                <label style={getStyles().label}>Grip Strength (lbs)</label>
                                <input
                                    type="number"
                                    value={grip}
                                    style={getStyles().input}
                                    min="0"
                                    required
                                    disabled={submittedToday}
                                    onBlur={(e) => handleBlur('grip', e.target.value)}
                                    onChange={(e) => {
                                        if (!submittedToday) {
                                            const newValue = e.target.value;
                                            if(newValue >= 0) {
                                                setGrip(newValue);
                                            }
                                        }
                                    }}
                                />
                            </div>

                            <div style={getStyles().inputGroup}>
                                <label style={getStyles().label}>Weight (lbs)</label>
                                <input
                                    type="number"   
                                    value={weight}
                                    style={getStyles().input}
                                    min="0"
                                    required
                                    disabled={submittedToday}
                                    onBlur={(e) => handleBlur('weight', e.target.value)}
                                    onChange={(e) => {
                                        if (!submittedToday) {
                                            const newValue = e.target.value;
                                            if(newValue >= 0) {
                                                setWeight(newValue);
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={getStyles().section}>
                        <div style={getStyles().sectionTitle}>Blood Pressure</div>
                        <div style={getStyles().inputGroup}>
                            <label style={getStyles().label}>Blood Pressure (mmHg)</label>
                            <div style={getStyles().bloodPressureContainer}>
                                <input
                                    type="number"
                                    value={systolic}
                                    style={{...getStyles().input, width: '80px'}}
                                    min="0"
                                    required
                                    placeholder="120"
                                    disabled={submittedToday}
                                    onBlur={(e) => handleBlur('systolic', e.target.value)}
                                    onChange={(e) => {
                                        if (!submittedToday) {
                                            const newValue = e.target.value;
                                            if(newValue >= 0) {
                                                setSystolic(newValue);
                                            }
                                        }
                                    }}
                                />
                                <span style={getStyles().bloodPressureDivider}>/</span>
                                <input
                                    type="number"
                                value={diastolic}
                                style={{...getStyles().input, width: '80px'}}
                                min="0"
                                required
                                placeholder="80"
                                disabled={submittedToday}
                                onBlur={(e) => handleBlur('diastolic', e.target.value)}
                                onChange={(e) => {
                                    if (!submittedToday) {
                                        const newValue = e.target.value;
                                        if(newValue >= 0) {
                                            setDiastolic(newValue);
                                        }
                                    }
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={getStyles().section}>
                        <div style={getStyles().sectionTitle}>Cholesterol</div>
                        <div style={{display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))', gap: '20px'}}>
                            <div style={getStyles().inputGroup}>
                                <label style={getStyles().label}>Total</label>
                                <input
                                    type="number"
                                    value={chototal}
                                    style={getStyles().input}
                                    min="0"
                                    required
                                    disabled={submittedToday}
                                    onBlur={(e) => handleBlur('chototal', e.target.value)}
                                    onChange={(e) => {
                                        if (!submittedToday) {
                                            const newValue = e.target.value;
                                            if(newValue >= 0) {
                                                setChototal(newValue);
                                            }
                                        }
                                    }}
                                />
                                {errorMessage && (parseFloat(chototal) <= (parseFloat(choldl) + parseFloat(chohdl))) && (
                                    <p style={{color: 'red'}}>{errorMessage}</p>
                                )}
                            </div>

                            <div style={getStyles().inputGroup}>
                                <label style={getStyles().label}>LDL</label>
                                <input
                                    type="number"
                                    value={choldl}
                                    style={getStyles().input}
                                    min="0"
                                    required
                                    disabled={submittedToday}
                                    onBlur={(e) => handleBlur('choldl', e.target.value)}
                                    onChange={(e) => {
                                        if (!submittedToday) {
                                            const newValue = e.target.value;
                                            if(newValue >= 0) {
                                                setCholdl(newValue);
                                            }
                                        }
                                    }}
                                />
                            </div>

                            <div style={getStyles().inputGroup}>
                                <label style={getStyles().label}>HDL</label>
                                <input
                                    type="number"
                                    value={chohdl}
                                    style={getStyles().input}
                                    min="0"
                                    required
                                    disabled={submittedToday}
                                    onBlur={(e) => handleBlur('chohdl', e.target.value)}
                                    onChange={(e) => {
                                        if (!submittedToday) {
                                            const newValue = e.target.value;
                                            if(newValue >= 0) {
                                                setChohdl(newValue);
                                            }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{textAlign: 'center', marginBottom: '22px'}}>
                        <button
                            type="submit"
                            style={getStyles().submitButton}
                            disabled={submittedToday || firstSubmit}
                        >
                            {submittedToday || firstSubmit ? 'Saved for Today' : 'Submit'}
                        </button>
                    </div>
                </Form>
            )}
        </div>
    );
}

export default DailyProgress;