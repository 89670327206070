import React, { useState, useEffect } from "react";
import "./MessageArea.css";

export const highlightWord = (text, word) => {
  const parts = text.split(new RegExp(`(${word})`, 'gi'));
  return (
    <span>
      {parts.map((part, index) =>
        part.toLowerCase() === word.toLowerCase() ? (
          <span key={index} style={{ fontSize: '24px', color: '#FFD700', fontWeight: 'bold' }}>{part}</span>
          // <span key={index} style={{ backgroundColor: '#FFD700', color: '#333', fontWeight: 'bold', padding: '2px 4px', borderRadius: '3px' }}>{part}</span>
        ) : (
          <span key={index} style={{ fontSize: '22px', fontWeight: 'bold'}}>{part}</span>
        )
      )}
    </span>
  );
};
const MessageArea = () => {
  const [messages, setMessages] = useState(["Welcome to the MyYouthspan!",]);

  const [currentIndex, setCurrentIndex] = useState(0);

  const [numMessages, setNumMessages] = useState(1);

  const [scrollingUp, setScrollingUp] = useState(false);

  const baseURLAWS = process.env.REACT_APP_baseUrl

  useEffect(() => {
    const fetchData = async () => {
      try {
        // first get user id
        const userInfo = JSON.parse(localStorage.getItem('mysUser'))
        // Check if user is logged in
        console.log(userInfo)
        if (!userInfo || !userInfo.userId) {
          // Set default message if not logged in
          setMessages([
            "Welcome to the MyYouthspan! Subscribe with signup code: GROWYOUNG2025 for 50% off on your subscription for 3 months and more advanced features! Expires on 4/30/2025!",
          ]);
          return;
        }
        else if (!userInfo.isPremium) {
          setMessages([
            "Welcome to the MyYouthspan! Subscribe with signup code: GROWYOUNG2025 for 50% off on your subscription for 3 months and more advanced features! Expires on 4/30/2025!",
          ]);
          return;
        }
        // console.log(userInfo.userId)
        // Fetch data from API endpoint
        const response = await fetch(`${baseURLAWS}api/message-area/messages/${userInfo.userId}/`);
        const data = await response.json();
        const dataMessages = []

        for (let i = 0; i < data.length; i++) {
          dataMessages.push(data[i].body);
        }

        // Combine the test data and fetched data
        setMessages(dataMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    fetchData();
  }, []);

  const displayedMessages = messages

  // Update the index in a cyclic manner (vertically)
  useEffect(() => {
    if (messages.length > 0) {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % (messages.length));
      }, 5000); // the message frequency

      return () => clearInterval(intervalId);
    }
  }, [messages.length]);

  const isAtBottom =
    window.innerHeight + window.scrollY + 50 >= document.body.offsetHeight;
  return (
    <div className={`message-area ${isAtBottom ? "fixed-at-bottom" : ""}`}>
      <div className="message-container">
        {/* {displayedMessages.map((message, index) => (
          <div key={index} className="message">
            {message}
          </div>
        ))} */}
        <div className="message">
          {displayedMessages.map((msg, index) => (
            <div className="messageText" key={index}>{highlightWord(msg, 'GROWYOUNG2025')}</div>
          ))}
        </div>
      </div>
    </div>
  );
};


export default MessageArea;
