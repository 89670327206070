import React from 'react'
import InputSingle from '../../../components/questionnaireElement/InputSingle'
import InputCheckBox from '../../../components/questionnaireElement/InputCheckBox'
import InputMulti from '../../../components/questionnaireElement/InputMulti'
import SupplementWarning from '../../../components/SupplementWarning'
import { useEffect, useState } from 'react'
import {
    fiberLabel, proteinLabel, magnesiumLabel, manganeseLabel, phosphorusLabel, potassiumLabel, vitaminALabel, vitaminCLabel, vitaminDLabel, vitaminKLabel,
    biotinLabel, chlorideLabel, chromiumLabel, copperLabel, folateFolicAcidLabel, molybdenumLabel, niacinLabel, pantothenicAcidLabel, riboflavinLabel, seleniumLabel,
    thiamineLabel, cholineLabel, vitaminB6Label, vitaminB12Label, vitaminELabel, zincLabel, iodineLabel, ironLabel, nickelLabel, fishOilOmega3Label,
    melatoninLabel, turmericLabel, garlicLabel, matchaGreenTeaLabel, plantSterolsLabel, nmnLabel, nrLabel, calciumAKGLabel, alphaLipoicAcidLabel, quercetinLabel,
    pqqLabel, hyaluronicAcidLabel, chlorellaLabel, spirulinaLabel, reveratrolLabel, coQ10Label, acetylLCarnitineLabel, oliveOilLabel, luteinLabel, milkThistleLabel,
    spermidineLabel, creatineLabel, trimethylGlycineLabel, calciumLabel,
    supplementsLabels
} from '../Sections/Lables/MySupplementLables'
import {
    fiberName, proteinName, magnesiumName, manganeseName, phosphorusName, potassiumName, vitaminAName, vitaminCName, vitaminDName, vitaminKName,
    biotinName, chlorideName, chromiumName, copperName, folateFolicAcidName, molybdenumName, niacinName, pantothenicAcidName, riboflavinName, seleniumName,
    thiamineName, cholineName, vitaminB6Name, vitaminB12Name, vitaminEName, zincName, iodineName, ironName, nickelName, fishOilOmega3Name,
    melatoninName, turmericName, garlicName, matchaGreenTeaName, plantSterolsName, nmnName, nrName, calciumAKGName, alphaLipoicAcidName, quercetinName,
    pqqName, hyaluronicAcidName, chlorellaName, spirulinaName, reveratrolName, coQ10Name, acetylLCarnitineName, oliveOilName, luteinName, milkThistleName,
    spermidineName, creatineName, trimethylGlycineName, calciumName,
    supplementsNames
} from '../Sections/NameTable/MySupplementName'
import {
    fiberOptions, proteinOptions, magnesiumOptions, manganeseOptions, phosphorusOptions, potassiumOptions, vitaminAOptions, vitaminCOptions, vitaminDOptions, vitaminKOptions,
    biotinOptions, chlorideOptions, chromiumOptions, copperOptions, folateFolicAcidOptions, molybdenumOptions, niacinOptions, pantothenicAcidOptions, riboflavinOptions, seleniumOptions,
    thiamineOptions, cholineOptions, vitaminB6Options, vitaminB12Options, vitaminEOptions, zincOptions, iodineOptions, ironOptions, nickelOptions, fishOilOmega3Options,
    melatoninOptions, turmericOptions, garlicOptions, matchaGreenTeaOptions, plantSterolsOptions, nmnOptions, nrOptions, calciumAKGOptions, alphaLipoicAcidOptions, quercetinOptions,
    pqqOptions, hyaluronicAcidOptions, chlorellaOptions, spirulinaOptions, reveratrolOptions, coQ10Options, acetylLCarnitineOptions, oliveOilOptions, luteinOptions, milkThistleOptions,
    spermidineOptions, creatineOptions, trimethylGlycineOptions, calciumOptions,
    supplementsOptions
} from '../Sections/OptionsTable/MySupplementOptions'
import {
    fiberDefault, proteinDefault, magnesiumDefault, manganeseDefault, phosphorusDefault, potassiumDefault,
    vitaminADefault, vitaminCDefault, vitaminDDefault, vitaminKDefault, biotinDefault, chlorideDefault,
    chromiumDefault, copperDefault, folateFolicAcidDefault, molybdenumDefault, niacinDefault, pantothenicAcidDefault,
    riboflavinDefault, seleniumDefault, thiamineDefault, cholineDefault, vitaminB6Default, vitaminB12Default,
    vitaminEDefault, zincDefault, iodineDefault, ironDefault, nickelDefault, fishOilOmega3Default,
    melatoninDefault, turmericDefault, garlicDefault, matchaGreenTeaDefault, plantSterolsDefault, nmnDefault,
    nrDefault, calciumAKGDefault, alphaLipoicAcidDefault, quercetinDefault, pqqDefault, hyaluronicAcidDefault,
    chlorellaDefault, spirulinaDefault, reveratrolDefault, coQ10Default, acetylLCarnitineDefault, oliveOilDefault,
    luteinDefault, milkThistleDefault, spermidineDefault, creatineDefault, trimethylGlycineDefault, calciumDefault
} from '../Sections/OptionsTable/MySupplementDefault'
import { UnitConvert } from '../actions'
const formDataKey = 'IntakeForm'; 
const formDataUnitKey = 'IntakeFormUnit'; 
const formDataUnitDefaultKey = 'IntakeFormUnitDefault';
export default function MySupplement({setHasChanges, dataState, setDataState, unitState, setUnitState, errors, limit, setLimit}) {
  const [cigaretteStatus, setCigaretteStatus] = useState('');
  const [formData, setformData] = useState(JSON.parse(sessionStorage.getItem(formDataKey)) || {});
  const [formDataUnit, setformDataUnit] = useState(JSON.parse(sessionStorage.getItem(formDataUnitKey)) || {});
  const [formDataUnitDefault, setformDataUnitDefault] = useState(JSON.parse(sessionStorage.getItem(formDataUnitDefaultKey)) || {});
  useEffect(() => {
    const savedData = sessionStorage.getItem(formDataKey);
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setformData(parsedData);
    }
  }, []);
  useEffect(() => {
    sessionStorage.setItem(formDataKey, JSON.stringify(formData));
  }, [formData])
  useEffect(() => {
    sessionStorage.setItem(formDataUnitDefaultKey, JSON.stringify(formDataUnitDefault));
  }, [formDataUnitDefault]);  
  const handleInputChange = (name, value) => {
    setHasChanges(true);
    
    let newValue = {...dataState, [name]:supplementsNames.includes(name) ?
        value : parseFloat(value)}
    setDataState(newValue)
  };
  const handleCheckBoxInputChange = (name, value) => {
    if(supplementsNames.includes(name)){
        for(let i=0;i<supplementsNames.length;i++){
            if(supplementsNames[i]===name) continue;
            dataState[supplementsNames[i]]=false;
        }
    }
    let newValue = {...dataState, [name]:value}
    setDataState(newValue)
  };
  const handleUnitChange = (name, value) => {
    const prevUnit = unitState[name]
    const newLimit = UnitConvert(limit[name], prevUnit, value, name).toFixed(2)
    setLimit({...limit, [name]: newLimit})
    const newUnits = {...unitState, [name]: value }
    setUnitState(newUnits)
    // const newVal = UnitConvert(dataState[name], prevUnit, value, name)
    // setDataState({...dataState, [name]: newVal})
  }
  const handleUnitDefaultChange = (name, value) => {
    setformDataUnitDefault(prevDefaults => ({
        ...prevDefaults,
        [name]: value  
    }));
  }
  return (
          <form>
            <div class='inlineContainer'>
              <small class="form-text">My Supplements</small>
            </div>
            <SupplementWarning />
            <InputCheckBox label={supplementsLabels} name={supplementsNames} onChange={handleCheckBoxInputChange} options={supplementsOptions} value={dataState}/>
            <InputMulti inputs={[
                {type: 'InputSingle', label: fiberLabel, options: fiberOptions, name: fiberName, default: fiberDefault},
                {type: 'InputSingle', label: proteinLabel, options: proteinOptions, name: proteinName, default: proteinDefault},
                {type: 'InputSingle', label: magnesiumLabel, options: magnesiumOptions, name: magnesiumName, default: magnesiumDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
                formData={dataState} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/>
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: manganeseLabel, options: manganeseOptions, name: manganeseName, default: manganeseDefault},
                {type: 'InputSingle', label: phosphorusLabel, options: phosphorusOptions, name: phosphorusName, default: phosphorusDefault},
                {type: 'InputSingle', label: potassiumLabel, options: potassiumOptions, name: potassiumName, default: potassiumDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            <InputMulti inputs={[
                {type: 'InputSingle', label: vitaminALabel, options: vitaminAOptions, name: vitaminAName, default: vitaminADefault},
                {type: 'InputSingle', label: vitaminKLabel, options: vitaminKOptions, name: vitaminKName, default: vitaminKDefault},
                {type: 'InputSingle', label: vitaminDLabel, options: vitaminDOptions, name: vitaminDName, default: vitaminDDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/>
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: vitaminCLabel, options: vitaminCOptions, name: vitaminCName, default: vitaminCDefault},
                {type: 'InputSingle', label: biotinLabel, options: biotinOptions, name: biotinName, default: biotinDefault},
                {type: 'InputSingle', label: chlorideLabel, options: chlorideOptions, name: chlorideName, default: chlorideDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: chromiumLabel, options: chromiumOptions, name: chromiumName, default: chromiumDefault},
                {type: 'InputSingle', label: copperLabel, options: copperOptions, name: copperName, default: copperDefault},
                {type: 'InputSingle', label: cholineLabel, options: cholineOptions, name: cholineName, default: cholineDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: molybdenumLabel, options: molybdenumOptions, name: molybdenumName, default: molybdenumDefault},
                {type: 'InputSingle', label: niacinLabel, options: niacinOptions, name: niacinName, default: niacinDefault},
                {type: 'InputSingle', label: pantothenicAcidLabel, options: pantothenicAcidOptions, name: pantothenicAcidName, default: pantothenicAcidDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: riboflavinLabel, options: riboflavinOptions, name: riboflavinName, default: riboflavinDefault},
                {type: 'InputSingle', label: seleniumLabel, options: seleniumOptions, name: seleniumName, default: seleniumDefault},
                {type: 'InputSingle', label: thiamineLabel, options: thiamineOptions, name: thiamineName, default: thiamineDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            <InputMulti inputs={[
                {type: 'InputSingle', label: folateFolicAcidLabel, options: folateFolicAcidOptions, name: folateFolicAcidName, default: folateFolicAcidDefault},
                {type: 'InputSingle', label: vitaminB6Label, options: vitaminB6Options, name: vitaminB6Name, default: vitaminB6Default},
                {type: 'InputSingle', label: vitaminB12Label, options: vitaminB12Options, name: vitaminB12Name, default: vitaminB12Default},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/>
            <InputMulti inputs={[
                {type: 'InputSingle', label: vitaminELabel, options: vitaminEOptions, name: vitaminEName, default: vitaminEDefault},
                {type: 'InputSingle', label: zincLabel, options: zincOptions, name: zincName, default: zincDefault},
                {type: 'InputSingle', label: calciumLabel, options: calciumOptions, name: calciumName, default: calciumDefault}
                // {type: 'InputSingle', label: iodineLabel, options: iodineOptions, name: iodineName, default: iodineDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/>
            <InputMulti inputs={[
                {type: 'InputSingle', label: ironLabel, options: ironOptions, name: ironName, default: ironDefault},
                {type: 'InputSingle', label: oliveOilLabel, options: oliveOilOptions, name: oliveOilName, default: oliveOilDefault},
                // {type: 'InputSingle', label: nickelLabel, options: nickelOptions, name: nickelName, default: nickelDefault},
                {type: 'InputSingle', label: fishOilOmega3Label, options: fishOilOmega3Options, name: fishOilOmega3Name, default: fishOilOmega3Default},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/>
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: melatoninLabel, options: melatoninOptions, name: melatoninName, default: melatoninDefault},
                {type: 'InputSingle', label: turmericLabel, options: turmericOptions, name: turmericName, default: turmericDefault},
                {type: 'InputSingle', label: garlicLabel, options: garlicOptions, name: garlicName, default: garlicDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            <InputMulti inputs={[
                {type: 'CalculatorInputSingle', label: matchaGreenTeaLabel, options: matchaGreenTeaOptions, name: matchaGreenTeaName, default: matchaGreenTeaDefault},
                {type: 'InputSingle', label: vitaminCLabel, options: vitaminCOptions, name: vitaminCName, default: vitaminCDefault},
                {type: ''},
                // {type: 'InputSingle', label: plantSterolsLabel, options: plantSterolsOptions, name: plantSterolsName, default: plantSterolsDefault},
                // {type: 'InputSingle', label: nmnLabel, options: nmnOptions, name: nmnName, default: nmnDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={unitState} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/>
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: nrLabel, options: nrOptions, name: nrName, default: nrDefault},
                {type: 'InputSingle', label: calciumAKGLabel, options: calciumAKGOptions, name: calciumAKGName, default: calciumAKGDefault},
                {type: 'InputSingle', label: alphaLipoicAcidLabel, options: alphaLipoicAcidOptions, name: alphaLipoicAcidName, default: alphaLipoicAcidDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: quercetinLabel, options: quercetinOptions, name: quercetinName, default: quercetinDefault},
                {type: 'InputSingle', label: pqqLabel, options: pqqOptions, name: pqqName, default: pqqDefault},
                {type: 'InputSingle', label: hyaluronicAcidLabel, options: hyaluronicAcidOptions, name: hyaluronicAcidName, default: hyaluronicAcidDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: chlorellaLabel, options: chlorellaOptions, name: chlorellaName, default: chlorellaDefault},
                {type: 'InputSingle', label: spirulinaLabel, options: spirulinaOptions, name: spirulinaName, default: spirulinaDefault},
                {type: 'InputSingle', label: reveratrolLabel, options: reveratrolOptions, name: reveratrolName, default: reveratrolDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: coQ10Label, options: coQ10Options, name: coQ10Name, default: coQ10Default},
                {type: 'InputSingle', label: acetylLCarnitineLabel, options: acetylLCarnitineOptions, name: acetylLCarnitineName, default: acetylLCarnitineDefault},
                {type: 'InputSingle', label: oliveOilLabel, options: oliveOilOptions, name: oliveOilName, default: oliveOilDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: luteinLabel, options: luteinOptions, name: luteinName, default: luteinDefault},
                {type: 'InputSingle', label: milkThistleLabel, options: milkThistleOptions, name: milkThistleName, default: milkThistleDefault},
                {type: 'InputSingle', label: spermidineLabel, options: spermidineOptions, name: spermidineName, default: spermidineDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
            {/* <InputMulti inputs={[
                {type: 'InputSingle', label: creatineLabel, options: creatineOptions, name: creatineName, default: creatineDefault},
                {type: 'InputSingle', label: trimethylGlycineLabel, options: trimethylGlycineOptions, name: trimethylGlycineName, default: trimethylGlycineDefault},
                {type: 'InputSingle', label: calciumLabel, options: calciumOptions, name: calciumName, default: calciumDefault},
            ]} onChange={handleInputChange} onChangeUnit={handleUnitChange} onChangeUnitDefault={handleUnitDefaultChange} 
            formData={formData} formUnit={formDataUnit} formDataUnitDefault={formDataUnit} value={dataState} error={errors} limit={limit} setLimit={setLimit}/> */}
          </form>
  )
}
