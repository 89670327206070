/* eslint-disable jsx-a11y/anchor-is-valid */
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Card, Button } from "react-bootstrap/";
import "./SubscriptionPlans.css";
import "./CardForm.css";
import axios from "axios";
import { useState, useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import myyouthspanlogo from "../components/images/myyouthspanlogo.jpg";
import Form from "react-bootstrap/Form";
import { useAuthContext } from "../hooks/useAuthContext";
import { highlightWord } from '../components/MessageArea.js';


const productId = process.env.REACT_APP_STRIPE_ID;
const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY
);
const baseUrl = process.env.REACT_APP_baseUrl;
const CardForm = ({ onSuccessfulPayment, setPaymentMessage, paymentMessage }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentMessage('');

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!error) {
      onSuccessfulPayment(paymentMethod);
    } else {
      setPaymentMessage('Payment failed. Please check your payment information and try again.');
      console.log(error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="stripe-card-form">
      <CardElement className="stripe-card-element" />
      {paymentMessage && <p style={{color: 'red', fontWeight: '600'}}>{paymentMessage}</p>}
      <button type="submit" disabled={!stripe} className="stripe-submit-btn">
        Submit Payment 
      </button>
    </form>
  );
};

function SubscriptionPlans() {
  // function updateIsPremium function will change localstore isPremium status from false to true
  // This function should only be triggered after the payment is success
  const { dispatch } = useAuthContext();
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('Individual');
  const [price, setPrice] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [showForm, setShowForm] = useState(false);
  const [hideCards, setHideCards] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [signupStatus, setSingupStatus] = useState(false); // add for detect if a user click enroll without signin
  const [code, setCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [finalPirce, setFinalPrice] = useState(0);
  const [showFinalPrice, setShowFinalPrice] = useState(false);
  const [individualPrice, setIndividualPrice] = useState(9.99);
  const [familyPrice, setFamilyPrice] = useState(7.99);
  const [enrollButtonText, setEnrollButtonText] = useState("Enroll");
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountFetched, setDiscountFetched] = useState(false);
  const [promoError, setPromoError] = useState("");
  const [notSelectPlanError, setNotSelectPlanError] = useState("");
  const [validCode, setValidCode] = useState("");
  const [isValidCode, setisValidCode] = useState(false);
  const [premiumError, setPremiumError] = useState("");
  const [hoveredCard, setHoveredCard] = useState(null);
  const [paymentMessage, setPaymentMessage] = useState("");
  const [subscriptionDetail, setSubscriptionDetail] = useState(null);
  const [unsubscribing, setUnsubscribing] = useState(false);

  const userStr = localStorage.getItem("mysUser");
  // const user = JSON.parse(userStr);

  const user = useMemo(() => {
    let user = localStorage.getItem("mysUser");
    user = user
      ? JSON.parse(user)
      : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1", isPremium: false };
    // user = user ? JSON.parse(user) : null
    return user;
  }, []);
  useEffect(() => {
    if (user.isPremium) {
      setPremiumError("You are already a premium user!");
    }
  }, [user]);

  // Add a function to check if the user is premium user when it login and save the result into local
  // return True it the user is a premium user, else return false
  const fetchPremiumStatus = async (id) => {
    const response = await fetch(`${baseUrl}api/users/userpremium/${id}`);
    const data = await response.json();
    const premiumVal = data.userPremiumLevel !== 0;
    return premiumVal;
  };

  const updateIsPremium = async () => {
    const userStr = localStorage.getItem("mysUser");
    if (userStr) {
      const userObj = JSON.parse(userStr);
      // update the isPremium locally to true
      const newIsPremium = await fetchPremiumStatus(userObj.userId);
      // // for testing, user without Premium would updated to premium user
      // alert(!newIsPremium)
      // userObj.isPremium = !newIsPremium
      userObj.isPremium = newIsPremium;
      localStorage.setItem("mysUser", JSON.stringify(userObj));
    }
    // alert('turn to premium status, redirect to local')
    navigate("/", { replace: true });
    window.location.reload();
  };

  const handleShowModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  useEffect(() => {
    // checkSubscriptionStatus();
    const getSubscriptionDetail = async () => {
      try {
        const token = localStorage.getItem("idToken").replace(/^"(.*)"$/, "$1");
        const response = await axios.get(
          `${baseUrl}api/users/get-subscription-detail`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          });
        
        setSubscriptionDetail(response.data.subscription);
        console.log(response.data.subscription);
      } catch (error) {
        console.error("Error fetching subscription detail:", error);
      }
    }
    getSubscriptionDetail();
  }, []);

  const checkSubscriptionStatus = async () => {
    try {
      const token = localStorage.getItem("idToken").replace(/^"(.*)"$/, "$1");
      //const token = localStorage.getItem("idToken");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `${baseUrl}api/users/subscribe`,
        config
      );
      if (response.data.endDate && response.data.endDate !== "n/a") {
        const expirationDate = new Date(response.data.endDate);
        const today = new Date();
        const daysLeft = (expirationDate - today) / (1000 * 60 * 60 * 24);
        if (daysLeft <= 3) {
          handleShowModal(
            `Your subscription will expire in ${Math.ceil(
              daysLeft
            )} day(s). Please consider renewing.`
          );
        }
      }
    } catch (error) {
      console.error("Error checking subscription status:", error);
    }
  };

  //price
  // const getPrice = async (planChoice) => {
  //   const token = localStorage.getItem("idToken").replace(/^"(.*)"$/, "$1");
  //   //const token = localStorage.getItem("idToken");
  //   const config = {
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  //   try {
  //     const response = await axios.get(
  //       `${baseUrl}api/users/get-price/?plan=${planChoice}`,
  //       config
  //     );
  //     return response.data.total_price;
  //   } catch (error) {
  //     console.error("Error fetching price:", error);
  //     return null;
  //   }
  // };

  const handleEnroll = async () => {
    // get user from local resource, if there does not have any user signin.
    // This button will be redirect to cognito signup page
    const userStr = localStorage.getItem("mysUser");
    const user = JSON.parse(userStr);
    if (selectedPlan === null) {
      setSelectedPlan("Individual");
      setNotSelectPlanError('');
    }

    if (userStr === null) {
      setSingupStatus(true); // Assuming this should indicate a missing sign-in status
      setTimeout(() => {
        navigate("/signin");
      }, 4000);
    } else {
      if (user.isPremium) {
        setPremiumError("You are already a premium user!");
        return;
      }
      setIsEnrolling(true);
      // const price = await getPrice(selectedPlan);
      // const price =
      //   (await selectedPlan) === "Individual" ? individualPrice : familyPrice;
      // console.log(`Plan chosen: Individual, Price: ${price}`);
      if (price !== null) {
        // discountApplied ? setPrice(price) : setPrice(price);
        // setPrice(price);
        setShowPaymentForm(true);
        console.log("Should show payment form now");
      }
      setIsEnrolling(false); // Once done, set it back to false
    }
  };
  const handleMouseEnter = (cardType) => {
    setHoveredCard(cardType);
  };
  
  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  useEffect(() => {
    // const price = selectedPlan === "Individual" ? individualPrice : familyPrice;
    const price = individualPrice;
    setPrice(price);
  }, [selectedPlan, familyPrice, individualPrice]);

  const handleEnterCode = async () => {
    const promoCode = code;
    // const userStr = localStorage.getItem("mysUser");
    // const user = JSON.parse(userStr);

    if (user.isPremium) {
      setPremiumError("You are already a premium user!");
      return;
    }
    if (userStr === null) {
      setSingupStatus(true); // Assuming this should indicate a missing sign-in status
      setTimeout(() => {
        navigate("/signin");
      }, 4000);
      return;
    }



    if (discountApplied) {
      setPromoError("Promotion already applied!");
      return;
    }
    const userInfo = user.userId;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.post(
        `${baseUrl}api/influencer/post/${userInfo}/`,
        { code: promoCode, order_amount: 9.99 },
        config
      );

      if (response.data.valid) {
        setisValidCode(true);
        setDiscount(response.data.percent_off);
        setFinalPrice(Math.trunc((individualPrice * response.data.percent_off / 100) * 100) / 100);
        setShowFinalPrice(true);
        setIndividualPrice(Math.trunc((individualPrice * response.data.percent_off / 100) * 100) / 100);
        setFamilyPrice(Math.trunc((familyPrice * response.data.percent_off / 100) * 100) / 100);
        setEnrollButtonText("Enroll with Promo Code");
        setDiscountApplied(true);
        setDiscountFetched(true);
        setPromoError("");
        setValidCode(response.data.couponId);
      } else {
        setisValidCode(false);
        setPromoError('Invalid promo code');
      }
    } catch (error) {
      if (error.response.status === 400) {
        setPromoError(error.response.data.error);
      } else {
        console.error("An error occurred:", error);
      }
    }
  };

  const handleCloseFianlPrice = () => {
    setShowFinalPrice(false);
  };

  console.log(user)
  const onSuccessfulPayment = async (paymentMethodId) => {
    setIsSubmitting(true);
    try {
      const token = localStorage.getItem("idToken").replace(/^"(.*)"$/, "$1");
      console.log("what is the price", price);

      const response = await axios.post(
        `${baseUrl}api/users/payment/`,
        {
          paymentMethodId: paymentMethodId,
          product_id: productId,                                               
          couponId: (isValidCode ? validCode : null),                                                  
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data && response.status === 200) {
        const user = JSON.parse(localStorage.getItem("mysUser"));
        if (user) {
          user.isPremium = true;
          localStorage.setItem("mysUser", JSON.stringify(user));
          dispatch({ type: "UPDATE_USER", payload: user });
        }
        setPaymentSuccess(true);
        setTimeout(() => {
          console.log("Payment successful, navigating back to home");
          navigate("/");
      }, 3000);
      }
    } catch (error) {
      setPaymentMessage('Payment failed. Please check your payment information and try again.');
      console.error(
        "Payment error:",
        error.response ? error.response.data : error.message
      );
    }
    setIsSubmitting(false);
  };
  const cancelSubscription = async () => {
    console.log("cancel subscription");
    setUnsubscribing(true);
    try {
      const token = localStorage.getItem("idToken").replace(/^"(.*)"$/, "$1");
      const response = await axios.get(
        `${baseUrl}api/users/cancel-subscription/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      setSubscriptionDetail({...subscriptionDetail, 'cancel_at_period_end': true});
    } catch (error) {
      console.error(
        "Payment error:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setUnsubscribing(false);
    }
  }

  const promoText = "Please subscribe with promo code GROWYOUNG2025 for 50% off on premium plan for 3 months. Expires on 4/30/2025!"
  return (
    <div
      className="subscription"
      style={{ backgroundColor: "#FEFAFA", height: "100vh" }}
    >
      <div className="sub_top">
        <p className="sub_top_script">Subscription Plans</p>
      </div>
      {!user.isPremium?
        <>
        <div className="sub_second">
          <div className="sub_up">
          <div className="sub_up_script">
            {/* Choose the plan that's right for you <br /> */}
            {!user.isPremium ? <p>
              {highlightWord(promoText, 'GROWYOUNG2025')}
            </p> : ""}
          </div>
        </div>
      </div>
        </>:""
        }
      
      {/* <div style={{ display: "flex", flexDirection: "column", alignItems: "center"}}>
      {
      premiumError && (
            <div className="prem-user">
              <span className="error-message-icon">⚠️</span> Optional warning icon
              <p style={{ margin: 0 }}>{premiumError}</p>
            </div>)
      }
      </div> */}
      <div className="content-container">
        <div className={`cards-container`}>
          {/* card */}
          <Card
            className="sub_card"
            style={{
              width: "80rem",
              // height: "200px",
              boxShadow: user.isPremium ? "none" : "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
              // transform: selectedPlan === "Individual" || hoveredCard === "Individual" ? "translateY(-5px)" : "none",
              // outline: selectedPlan === "Individual" || hoveredCard === "Individual" ? "3px solid #7f7e7e" : "none"
            }}
            onMouseEnter={() => { 
              if (!user.isPremium)
                handleMouseEnter("Individual")}
              }
            onMouseLeave={handleMouseLeave}
          >
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              onClick={() => {
                if (!user.isPremium) {
                  setSelectedPlan("Individual");
                  setNotSelectPlanError("");
                }

                
              }}
            >
              <div>
                {!user.isPremium?
                <>
                  <Card.Title>Individual</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    ${individualPrice}/month
                  </Card.Subtitle>
                  <Card.Text>
                    &#x27A4; Personalized Plan Creation, Health Activity Log,
                    Health Metrics and Tests, Progress Reports, Dynamic
                    Recommendations, Educational Resource Center, Data Privacy
                  </Card.Text>
                  <hr />
                </>
                :
                <>
                <Card.Title>Family Plan</Card.Title>
                </>}

                <Card.Text>
                  {!user.isPremium?<>
                    &#x27A4; Family: ${individualPrice} for the first family member, and
                  ${familyPrice} for each additional family member/month. 
                  To enroll family members, create an individual plan account first,
                  then return to the available plans.
                  
                  </>:<>
                  &#x27A4; ${familyPrice} for each additional family member/month.
                  {premiumError && (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", margin: "4%"}}>
                      <button onClick={() => navigate("/my-account")}>Enroll</button>
                    </div>
                  )}
                  </>}
                </Card.Text>
              </div>
            </Card.Body>
          </Card>

          {/* <Card
            className="sub_card"
            style={{
              width: "60rem",
              height: "200px",
              boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
              // transform: selectedPlan === "Family" || hoveredCard === "Family" ? "translateY(-5px)" : "none",
              // outline: selectedPlan === "Family" || hoveredCard === "Family" ? "3px solid #7f7e7e" : "none", 
            }}
            onMouseEnter={() => handleMouseEnter("Family")}
            onMouseLeave={handleMouseLeave}
          >
            <Card.Body
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
              onClick={() => {
                if (user.isPremium) {
                  navigate('/familyplan');
                }
                else{
                  // setSelectedPlan("Family");
                  // setNotSelectPlanError("");
                }
              }}
            >
              <div>
                <Card.Title>Family</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                  ${individualPrice} plus ${familyPrice} for each additional
                  family member/month
                </Card.Subtitle>
                <Card.Text>
                  &#x27A4; Along with all the features of the single's plan,
                  Family Member Addition, Flexible Membership Management,
                  Individual Access and Privacy
                </Card.Text>
              </div>
            </Card.Body>
          </Card> */}

          {user.isPremium ?
            ( subscriptionDetail ?
            <Card
            className="sub_card"
            style={{
              width: "20rem",
              height: "170px",
              boxShadow: user.isPremium ? "none" : "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",

            }}
          >
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Card.Title>Subscription</Card.Title>
                  {/* <Card.Subtitle className="mb-2 text-muted">
                    Enter your promo code
                  </Card.Subtitle> */}
                  <Card.Text>
                    {subscriptionDetail['cancel_at_period_end'] ?
                      `Your subscription has been cancelled. Access to premium features will expire on ${subscriptionDetail['billing_date']}.`
                      : `The next billing date is on ${subscriptionDetail['billing_date']}.`
                    }
                  </Card.Text>
                </div>
                {unsubscribing ? <div className="loader"></div> :
                (!subscriptionDetail['cancel_at_period_end'] &&
                <a href="#"
                  style={{ margin: "5%", color:"black"}}
                  onClick={(e) => {
                    e.preventDefault(); // Prevents navigation
                    cancelSubscription();
                  }}>
                  Unsubscribe
                </a>)}
              </Card.Body>
            </Card> : null
            )
            :
            <Card
              className="sub_card"
              style={{
                width: "20rem",
                height: "170px",
                boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
                boxShadow: user.isPremium ? "none" : "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",

              }}
            >
              <Card.Body
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Card.Title>Promo Code</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    Enter your promo code
                  </Card.Subtitle>
                  <Card.Text>
                    <Form>
                      <Form.Group controlId="formPromoCode">
                        <Form.Control
                          type="text"
                          placeholder="Enter promo code"
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </Form.Group>
                    </Form>
                  </Card.Text>
                </div>
                <Button
                  onClick={handleEnterCode}
                  className="submit-code-button"
                  disabled={user.isPremium} 
                  style={{
                    backgroundColor: user.isPremium ? "grey" : "", 
                    width: "120px",
                    height: "40px", 
                    padding: "10px 10px",
                    borderRadius: "4px", 
                    cursor: user.isPremium ? "not-allowed" : "pointer", 
                  }}
                >
                  Submit
                </Button>
              </Card.Body>
            </Card>
          }

          <Modal
            show={showFinalPrice}
            onHide={handleCloseFianlPrice}
            backdropClassName="custom-modal-backdrop"
            dialogClassName="custom-modal-content"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Promo Code Applied</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                <b>Discount: {discount}%</b>
              </p>
              <p>
                <b>Final Price: ${finalPirce}</b>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseFianlPrice}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <div className="enroll-button-container">
            {!showPaymentForm && (
              <>
                {!isEnrolling ? (
                  <Button
                    onClick={handleEnroll}
                    className="enroll-button"
                    disabled={user.isPremium} // Disable if user is premium
                    hidden={user.isPremium}
                    style={{
                      backgroundColor: user.isPremium ? "grey" : "", // Grey out when disabled
                      cursor: user.isPremium ? "not-allowed" : "pointer", // Change cursor to indicate disabled state
                      marginBottom: "30px",
                    }}
                  >
                    {enrollButtonText}
                  </Button>
                ) : (
                  <div className="loader"></div>
                )}
              </>
            )}
          </div>
          {signupStatus && (
            <p>
              oops! Seems you haven't logged in. Redirecting to Login page...
            </p>
          )}
        </div>
        {/* {premiumError && (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "5%"}}>
                      <button onClick={() => navigate("/my-account")}>Click here to create a family plan</button>
                    </div>
                  )} */}
        {promoError && (
          <div className="error-message">
            <span className="error-message-icon">⚠️</span> {/* Optional warning icon */}
            <p style={{ margin: 0 }}>{promoError}</p>
          </div>
        )}


        {notSelectPlanError && (
          <div>
            <p style={{ color: "red" }}>{notSelectPlanError}</p>
          </div>
        )}

        {paymentSuccess ? (
          <div className="payment-success-message">
            <h2>Payment successful!</h2>
            <p>Thank you for your subscription.</p>
            <button onClick={updateIsPremium}>Go back to home page</button>
          </div>
        ) : (
          showPaymentForm && (
            <div className="payment-form-container">
              {/* stripe form */}
              <h2>
                Payment Information for {selectedPlan} - ${price}
              </h2>
              <Elements stripe={stripePromise}>
                <CardForm
                  planChoice={selectedPlan}
                  onSuccessfulPayment={onSuccessfulPayment}
                  setPaymentMessage={setPaymentMessage}
                  paymentMessage={paymentMessage}
                />
              </Elements>
            </div>
          )
        )}
        {isSubmitting && (
          <div className="loading-overlay">
            <div className="loader"></div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SubscriptionPlans;