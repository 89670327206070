import { useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { confirmSignup, resendConfirmationCode, createDefaultMessage } from "./auth"
import axios from "axios";
export default function ConfirmSignup() {
  const username = sessionStorage.getItem('recentlySignedUpUsername');
  const [code, setCode] = useState("")
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)
  const baseUrl = process.env.REACT_APP_baseUrl;
  const is_staffUrl = baseUrl + "api/users/is_staff/";
  const create_signup_promo_codeUrl = baseUrl + "api/users/signup_promo_code/";
  const navigate = useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search)
  const invite_id = queryParams.get('invite_id')
  const orgtype = queryParams.get('orgtype')

  const handleResendCode = async () => {
    setError('');
    try {
      await resendConfirmationCode(username);
      setError('A new confirmation code has been sent.');
    } catch (err) {
      setError('Failed to resend the confirmation code. Please try again later.');
      console.log(err.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault()
    setError("")
    if (!code) {
      setError("Enter a code");
        return;
    }


    try {
      await confirmSignup(username, code)
      await axios.put(is_staffUrl,{"email":username})
      // await axios.post(create_signup_promo_codeUrl, {"email":username}) // dont create new promo code
      setSuccess(true)
    } catch (err) {
      if(err.message === 'User cannot be confirmed. Current status is CONFIRMED'){
        setError("The email is already confirmed");
      }
      else{
        setError(err.message)
      }
    }
  }

  if (success) {
    sessionStorage.removeItem('recentlySignedUpUsername');

    setTimeout(() => {
      if (invite_id) {
        navigate("/signin" + "?invite_id=" + invite_id + "&orgtype=" + orgtype);
      } else {
        navigate("/signin");
      }
    }, 2000); // Delay of 2 seconds

    // Add Default message to messageArea in Database
    try{
      createDefaultMessage(username)
    } catch(err){
      // setError(err.message)
    }
  }

  return success ? (
    <div className="cognito_login_box">
        <br />
        <br />
        <br />
        <br />
        <h2 className="cognito_login_title">Confirmation successful!</h2>
        <p className="cognito_login_text">Redirecting to sign in page...</p>
      </div>
  ) : (
    <div className="cognito_login_box">
        <br />
        <br />
        <br />
        <br />
      <h4 className="cognito_login_title">Confirm Sign Up</h4>
      <p className="cognito_login_text">We have sent you a code to {username}. Enter it below to confirm your account.</p>
      <form onSubmit={handleSubmit}>
        <label className="cognito_login_label">Verification code:</label>
          <input
          className="cognito_lable_box"
            type="text"
            placeholder="Confirmation code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
        <button className= "cognito_login_submit"type="submit">Confirm</button>
        <button className="cognito_login_submit" onClick={handleResendCode}>
          Resend Code
        </button>
      </form>
      {error && <p>{error}</p>}
      <br />
        <br />
        <br />
        <br />
    </div>
  )
}