import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import AboutMe from "./Sections/AboutMe";
import Diet from "./Sections/Diet";
import ExerciseSleepStress from "./Sections/ExerciseSleepStress";
import Supplements from "./Sections/Supplements";
import CalculatorResponse from "../../components/CalculatorResponse";
import { useAuthContext } from "../../hooks/useAuthContext";
import {
  fetchProbData,
  fetchUserInfo,
  updateProbData,
  getScoreFromLocal,
  fetchDBData,
  fetchUserData,
} from "./actions";
import { getLifestyleResponseData } from "../FullProfile/actions";
import {DATE_OF_BIRTH, GENDER, CIGARETTES_USE, AGE,
  FORMER_CIGARETTES_YEARS, CURRENT_CIGARETTES_YEARS, ALCOHOL_DRINKS_PER_WEEK,
  calorieRestrictionName, transFatIntakeName, refinedGrainName, wholeGrainName,
  legumesName, processedMeatName, poultryName, redMeatName, vegetablesName,
  fruitName, waterIntakeName, sugarySugar, artificiallySugar,
  cardioName, strengthTrainingName, actualSleepHoursName, sleepQualityName,
  activitiesSaunaBathingName, stressQualityName, calciumName, fishOilOmega3Name,
  matchaGreenTeaName, smoking_frequencyName, fruitAndVegetableName} from "./archive/CalculatorName";

import "./Calculator.css";

const CALCULATOR_TIMES = 3;

function Calculator() {
  const { user, dispatch } = useAuthContext();

  // Add useEffect for initial data loading
  useEffect(() => {
    const initializeCalculator = async () => {
      
    }
  }, [user]); // Depend on user

  // Keep the LoadingSpinner component
  const LoadingSpinner = () => (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      zIndex: 1000
    }}>
      <div style={{
        width: '50px',
        height: '50px',
        border: '5px solid #f3f3f3',
        borderTop: '5px solid #007bff',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite'
      }}/>
    </div>
  );

  // Return loading spinner if still loadin

  const aboutMeAlcoholModelName = "alcohol";
  const aboutMeAgeModelName = "age";
  const aboutMeGenderModelName = "gender";
  const aboutMeSmokingStatusModelName = "smoking_status";
  const aboutMeSmokingFrequencyModelName = "smoking_frequency";
  const aboutMeSmokingDurationModelName = "smoking_duration";
  const dietCalorieRestrictionModelName = "calorie_restriction";
  const dietFatTransModelName = "fat_trans";
  const dietGrainRefinedModelName = "grain_refined";
  const dietGrainUnrefinedModelName = "grain_unrefined";
  const dietLegumesModelName = "legumes";
  const dietMeatProcessedModelName = "meat_processed";
  const dietMeatUnprocessedModelName = "meat_unprocessed";
  const dietMeatPoultryModelName = "meat_poultry";
  const dietFruitsAndVeggiesModelName = "fruits_and_veggies";
  const dietWaterModelName = "water";
  const dietRefinedSugarModelName = "refined_sugar";
  const dietArtificialSweetenerModelName = "artificial_sweetener";
  const exerciseCardioModelName = "cardio";
  const exerciseStrengthTrainingModelName = "strength_training";
  const mySleepSleepDurationModelName = "sleep_duration";
  const mySleepSleepQualityModelName = "sleep_quality_score_lifestyle";
  const exerciseSaunaFrequencyModelName = "sauna_frequency";
  const myStressStressQualityModelName = "stress_level_score";
  const nodeStressQualityModelName = "stress_level_score";
  const supplementsCalciumModelName = "calcium";
  const supplementsFishOilOmega3ModelName = "fish_oil_omega_3";
  const supplementsGreenTeaModelName = "green_tea";
  const smoking_frequencyModelName = "smokingfrequencyLable";
  const genderOptions = ["Male", "Female"];
  const cigarettesOptions = [
    "Never used",
    "Former user",
    "Current user",
    "I decline to answer",
    "I don't know",
  ];
  const calorieIntakeOptions = ["No", "Yes"];
  const sweetTimeOptions = ["Day"];
  const sleepQualityOptions = [
    "Poor - I frequently wake up during the night and do not feel rested in the morning.",
    "Fair - I occasionally wake up during the night but generally feel somewhat rested in the morning.",
    "Excellent - I sleep through the night without interruptions and wake up feeling refreshed.",
  ];
  const stressQualityOptions = [
    "Low - I rarely feel stressed and can manage daily challenges with ease.",
    "Moderate - I experience stress occasionally but can generally cope with it.",
    "High - I frequently feel overwhelmed by stress and find it difficult to cope.",
  ];

  const calciumOptions = ["mg/day", "g/day"];
  const fishOilOmega3Options = ["g/day", "mg/day"];
  const matchaGreenTeaOptions = ["ml/day", "oz/day"];
  
  const [loaded, setLoaded] = useState(false);

  const [aboutMeModel, setAboutMeModel] = useState({
    [DATE_OF_BIRTH]: null,
    [AGE]: null,
    [ALCOHOL_DRINKS_PER_WEEK]: null,
    [GENDER]: null,
    [CIGARETTES_USE]: null,
    [FORMER_CIGARETTES_YEARS]: null,
    [CURRENT_CIGARETTES_YEARS]: null,
    [smoking_frequencyName]: null,
    smoking_status: null,
    // [DATE_OF_BIRTH]: "2000-01-01",
    // [aboutMeAgeModelName]: 0,
    // [aboutMeAlcoholModelName]: 0,
    // [aboutMeGenderModelName]: 0,
    // [aboutMeSmokingStatusModelName]: 0,
    // [aboutMeSmokingFrequencyModelName]: 0,
    // smoking_status: 0,
    // smoking_frequency: 0,
  });
  const [dietModel, setDietModel] = useState({
    [dietArtificialSweetenerModelName]: 0,
    [dietCalorieRestrictionModelName]: 0,
    [dietFatTransModelName]: 0,
    [dietFruitsAndVeggiesModelName]: 0,
    [dietGrainRefinedModelName]: 0,
    [dietGrainUnrefinedModelName]: 0,
    [dietLegumesModelName]: 0,
    [dietMeatProcessedModelName]: 0,
    [dietMeatUnprocessedModelName]: 0,
    [dietMeatPoultryModelName]: 0,
    [dietRefinedSugarModelName]: 0,
    [dietWaterModelName]: 0,
  });
  const [exerciseSleepStressModel, setExerciseSleepStressModel] = useState({
    [exerciseSaunaFrequencyModelName]: 0,
    [exerciseStrengthTrainingModelName]: 0,
    [nodeStressQualityModelName]: 0,
    [exerciseCardioModelName]: 0,
    [mySleepSleepDurationModelName]: 0,
    [mySleepSleepQualityModelName]: 0,
  });
  const [supplementsModel, setSupplementsModel] = useState({
    [supplementsCalciumModelName]: 0,
    [supplementsFishOilOmega3ModelName]: 0,
    [supplementsGreenTeaModelName]: 0,
  });
  const [aboutMe, setAboutMe] = useState({
    [DATE_OF_BIRTH]: null,
    [AGE]: null,
    [ALCOHOL_DRINKS_PER_WEEK]: null,
    [GENDER]: null,
    [CIGARETTES_USE]: null,
    [FORMER_CIGARETTES_YEARS]: null,
    [CURRENT_CIGARETTES_YEARS]: null,
    [smoking_frequencyName]: null,
    smoking_status: null,
  });
  const [diet, setDiet] = useState({
    [artificiallySugar]: null,
    [calorieRestrictionName]: null,
    [transFatIntakeName]: null,
    [fruitAndVegetableName]: null,
    // [fruitName]: null,
    // [vegetablesName]: null,
    [wholeGrainName]: null,
    [refinedGrainName]: null,
    [legumesName]: null,
    [processedMeatName]: null,
    [poultryName]: null,
    [redMeatName]: null,
    [sugarySugar]: null,
    [waterIntakeName]: null,
  });
  const [exerciseSleepStress, setExerciseSleepStress] = useState({
    [exerciseSaunaFrequencyModelName]: null,
    [strengthTrainingName]: null,
    [stressQualityName]: null,
    [cardioName]: null,
    [sleepQualityName]: null,
    [actualSleepHoursName]: null,
  });
  const [supplements, setSupplements] = useState({
    [calciumName]: null,
    [fishOilOmega3Name]: null,
    [matchaGreenTeaName]: null,
  });
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [estiScore, setEstiScore] = useState(0);
  const [calculatorTimes, setCalculatorTimes] = useState(0);
  const [preferredUnitSystem, setPreferredUnitSystem] = useState("");
  const [greenTeaUnit, setGreenTeaUnit] = useState(""); // Add state for GreenTea unit
  const [waterUnit, setWaterUnit] = useState("");
  const [limit, setLimit] = useState({
    [artificiallySugar]: 36,
    [transFatIntakeName]: 50,
    [fruitName]: 10,
    [vegetablesName]: 10,
    [wholeGrainName]: 15 * 7, // per week
    [refinedGrainName]: 15 * 7, // per week
    [legumesName]: 4 * 7, // per week
    [processedMeatName]: 6 * 7, // per week
    [poultryName]: 6 * 7, // per week
    [redMeatName]: 6 * 7, // per week
    [sugarySugar]: 36,
    [waterIntakeName]: 8000 ,
    [calciumName]: 2000,
    [fishOilOmega3Name]: 5000,
    [matchaGreenTeaName]: Infinity,
    [actualSleepHoursName]: 14,
  });
  const [showWarning, setShowWarning] = useState(false);
  const onClose = () => {
    setShowWarning(false);
  };
  const handleUnitChange = (name, unit) => {
    if (name === "matchaOrGreenTea") {
      setGreenTeaUnit(unit);
      if (unit === "ml/day") {
        const newLimit = 2300;
        setLimit({ ...limit, [name]: newLimit });
        // const newVal = (supplements[name] / 0.0295735).toFixed(2);
        // setSupplements({ ...supplements, [name]: newVal });
      } else {
        const newLimit = Math.round(limit[name] / 29.5735);
        setLimit({ ...limit, [name]: newLimit });
        // const newVal = Math.round(supplements[name] * 0.0295735).toFixed(2);
        // setSupplements({ ...supplements, [name]: newVal });
      }
    }
    if (name === "water") {
      setWaterUnit(unit);
      if (unit === "ml/day") {
        const newLimit = 8000;
        setLimit({ ...limit, [name]: newLimit });
        // const newVal = Math.round(diet[name] / 0.0295735);
        // setDiet({ ...diet, [name]: newVal });
      } else {
        const newLimit = Math.round(limit[name] / 29.5735);
        setLimit({ ...limit, [name]: newLimit });
        // const newVal = Math.round(diet[name] * 0.0295735);
        // setDiet({ ...diet, [name]: newVal });
      }
    }
  };

  const handleUnitDefaultChange = (name, unit) => {
    if (name === "matchaOrGreenTea") {
      setGreenTeaUnit(unit);
    }
    if (name === "water") {
      setWaterUnit(unit);
    }
    //console.log(`Default unit for ${name} set to ${unit}`);
  };
  const [errors, setErrors] = useState({});
  const errorMessage = "Error: Please fill out this field or enter 0!";

  const retrieveData = (state, setState, data) => {
    let values = {...state};
    Object.keys(state).forEach((key) => {
      if (data.hasOwnProperty(key)) {
        values[key] = data[key];
      }
    })
    setState(values);
    setLoaded(true);
  }

  useEffect(() => {
    const fetchUser = async (userId) => {
      try {
        console.log("fetching user info")
        const data = await fetchUserInfo(userId);
        console.log("user info fetched")
        
        if (data) {
          setScore(data.lifespan_score);
          setEstiScore(data.estimated_lifespan_score);
          console.log("what is the calculator times: ", data.number_submitted);
          setCalculatorTimes(data.number_submitted);
          console.log('data: ', data)
          retrieveData(aboutMe, setAboutMe, data);
          retrieveData(diet, setDiet, data);
          retrieveData(exerciseSleepStress, setExerciseSleepStress, data);
          retrieveData(supplements, setSupplements, data);
          console.log('diet: ', diet)
          if (data.number_submitted === CALCULATOR_TIMES) {
            setSubmitted(true);
          }
        }
        setLoaded(true); // Move this outside the if block
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoaded(true); // Set loaded to true even if there's an error
      }

      try {
        const data = await getScoreFromLocal(userId);
        if (data) {
          setEstiScore(data.optimal_lifespan);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const userId = JSON.parse(localStorage.getItem("mysUser"))?.userId;
    if (userId) {
      fetchUser(userId);
    } else {
      setLoaded(true); // Set loaded to true if there's no userId
    }
  }, []);

  // useEffect(() => {
  //   if (estiScore !== undefined || score !== undefined) {
  //     localStorage.setItem("estiScore", estiScore);
  //     localStorage.setItem("score", score);
  //   }
  // }, [estiScore, score]);

  const currentUser = JSON.parse(localStorage.getItem('mysUser'));

  useEffect(() => {
    const userId = JSON.parse(localStorage.getItem("mysUser")).userId;
    const fetchUser = async (userId) => {
      try {
        const userData = await fetchUserData(userId);
        setPreferredUnitSystem(userData.preferred_unit_system);
        if (userData.preferred_unit_system === "imperial") {
          setGreenTeaUnit("oz/day");
          setWaterUnit("oz/day");
          const newWaterLimit = Math.round(limit[waterIntakeName] / 29.5735);
          const newGreenTeaLimit = Math.round(
            limit[matchaGreenTeaName] / 29.5735
          );
          // console.log("water limit: " + newWaterLimit);
          setLimit({
            ...limit,
            [waterIntakeName]: newWaterLimit,
            [matchaGreenTeaName]: newGreenTeaLimit,
          });
          // console.log("green tea limit: " + newGreenTeaLimit);
        } else {
          setGreenTeaUnit("ml/day");
          setWaterUnit("ml/day");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUser(userId);
  }, []);

  const submitFormData = async (FormData, formDbData) => {
    try {
      if (calculatorTimes === 0) {
        const res = await fetchProbData(FormData);
      } else {
        const res = await updateProbData(FormData);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
    await fetchDBData(formDbData);
  };

  const updateFormData = async (FormData) => {
    try {
      const res = await updateProbData(FormData);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const errorHandling = () => {
    const newErrors = {};
    let allFilledOut = true;
    const fieldToSkip = [];

    if (aboutMe[CIGARETTES_USE] == "0") {
      // console.log('never used');
      fieldToSkip.push(
        "smoking_duration",
        smoking_frequencyName
      );
    } else if (aboutMe[CIGARETTES_USE] == "1") {
      // console.log('former user');
      fieldToSkip.push(smoking_frequencyName);
    } else if (aboutMe[CIGARETTES_USE] == "2") {
      // console.log('current user');
    } else {
      fieldToSkip.push(
        "smoking_duration",
        smoking_frequencyName
      );
    }

    Object.entries(aboutMe).forEach(([key, value]) => {
      if (
        (value !== 0 && !value && !fieldToSkip.includes(key)) ||
        (key === "dob" && new Date(aboutMe[DATE_OF_BIRTH]) > new Date())
      ) {
        if (key === "dob") {
          newErrors[key] =
            "Error: Date of Birth must be less than current date!";
        } else {
          newErrors[key] = errorMessage;
        }
        // console.log(key + " is not filled out");
        allFilledOut = false;
      } else {
        newErrors[key] = "";
      }
    });

    Object.entries(diet).forEach(([key, value]) => {
      if (value !== 0 && !value) {
        newErrors[key] = errorMessage;
        // console.log(key + ' is not filled out')
        allFilledOut = false;
      } else {
        newErrors[key] = "";
      }
    });

    Object.entries(exerciseSleepStress).forEach(([key, value]) => {
      if (value !== 0 && !value) {
        newErrors[key] = errorMessage;
        // console.log(key + ' is not filled out');
        allFilledOut = false;
      } else {
        newErrors[key] = "";
      }
    });

    Object.entries(supplements).forEach(([key, value]) => {
      if (value !== 0 && !value) {
        newErrors[key] = errorMessage;
        // console.log(key + ' is not filled out')
        allFilledOut = false;
      } else {
        newErrors[key] = "";
      }
    });

    setErrors(newErrors);
    // console.log(errors);
    return allFilledOut;
  };
  
  // Get today's date and calculate yesterday's date in the format 'YYYY-MM-DD'
  const today = new Date();
  today.setDate(today.getDate() - 1); // Exclude today
  const maxDate = today.toISOString().split("T")[0]; // Format as YYYY-MM-DD

  // let allFilledOut = null;
  const handleSubmit = (e) => {
    e.preventDefault();
    const allFilledOut = errorHandling();

    if (!allFilledOut) {
      // console.log('Some fields are not filled out!');
      window.scrollTo({ top: 0, behavior: "smooth" });
      // alert('Please fill out all fields!');
      return;
    } else {
      // console.log('All fields are filled out!')
    }

    setScore();
    setEstiScore();
    if (calculatorTimes >= CALCULATOR_TIMES - 1) {
      setActiveTab(1);
    }

    const userInfo = JSON.parse(localStorage.getItem("mysUser"));
    const formDbData = {
      ...userInfo,
      ...aboutMe,
      ...diet,
      ...exerciseSleepStress,
      ...supplements,
      waterUnit: waterUnit,
      greenTeaUnit: greenTeaUnit,
      // smoking_frequency: Number(aboutMe["smoking_frequency"]),
      // smoking_status: !aboutMe[CURRENT_CIGARETTES_YEARS]
      //   ? Number(aboutMe[FORMER_CIGARETTES_YEARS])
      //   : Number(aboutMe[CURRENT_CIGARETTES_YEARS]),
    };
    console.log(formDbData);
    let formPredictData = {
      ...userInfo,
      ...aboutMeModel,
      ...dietModel,
      ...exerciseSleepStressModel,
      ...supplementsModel,
      waterUnit: waterUnit,
      greenTeaUnit: greenTeaUnit,
      number_submitted: calculatorTimes + 1,
      lifespan_score: score,
      // estimated_lifespan: estiScore,
      // smoking_status: aboutMe[CIGARETTES_USE],
      // smoking_frequency: Number(aboutMe["smoking_frequency"]),
    };
    formPredictData["user_id"] = userInfo["userId"];
    const genderVal = formPredictData[GENDER] == '0' ? "Male" : "Female";
    formPredictData[GENDER] = genderVal;
    console.log(formPredictData);
    submitFormData(formPredictData, formDbData)
      .then(() => {
        getScoreFromLocal(userInfo.userId)
          .then((response) => {
            if (calculatorTimes === CALCULATOR_TIMES) {
              setActiveTab(1);
            }
            // console.log(response);
            const newScore = response.all_cause_mortality_predicted_lifespan;
            // console.log('new score: ' + newScore);
            // console.log('new opt score: ' + response.optimal_lifespan);
            setScore(newScore);
            setEstiScore(response.optimal_lifespan);
            console.log(waterUnit);
            console.log(greenTeaUnit);
            const formPredictData = {
              ...userInfo,
              ...aboutMeModel,
              ...dietModel,
              ...exerciseSleepStressModel,
              ...supplementsModel,
              lifespan_score: newScore,
              estimated_lifespan_score: response.optimal_lifespan,
              user_id: userInfo["userId"],
              // smoking_status: Number(aboutMe["smoking_duration"]),
              // smoking_frequency: Number(aboutMe["smoking_frequency"]),
              waterUnit: waterUnit,
              greenTeaUnit: greenTeaUnit,
            };
            console.log(formPredictData);
            updateFormData(formPredictData);
          })
          .catch((error) => {
            console.error("Error fetching data", error);
          });
      })
      .catch((error) => {
        console.error("Error submitting form data", error);
        setShowWarning(true);
        return;
      });

    setCalculatorTimes(calculatorTimes + 1);
    setSubmitted(true);
    // try {
    //     axios.post("/modelapi/optimization_model/",
    //     {"user_id": JSON.parse(localStorage.getItem('mysUser'))['userId']},
    //     {
    //     headers: {
    //         "Content-Type": "application/json",
    //     },
    //     }
    // )
    //     .then(response => {
    //         console.log(response.data)
    //         const scores = Number(response.data["all_cause_mortality_predicted_lifespan"]).toFixed(2)
    //         setEstiScore(scores)
    //     })
    // }
    // catch (error) {
    //     console.log(error)
    // }
  };

  useEffect(() => {
    const newValue = {
      ...aboutMeModel,
      [DATE_OF_BIRTH]: aboutMe[DATE_OF_BIRTH],
      [aboutMeAgeModelName]: aboutMe[AGE],
      [aboutMeAlcoholModelName]: aboutMe[ALCOHOL_DRINKS_PER_WEEK],
      [aboutMeGenderModelName]: aboutMe[GENDER],
      [aboutMeSmokingDurationModelName]: aboutMe['smoking_duration'],
      [aboutMeSmokingFrequencyModelName]: aboutMe[smoking_frequencyName],
      [aboutMeSmokingStatusModelName]: aboutMe[CIGARETTES_USE],
    };
    setAboutMeModel(newValue);
  }, [aboutMe]);
  useEffect(() => {
    setExerciseSleepStressModel({
      ...exerciseSleepStressModel,
      [exerciseSaunaFrequencyModelName]:
        exerciseSleepStress[exerciseSaunaFrequencyModelName],
      [exerciseStrengthTrainingModelName]:
        exerciseSleepStress[strengthTrainingName],
      [nodeStressQualityModelName]: exerciseSleepStress[stressQualityName],
      [exerciseCardioModelName]: exerciseSleepStress[cardioName],
      [mySleepSleepDurationModelName]:
        exerciseSleepStress[actualSleepHoursName],
      [mySleepSleepQualityModelName]: exerciseSleepStress[sleepQualityName],
    });
  }, [exerciseSleepStress]);
  useEffect(() => {
    setSupplementsModel({
      ...supplementsModel,
      [supplementsCalciumModelName]: supplements[calciumName],
      [supplementsFishOilOmega3ModelName]: supplements[fishOilOmega3Name],
      [supplementsGreenTeaModelName]: supplements[matchaGreenTeaName],
    });
  }, [supplements]);
  useEffect(() => {
    setDietModel({
      ...dietModel,
      [dietArtificialSweetenerModelName]: diet[artificiallySugar],
      [dietCalorieRestrictionModelName]: diet[calorieRestrictionName],
      [dietFatTransModelName]: diet[transFatIntakeName],
      [dietFruitsAndVeggiesModelName]: diet[fruitAndVegetableName],
      [dietGrainRefinedModelName]: diet[refinedGrainName],
      [dietGrainUnrefinedModelName]: diet[wholeGrainName],
      [dietLegumesModelName]: diet[legumesName],
      [dietMeatProcessedModelName]: diet[processedMeatName],
      [dietMeatUnprocessedModelName]: diet[redMeatName],
      [dietMeatPoultryModelName]: diet[poultryName],
      [dietRefinedSugarModelName]: diet[sugarySugar],
      [dietWaterModelName]: diet[waterIntakeName],
    });
  }, [diet]);

  const [userAgeFromDB, setUserAgeFromDB] = useState(null);
  const userId = JSON.parse(localStorage.getItem("mysUser")).userId;

  useEffect(() => {
    const fetchUserInfo = async (userId) => {
      try {
        const res = await getLifestyleResponseData(userId);
        setUserAgeFromDB(res.data["age"]);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchUserInfo(userId);
  }, []);



  const calculateAge = (dob) => {
    if (!dob) return null;
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  const userAge = calculateAge(aboutMe[DATE_OF_BIRTH]);
  const [showButton, setShowButton] = useState(true);

  // Add state for active tab
  const [activeTab, setActiveTab] = useState(1);

  // Add state for tab completion
  const [completedTabs, setCompletedTabs] = useState({
    1: false,
    2: false,
    3: false,
    4: false,
    5: false
  });

  // Modified function to check if current tab is complete
  const isTabComplete = (tabId) => {
    switch (tabId) {
      case 1:
        return aboutMe[DATE_OF_BIRTH] && 
               (aboutMe[GENDER] == '0' || aboutMe[GENDER] == '1') && 
               (aboutMe[ALCOHOL_DRINKS_PER_WEEK] !== null && aboutMe[ALCOHOL_DRINKS_PER_WEEK] !== undefined && aboutMe[ALCOHOL_DRINKS_PER_WEEK] !== '') &&
               (aboutMe[CIGARETTES_USE] == '0' || aboutMe[CIGARETTES_USE] == '1' || aboutMe[CIGARETTES_USE] == '2') &&
               ((aboutMe[CIGARETTES_USE] == "0") || 
                (aboutMe[CIGARETTES_USE] == "1" && aboutMe['smoking_duration']) ||
                (aboutMe[CIGARETTES_USE] == "2" && aboutMe['smoking_duration'] && aboutMe[smoking_frequencyName]));

      case 2:
        // Check Diet values and limits
        console.log('Diet values:', diet);
        return Object.entries(diet).every(([key, value]) => {
          if (limit[key]) {
            return value !== undefined && 
                   value !== '' && 
                   value !==  undefined && 
                   Number(value) <= limit[key];
          }
          return value !== undefined && value !== '' && value !== null;
        });


      case 3:
        // Check Exercise/Sleep/Stress values - both numeric and dropdown fields
        console.log('Exercise/Sleep/Stress values:', exerciseSleepStress);
        return (exerciseSleepStress[exerciseSaunaFrequencyModelName] !== null &&
                exerciseSleepStress[exerciseSaunaFrequencyModelName] !== '') &&
               (exerciseSleepStress[strengthTrainingName] !== null &&
                exerciseSleepStress[strengthTrainingName] !== '') &&
               (exerciseSleepStress[cardioName] !== null &&
                exerciseSleepStress[cardioName] !== '') &&
               (exerciseSleepStress[actualSleepHoursName] !== null &&
                exerciseSleepStress[actualSleepHoursName] !== '') &&
               (exerciseSleepStress[sleepQualityName] !== null && 
                exerciseSleepStress[sleepQualityName] !== '') &&
               (exerciseSleepStress[stressQualityName] !== null && 
                exerciseSleepStress[stressQualityName] !== '');
        
      case 4:
        // Check Supplements values and limits
        return Object.entries(supplements).every(([key, value]) => {
          if (limit[key]) {
            return value !== undefined && 
                   value !== '' && 
                   value !== null && 
                   Number(value) <= limit[key];
          }
          return value !== undefined && value !== '' && value !== null;
        });
      case 5:
        return true;
        
      default:
        return false;
    }
  };

  // Modified tab change handler with autofill check
  const handleTabChange = (newTab) => {
    console.log('Attempting to change to tab:', newTab);
    
    // If data is already filled in the target tab, mark it as complete
    if (isTabComplete(newTab)) {
      setCompletedTabs(prev => ({
        ...prev,
        [newTab]: true
      }));
    }
    
    setActiveTab(newTab);
  };

  // Add useEffect to check initial completion status
  useEffect(() => {
    // Check all tabs for completion on initial load
    [1, 2, 3, 4].forEach(tabId => {
      if (isTabComplete(tabId)) {
        setCompletedTabs(prev => ({
          ...prev,
          [tabId]: true
        }));
      }
    });
  }, [aboutMe, diet, exerciseSleepStress, supplements]); // Add dependencies for the data

  // Add a function to check for errors in a specific tab
  const hasTabErrors = (tabId) => {
    // If data hasn't been loaded from database, don't show errors
    if (!loaded) {
      return false;
    }

    switch (tabId) {
      case 1:
        return Object.entries(aboutMe).some(([key, value]) => {
          // Skip certain fields based on cigarette use
          if (aboutMe[CIGARETTES_USE] === "0" && 
              (key === "smoking_duration" || key === smoking_frequencyName)) {
            return false;
          }
          if (aboutMe[CIGARETTES_USE] === "1" && key === smoking_frequencyName) {
            return false;
          }
          // Only check for errors if the field has been interacted with
          return value !== null && value !== undefined && value === "";
        });

      case 2:
        return Object.entries(diet).some(([key, value]) => {
          if (value === null || value === undefined) return false; // Skip uninteracted fields
          if (limit[key]) {
            return value === "" || Number(value) > limit[key];
          }
          return value === "";
        });

      case 3:
        return Object.entries(exerciseSleepStress).some(([key, value]) => {
          if (value === null || value === undefined) return false; // Skip uninteracted fields
          return value === "" || (limit[key] && Number(value) > limit[key]);
        });

      case 4:
        return Object.entries(supplements).some(([key, value]) => {
          if (value === null || value === undefined) return false; // Skip uninteracted fields
          if (limit[key]) {
            return value === "" || Number(value) > limit[key];
          }
          return value === "";
        });

      default:
        return false;
    }
  };


  // Update the tabs definition to include error state
  const tabs = [
    { 
      id: 1, 
      name: "About me", 
      status: hasTabErrors(1) ? "error" : completedTabs[1] ? "completed" : "incomplete" 
    },
    { 
      id: 2, 
      name: "Diet", 
      status: hasTabErrors(2) ? "error" : completedTabs[2] ? "completed" : "incomplete" 
    },
    { 
      id: 3, 
      name: "Sleep and Stress", 
      status: hasTabErrors(3) ? "error" : completedTabs[3] ? "completed" : "incomplete" 
    },
    { 
      id: 4, 
      name: "Supplement", 
      status: hasTabErrors(4) ? "error" : completedTabs[4] ? "completed" : "incomplete" 
    },
    { 
      id: 5, 
      name: "Results", 
      status: completedTabs[5] ? "completed" : "incomplete" 
    }
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <AboutMe
            dataState={aboutMe}
            setDataState={setAboutMe}
            errors={errors}
            setErrors={setErrors}
            maxDate={maxDate}
          />
        );
      case 2:
        return (
          <Diet
            dataState={diet}
            setDataState={setDiet}
            waterUnit={waterUnit}
            onChangeUnit={handleUnitChange}
            onChangeUnitDefault={handleUnitDefaultChange}
            errors={errors}
            limit={limit}
          />
        );
      case 3:
        return (
          <ExerciseSleepStress
            dataState={exerciseSleepStress}
            setDataState={setExerciseSleepStress}
            errors={errors}
            limit={limit}
          />
        );
      case 4:
        return (
          <Supplements
            dataState={supplements}
            setDataState={setSupplements}
            errors={errors}
            onChangeUnit={handleUnitChange}
            onChangeUnitDefault={handleUnitDefaultChange}
            greenTeaUnit={greenTeaUnit}
            limit={limit}
          />
        );
      case 5:
        return (
          calculatorTimes == 0 ? (
            <div style={{ fontSize: '40px', padding: '150px 0', textAlign: 'center' }}>Press submit to see results!</div>
          ) : (
            (score && estiScore) || submitted ? (
              <CalculatorResponse 
                score={score} 
                estiScore={estiScore} 
                calculatorTimes={calculatorTimes} 
                CALCULATOR_TIMES={CALCULATOR_TIMES} 
              />
            ) : null
          )
        );
      default:
        return null;
    }
  };

  if (!loaded) {
    return <LoadingSpinner />;
  }

  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <div className="questionnaire_title">
        Lifespan Calculator
      </div>

      {/* Show form if under limit OR viewing results */}
      {(calculatorTimes < CALCULATOR_TIMES || activeTab === 5) && (
        <form onSubmit={handleSubmit}>
          <div className="tabs-container">
            <div className="tabs-header">
              {tabs.map(tab => (
                <div 
                  key={tab.id}
                  className={`tab ${activeTab === tab.id ? 'active' : ''} ${tab.status}`}
                  onClick={() => handleTabChange(tab.id)}
                >
                  <div className="tab-number">{tab.id}</div>
                  <div className="tab-name">{tab.name}</div>
                  <div className="tab-status">{tab.status}</div>
                </div>
              ))}
            </div>

            <div className="tab-content">
              {renderTabContent()}
            </div>

              {activeTab === 5 && (
                <button
                  type="submit"
                  disabled={!isTabComplete(4)}
                  style={{
                    marginTop: '20px',
                    marginBottom: '50px',
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {calculatorTimes === 0
                    ? "Submit"
                    : `Calculate (${CALCULATOR_TIMES - calculatorTimes} remaining)`}
                </button>
              )}
            </div>
        </form>
      )}

      {calculatorTimes >= CALCULATOR_TIMES && (
        <div>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            margin: '20px 0', 
            color: '#ff8c00', 
            fontWeight: 'bold', 
            marginTop: '40px'
          }}>
            <div style={{ 
              padding: '20px', 
              border: '2px solid #ffa500', 
              borderRadius: '10px', 
              backgroundColor: '#fff3e0', 
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', 
              textAlign: 'center' 
            }}>
              <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                {!currentUser?.isPremium 
                  ? "You are currently out of free calculation submissions. Please subscribe to continue with CODE: GROWYOUNG2025 for 50% off on premium plan for 3 months, $9.99 after!"
                  : "You are currently out of free calculation submissions. Please go to the 'MyPlan' tab for more accurate predictions."
                }
              </p>
            </div>
          </div>
          <CalculatorResponse 
                score={score} 
                estiScore={estiScore} 
                calculatorTimes={calculatorTimes} 
                CALCULATOR_TIMES={CALCULATOR_TIMES} 
              />
          </div>)
            }

      {((userAge !== null && userAge < 18) || (userAgeFromDB !== null && userAgeFromDB < 18)) && (
        <p style={{ textAlign: "center" }}>
          Note: Our models are not calibrated for people under 18, so we cannot
          estimate how much your lifespan might increase by modifying your lifestyle.
        </p>
      )}
    </div>
  );
}

export default Calculator;
