import { useState, useMemo, useEffect } from "react";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from 'axios'
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";
import './SF36Test.css'

function SF36Test() {

  const [highlightedLabel, setHighlightedLabel] = useState(null);
  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === '36item_overall').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === '36item_overall')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)
                if (testDate === currDate) {
                  setSubmittedToday(true)
                  setSubmitted(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])
  const baseUrl = process.env.REACT_APP_baseUrl;
  const baseUrlAWS =`${baseUrl}api/myprogress`

  const[submitted, setSubmitted] = useState(false)
  const[score, setScore] = useState()

  // questions
  const [q1, setQ1] = useState("code11")
  const [q2, setQ2] = useState("code11")
  const [q20, setQ20] = useState("code11")
  const [q22, setQ22] = useState("code11")
  const [q34, setQ34] = useState("code11")
  const [q36, setQ36] = useState("code11")

  const [q3, setQ3] = useState("code21")
  const [q4, setQ4] = useState("code21")
  const [q5, setQ5] = useState("code21")
  const [q6, setQ6] = useState("code21")
  const [q7, setQ7] = useState("code21")
  const [q8, setQ8] = useState("code21")
  const [q9, setQ9] = useState("code21")
  const [q10, setQ10] = useState("code21")
  const [q11, setQ11] = useState("code21")
  const [q12, setQ12] = useState("code21")

  const [q13, setQ13] = useState("code31")
  const [q14, setQ14] = useState("code31")
  const [q15, setQ15] = useState("code31")
  const [q16, setQ16] = useState("code31")
  const [q17, setQ17] = useState("code31")
  const [q18, setQ18] = useState("code31")
  const [q19, setQ19] = useState("code31")

  const[q21, setQ21] = useState("code41")
  const[q23, setQ23] = useState("code41")
  const[q26, setQ26] = useState("code41")
  const[q27, setQ27] = useState("code41")
  const[q30, setQ30] = useState("code41")

  const[q24, setQ24] = useState("code51")
  const[q25, setQ25] = useState("code51")
  const[q28, setQ28] = useState("code51")
  const[q29, setQ29] = useState("code51")
  const[q31, setQ31] = useState("code51")

  const[q32, setQ32] = useState("code61")
  const[q33, setQ33] = useState("code61")
  const[q35, setQ35] = useState("code61")


  //for calculations
  const dict = {
    code11: 100,
    code12: 75,
    code13: 50,
    code14: 25,
    code15: 0,
    code21: 0,
    code22: 50,
    code23: 100,
    code31: 0,
    code32: 100,
    code41: 100,
    code42: 80,
    code43: 60,
    code44: 40,
    code45: 20,
    code46: 0,
    code51: 0,
    code52: 20,
    code53: 40,
    code54: 60,
    code55: 80,
    code56: 100,
    code61: 0,
    code62: 25,
    code63: 50,
    code64: 75,
    code65: 100
  }


  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

  const calculateScore = (scale) => {
    
    let physical_functioning = dict[q3] + dict[q4] + dict[q5] + dict[q6] + dict[q7] + dict[q8] + dict[q9] + dict[q10] + dict[q11] + dict[q12]
    let limitation_physical = dict[q13] + dict[q14] + dict[q15] + dict[q16]
    let limitation_emotional = dict[q17] + dict[q18] + dict[q19]
    let energy = dict[q23] + dict[q27] + dict[q29] + dict[q31]
    let emotional_wellbeing = dict[q24] + dict[q25] + dict[q26] + dict[q28] + dict[q30]
    let social_functioning = dict[q20] + dict[q32]
    let pain = dict[q21] + dict[q22]
    let general_health = dict[q1] + dict[q33] + dict[q34] + dict[q35] + dict[q36]

    let overall_score = Math.round((physical_functioning + limitation_physical + limitation_emotional + energy + emotional_wellbeing + social_functioning + pain + general_health) / 36)
    
    if (scale === "physical functioning") {
      return Math.round((physical_functioning) / 10)
    } else if (scale === "limitation physical") {
      return Math.round((limitation_physical) / 4)
    } else if (scale === "limitation emotional") {
      return Math.round((limitation_emotional) / 3)
    } else if (scale === "energy") {
      return Math.round((energy) / 4)
    } else if (scale === "emotional wellbeing") {
      return Math.round((emotional_wellbeing) / 5)
    } else if (scale === "social functioning") {
      return Math.round((social_functioning) / 2)
    } else if (scale === "pain") {
      return Math.round((pain) / 2)
    } else if (scale === "general health") {
      return Math.round((general_health) / 5)
    } else {
      return overall_score
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()

    const s = calculateScore("overall")
    setScore(s)
    setSubmitted(true)

    let testRes = {}
    testRes["user"] = userId
    testRes["testId"] = "36item_overall"
    testRes["score"] = s
    submitData(testRes)
    console.log(testRes)

    const s1 = calculateScore("physical functioning")
    testRes["testId"] = "36item_pf"
    testRes["score"] = s1
    submitData(testRes)

    const s2 = calculateScore("limitation physical")
    testRes["testId"] = "36item_lp"
    testRes["score"] = s2
    submitData(testRes)

    const s3 = calculateScore("limitation emotional")
    testRes["testId"] = "36item_le"
    testRes["score"] = s3
    submitData(testRes)

    const s4 = calculateScore("energy")
    testRes["testId"] = "36item_e"
    testRes["score"] = s4
    submitData(testRes)

    const s5 = calculateScore("emotional wellbeing")
    testRes["testId"] = "36item_ew"
    testRes["score"] = s5
    submitData(testRes)

    const s6 = calculateScore("social functioning")
    testRes["testId"] = "36item_sf"
    testRes["score"] = s6
    submitData(testRes)

    const s7 = calculateScore("pain")
    testRes["testId"] = "36item_p"
    testRes["score"] = s7
    submitData(testRes)

    const s8 = calculateScore("general health")
    testRes["testId"] = "36item_gh"
    testRes["score"] = s8
    submitData(testRes)

  };

  // call API to submit the form data 
  const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }

  return (
    <div className="general-health-container">
      <Container>
        <div>
          <Form onSubmit={handleSubmit}>
            <h3
              style={{
                textAlign: "center",
                marginTop: "65px",
                marginBottom: "30px",
              }}
            >
              General Health Inventory
            </h3>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "5px",
              }}
            >
              <div style={{ maxWidth: "80%" }}>
                <Form.Text>
                  Instructions: Answer the following questions honestly and accurately, without interruptions.
                </Form.Text>
              </div>
            </div>
            
            <Form.Label>
              General Health
            </Form.Label>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q1"
                style={{ marginLeft: "20px", color: highlightedLabel === '1' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '1' ? 'bold' : 'normal', }}
              >
                1) In general, would you say your health is:
              </Form.Label>
              <Form.Control
                as="select"
                name="q1"
                id="q1"
                value={q1}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ1(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('1')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code11">Excellent</option>
                <option value="code12">Very Good</option>
                <option value="code13">Good</option>
                <option value="code14">Fair</option>
                <option value="code15">Poor</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q2"
                style={{ marginLeft: "20px", color: highlightedLabel === '2' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '2' ? 'bold' : 'normal', }}
              >
                2) Compared to one year ago, how would you rate your health in general now?
              </Form.Label>
              <Form.Control
                as="select"
                name="q2"
                id="q2"
                value={q2}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ2(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('2')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code11">Much better now</option>
                <option value="code12">Somewhat better now</option>
                <option value="code13">About the same</option>
                <option value="code14">Somewhat worse now</option>
                <option value="code15">Much worse now</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q33"
                style={{ marginLeft: "20px", color: highlightedLabel === '3' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '3' ? 'bold' : 'normal', }}
              >
                3) I seem to get sick a little easier than other people
              </Form.Label>
              <Form.Control
                as="select"
                name="q33"
                id="q33"
                value={q33}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ33(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('3')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code61">Definitely true</option>
                <option value="code62">Mostly true</option>
                <option value="code63">Don't know</option>
                <option value="code64">Mostly false</option>
                <option value="code65">Definitely false</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q34"
                style={{ marginLeft: "20px", color: highlightedLabel === '4' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '4' ? 'bold' : 'normal', }}
              >
                4) I am as healthy as anybody I know
              </Form.Label>
              <Form.Control
                as="select"
                name="q34"
                id="q34"
                value={q34}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ34(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('4')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code11">Definitely true</option>
                <option value="code12">Mostly true</option>
                <option value="code13">Don't know</option>
                <option value="code14">Mostly false</option>
                <option value="code15">Definitely false</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q35"
                style={{ marginLeft: "20px", color: highlightedLabel === '5' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '5' ? 'bold' : 'normal', }}
              >
                5) I expect my health to get worse
              </Form.Label>
              <Form.Control
                as="select"
                name="q35"
                id="q35"
                value={q35}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ35(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('5')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code61">Definitely true</option>
                <option value="code62">Mostly true</option>
                <option value="code63">Don't know</option>
                <option value="code64">Mostly false</option>
                <option value="code65">Definitely false</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q36"
                style={{ marginLeft: "20px", color: highlightedLabel === '6' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '6' ? 'bold' : 'normal', }}
              >
                6) My health is excellent
              </Form.Label>
              <Form.Control
                as="select"
                name="q36"
                id="q36"
                value={q36}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ36(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('6')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code11">Definitely true</option>
                <option value="code12">Mostly true</option>
                <option value="code13">Don't know</option>
                <option value="code14">Mostly false</option>
                <option value="code15">Definitely false</option>
              </Form.Control>
            </Form.Group>

            <Form.Label>
                Limitations of Activities
                <p>The following items are about activities you might do during a typical day. Does your health now limit you in these activities? If so, how much?</p>
            </Form.Label>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q3"
                style={{ marginLeft: "20px", color: highlightedLabel === '7' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '7' ? 'bold' : 'normal', }}
              >
                7) Vigorous activities, such as running, lifting heavy objects, participating in strenuous sports.
              </Form.Label>
              <Form.Control
                as="select"
                name="q3"
                id="q3"
                value={q3}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ3(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('7')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code21">Yes, Limited a lot</option>
                <option value="code22">Yes, Limited a Little</option>
                <option value="code23">No, Not Limited at all</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q4"
                style={{ marginLeft: "20px", color: highlightedLabel === '8' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '8' ? 'bold' : 'normal', }}
              >
                8) Moderate activities, such as moving a table, pushing a vacuum cleaner, bowling, or playing golf
              </Form.Label>
              <Form.Control
                as="select"
                name="q4"
                id="q4"
                value={q4}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ4(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('8')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code21">Yes, Limited a lot</option>
                <option value="code22">Yes, Limited a Little</option>
                <option value="code23">No, Not Limited at all</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="q5" style={{ marginLeft: "20px", color: highlightedLabel === '9' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '9' ? 'bold' : 'normal', }}>
                9) Lifting or carrying groceries
              </Form.Label>
              <Form.Control
                as="select"
                name="q5"
                id="q5"
                value={q5}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ5(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('9')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code21">Yes, Limited a lot</option>
                <option value="code22">Yes, Limited a Little</option>
                <option value="code23">No, Not Limited at all</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q6"
                style={{ marginLeft: "20px", color: highlightedLabel === '10' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '10' ? 'bold' : 'normal', }}
              >
                10) Climbing several flights of stairs
              </Form.Label>
              <Form.Control
                as="select"
                name="q6"
                id="q6"
                value={q6}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ6(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('10')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code21">Yes, Limited a lot</option>
                <option value="code22">Yes, Limited a Little</option>
                <option value="code23">No, Not Limited at all</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q7"
                style={{ marginLeft: "20px", color: highlightedLabel === '11' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '11' ? 'bold' : 'normal', }}
              >
                11) Climbing one flight of stairs
              </Form.Label>
              <Form.Control
                as="select"
                name="q7"
                id="q7"
                value={q7}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ7(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('11')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code21">Yes, Limited a lot</option>
                <option value="code22">Yes, Limited a Little</option>
                <option value="code23">No, Not Limited at all</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label htmlFor="q8" style={{ marginLeft: "20px", color: highlightedLabel === '12' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '12' ? 'bold' : 'normal', }}>
                12) Bending, kneeling, or stooping
              </Form.Label>
              <Form.Control
                as="select"
                name="q8"
                id="q8"
                value={q8}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ8(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('12')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code21">Yes, Limited a lot</option>
                <option value="code22">Yes, Limited a Little</option>
                <option value="code23">No, Not Limited at all</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="q9" style={{ marginLeft: "20px", color: highlightedLabel === '13' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '13' ? 'bold' : 'normal', }}>
                13) Walking more than a mile
              </Form.Label>
              <Form.Control
                as="select"
                name="q9"
                id="q9"
                value={q9}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ9(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('13')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code21">Yes, Limited a lot</option>
                <option value="code22">Yes, Limited a Little</option>
                <option value="code23">No, Not Limited at all</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label htmlFor="q10" style={{ marginLeft: "20px", color: highlightedLabel === '14' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '14' ? 'bold' : 'normal', }}>
                14) Walking several blocks
              </Form.Label>
              <Form.Control
                as="select"
                name="q10"
                id="q10"
                value={q10}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ10(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('14')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code21">Yes, Limited a lot</option>
                <option value="code22">Yes, Limited a Little</option>
                <option value="code23">No, Not Limited at all</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="q11" style={{ marginLeft: "20px", color: highlightedLabel === '15' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '15' ? 'bold' : 'normal', }}>
                15) Walking one block
              </Form.Label>
              <Form.Control
                as="select"
                name="q11"
                id="q11"
                value={q11}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ11(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('15')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code21">Yes, Limited a lot</option>
                <option value="code22">Yes, Limited a Little</option>
                <option value="code23">No, Not Limited at all</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label htmlFor="q12" style={{ marginLeft: "20px", color: highlightedLabel === '16' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '16' ? 'bold' : 'normal', }}>
                16) Bathing or dressing yourself
              </Form.Label>
              <Form.Control
                as="select"
                name="q12"
                id="q12"
                value={q12}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ12(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('16')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code21">Yes, Limited a lot</option>
                <option value="code22">Yes, Limited a Little</option>
                <option value="code23">No, Not Limited at all</option>
              </Form.Control>
            </Form.Group>

            <Form.Label>
                Physical Health Problems
                <p>During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of your physical health?</p>
            </Form.Label>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q13"
                style={{ marginLeft: "20px", color: highlightedLabel === '17' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '17' ? 'bold' : 'normal', }}
              >
                17) Cut down the amount of time you spent on work or other activities
              </Form.Label>
              <Form.Control
                as="select"
                name="q13"
                id="q13"
                value={q13}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ13(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('17')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code31">Yes</option>
                <option value="code32">No</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q14"
                style={{ marginLeft: "20px", color: highlightedLabel === '18' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '18' ? 'bold' : 'normal', }}
              >
                18) Accomplished less than you would like
              </Form.Label>
              <Form.Control
                as="select"
                name="q14"
                id="q14"
                value={q14}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ14(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('18')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code31">Yes</option>
                <option value="code32">No</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="q15" style={{ marginLeft: "20px", color: highlightedLabel === '19' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '19' ? 'bold' : 'normal', }}>
                19) Were limited in the kind of work or other activities
              </Form.Label>
              <Form.Control
                as="select"
                name="q15"
                id="q15"
                value={q15}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ15(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('19')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code31">Yes</option>
                <option value="code32">No</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q16"
                style={{ marginLeft: "20px", color: highlightedLabel === '20' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '20' ? 'bold' : 'normal', }}
              >
                20) Had difficulty performing the work or other activities (for example, it took extra effort)
              </Form.Label>
              <Form.Control
                as="select"
                name="q16"
                id="q16"
                value={q16}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ16(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('20')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code31">Yes</option>
                <option value="code32">No</option>
              </Form.Control>
            </Form.Group>

            <Form.Label>
                Emotional Health Problems
                <p>During the past 4 weeks, have you had any of the following problems with your work or other regular daily activities as a result of any emotional problems (such as feeling depressed or anxious)?</p>
            </Form.Label>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q17"
                style={{ marginLeft: "20px", color: highlightedLabel === '21' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '21' ? 'bold' : 'normal', }}
              >
                21) Cut down the amount of time you spent on work or other activities
              </Form.Label>
              <Form.Control
                as="select"
                name="q17"
                id="q17"
                value={q17}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ17(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('21')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code31">Yes</option>
                <option value="code32">No</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q18"
                style={{ marginLeft: "20px", color: highlightedLabel === '22' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '22' ? 'bold' : 'normal', }}
              >
                22) Accomplished less than you would like
              </Form.Label>
              <Form.Control
                as="select"
                name="q18"
                id="q18"
                value={q18}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ18(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('22')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code31">Yes</option>
                <option value="code32">No</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label htmlFor="q19" style={{ marginLeft: "20px", color: highlightedLabel === '23' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '23' ? 'bold' : 'normal', }}>
                23) Didn't do work or other activities as carefully as usual
              </Form.Label>
              <Form.Control
                as="select"
                name="q19"
                id="q19"
                value={q19}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ19(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('23')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code31">Yes</option>
                <option value="code32">No</option>
              </Form.Control>
            </Form.Group>

            <Form.Label>
              Social Activities
            </Form.Label>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q20"
                style={{ marginLeft: "20px", color: highlightedLabel === '24' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '24' ? 'bold' : 'normal', }}
              >
                24) Emotional problems interfered with your normal social activities with family, friends, neighbors, or groups?
              </Form.Label>
              <Form.Control
                as="select"
                name="q20"
                id="q20"
                value={q20}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ20(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('24')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code11">Not at all</option>
                <option value="code12">Slightly</option>
                <option value="code13">Moderately</option>
                <option value="code14">Severe</option>
                <option value="code15">Very Severe</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q32"
                style={{ marginLeft: "20px", color: highlightedLabel === '25' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '25' ? 'bold' : 'normal', }}
              >
                25) During the past 4 weeks, how much of the time has your physical health or emotional problems interfered with your
                social activities?
              </Form.Label>
              <Form.Control
                as="select"
                name="q32"
                id="q32"
                value={q32}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ32(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('25')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code61">All of the time</option>
                <option value="code62">Most of the time</option>
                <option value="code63">Some of the time</option>
                <option value="code64">A little bit of the time</option>
                <option value="code65">None of the time</option>
              </Form.Control>
            </Form.Group>

            <Form.Label>
              Pain
            </Form.Label>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q21"
                style={{ marginLeft: "20px", color: highlightedLabel === '26' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '26' ? 'bold' : 'normal', }}
              >
                26) How much bodily pain have you had during the past 4 weeks?
              </Form.Label>
              <Form.Control
                as="select"
                name="q21"
                id="q21"
                value={q21}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ21(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('26')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code41">None</option>
                <option value="code42">Very Mild</option>
                <option value="code43">Mild</option>
                <option value="code44">Moderate</option>
                <option value="code45">Severe</option>
                <option value="code46">Very Severe</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q22"
                style={{ marginLeft: "20px", color: highlightedLabel === '27' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '27' ? 'bold' : 'normal', }}
              >
                27) During the past 4 weeks, how much did pain interfere with your normal work (including both work outside the home and housework)?
              </Form.Label>
              <Form.Control
                as="select"
                name="q22"
                id="q22"
                value={q22}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ22(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('27')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code11">Not at all</option>
                <option value="code12">A little bit</option>
                <option value="code13">Moderately</option>
                <option value="code14">Quite a bit</option>
                <option value="code15">Extremely</option>
              </Form.Control>
            </Form.Group>

            <Form.Label>
              Energy and Emotions
              <p>These questions are about how you feel and how things have been with you during the last 4 weeks. For each
                question, please give the answer that comes closest to the way you have been feeling.
              </p>
            </Form.Label>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q23"
                style={{ marginLeft: "20px", color: highlightedLabel === '28' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '28' ? 'bold' : 'normal', }}
              >
                28) Do you feel full of pep?
              </Form.Label>
              <Form.Control
                as="select"
                name="q23"
                id="q23"
                value={q23}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ23(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('28')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code41">All of the time</option>
                <option value="code42">Most of the time</option>
                <option value="code43">A good bit of the time</option>
                <option value="code44">Some of the time</option>
                <option value="code45">A little bit of the time</option>
                <option value="code46">None of the time</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q24"
                style={{ marginLeft: "20px", color: highlightedLabel === '29' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '29' ? 'bold' : 'normal', }}
              >
                29) Have you been a very nervous person?
              </Form.Label>
              <Form.Control
                as="select"
                name="q24"
                id="q24"
                value={q24}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ24(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('29')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code51">All of the time</option>
                <option value="code52">Most of the time</option>
                <option value="code53">A good bit of the time</option>
                <option value="code54">Some of the time</option>
                <option value="code55">A little bit of the time</option>
                <option value="code56">None of the time</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q25"
                style={{ marginLeft: "20px", color: highlightedLabel === '30' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '30' ? 'bold' : 'normal', }}
              >
                30) Have you felt so down in the dumps that nothing could cheer you up?
              </Form.Label>
              <Form.Control
                as="select"
                name="q25"
                id="q25"
                value={q25}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ25(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('30')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code51">All of the time</option>
                <option value="code52">Most of the time</option>
                <option value="code53">A good bit of the time</option>
                <option value="code54">Some of the time</option>
                <option value="code55">A little bit of the time</option>
                <option value="code56">None of the time</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q26"
                style={{ marginLeft: "20px", color: highlightedLabel === '31' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '31' ? 'bold' : 'normal', }}
              >
                31) Have you felt calm and peaceful?
              </Form.Label>
              <Form.Control
                as="select"
                name="q26"
                id="q26"
                value={q26}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ26(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('31')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code41">All of the time</option>
                <option value="code42">Most of the time</option>
                <option value="code43">A good bit of the time</option>
                <option value="code44">Some of the time</option>
                <option value="code45">A little bit of the time</option>
                <option value="code46">None of the time</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q27"
                style={{ marginLeft: "20px", color: highlightedLabel === '32' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '32' ? 'bold' : 'normal', }}
              >
                32) Did you have a lot of energy?
              </Form.Label>
              <Form.Control
                as="select"
                name="q27"
                id="q27"
                value={q27}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ27(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('32')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code41">All of the time</option>
                <option value="code42">Most of the time</option>
                <option value="code43">A good bit of the time</option>
                <option value="code44">Some of the time</option>
                <option value="code45">A little bit of the time</option>
                <option value="code46">None of the time</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q28"
                style={{ marginLeft: "20px", color: highlightedLabel === '33' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '33' ? 'bold' : 'normal', }}
              >
                33) Have you felt downhearted and blue?
              </Form.Label>
              <Form.Control
                as="select"
                name="q28"
                id="q28"
                value={q28}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ28(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('33')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code51">All of the time</option>
                <option value="code52">Most of the time</option>
                <option value="code53">A good bit of the time</option>
                <option value="code54">Some of the time</option>
                <option value="code55">A little bit of the time</option>
                <option value="code56">None of the time</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q29"
                style={{ marginLeft: "20px", color: highlightedLabel === '34' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '34' ? 'bold' : 'normal', }}
              >
                34) Did you feel worn out?
              </Form.Label>
              <Form.Control
                as="select"
                name="q29"
                id="q29"
                value={q29}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ29(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('34')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code51">All of the time</option>
                <option value="code52">Most of the time</option>
                <option value="code53">A good bit of the time</option>
                <option value="code54">Some of the time</option>
                <option value="code55">A little bit of the time</option>
                <option value="code56">None of the time</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
              }}
            >
              <Form.Label
                htmlFor="q30"
                style={{ marginLeft: "20px", color: highlightedLabel === '35' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '35' ? 'bold' : 'normal', }}
              >
                35) Have you been a happy person?
              </Form.Label>
              <Form.Control
                as="select"
                name="q30"
                id="q30"
                value={q30}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ30(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('35')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code41">All of the time</option>
                <option value="code42">Most of the time</option>
                <option value="code43">A good bit of the time</option>
                <option value="code44">Some of the time</option>
                <option value="code45">A little bit of the time</option>
                <option value="code46">None of the time</option>
              </Form.Control>
            </Form.Group>

            <Form.Group
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                marginBottom: "8px",
                backgroundColor: '#f0f0f0', 
                borderRadius: '8px'
              }}
            >
              <Form.Label
                htmlFor="q31"
                style={{ marginLeft: "20px", color: highlightedLabel === '36' ? '#04723A' : '#333',
                  fontWeight: highlightedLabel === '36' ? 'bold' : 'normal', }}
              >
                36) Did you feel tired?
              </Form.Label>
              <Form.Control
                as="select"
                name="q31"
                id="q31"
                value={q31}
                className="form-control general-health"
                onChange={(e) => {
                  console.log(e.target.value);
                  setQ31(e.target.value);
                }}
                onFocus={() => setHighlightedLabel('36')}
                onBlur={() => setHighlightedLabel(null)}
              >
                <option value="code51">All of the time</option>
                <option value="code52">Most of the time</option>
                <option value="code53">A good bit of the time</option>
                <option value="code54">Some of the time</option>
                <option value="code55">A little bit of the time</option>
                <option value="code56">None of the time</option>
              </Form.Control>
            </Form.Group>

            <div style={{ display: "flex", justifyContent: "center", marginTop: "30px" }}>
              {(!submittedToday && !submitted) && (
                <div style={{ paddingBottom: "120px" }}>
                  <Button type="submit" variant="dark" style={{ minWidth: "225px" }}>
                    Submit
                  </Button>
                </div>
              )}
              {(submittedToday || submitted) && (
                <div>
                  <Button type="submit" variant="dark" style={{ minWidth: "225px" }} disabled>
                    Submit
                  </Button>
                  <p style={{color: "gray", fontSize: "14px", textAlign: 'center'}}>(Already submitted today)</p>
                </div>
              )}
            </div>
          </Form>
          <div>
            {submitted && (
              <div>
                <div style={{display: "flex", justifyContent: "center", paddingBottom: "70px",}}>Score: {score}/100</div>
                <p style={{color: "gray", fontSize: "14px",display: "flex", justifyContent: "center"}}>(Where higher values represent better health)</p>
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

export default SF36Test;