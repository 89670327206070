import React from 'react';
import './SavingIcon.css'; 

const Loading = () => (
  <div className='saving-overlay'>
    <div className='spinner-container'>
      <div className='spinner'></div>
      <p className='spinner-text'>Saving data, please wait...</p>
    </div>
  </div>
  
);

export default Loading;