import React, {useState, useMemo, useEffect} from 'react'
import Calendar from 'react-calendar'
import LogForm from './LogForm'
import './Log.css'
import { fetchDates, getLoggedData, getLogStatusColor } from './action'
import { processedMeat, redMeat, poultry, fish, fv, dairy, wholeGrain, refinedGrain,
  legumes, saturatedFat, transFat, water, sugar, sweetener, alcohol, cigarettes,
  calorieRestriction, fasting, sickDays, isSick } from './NameTable/DietNameTable';
import {lCardio, mCardio, hCardio, strength, balance, boneDensity,
  stretching, saunaFreq, saunaDuration} from './NameTable/ExerciseNameTable';
import { multivitamin, vitaminA, vitaminB6, vitaminB12, folicAcid, vitaminC, vitaminD,
  vitaminE, vitaminK, calcium, collagenPeptides, fiber, omega3, greenTea, iron,
  magnesium, oliveOil, probiotics, protein, zinc, sleep} from './NameTable/SupplementsNameTable';
import PlanInstructions from '../../components/PlanInstructions';
import { getLifestyleResponseData } from '../FullProfile/actions';
import { FaStar } from 'react-icons/fa'; // Importing star icon


function Log() {
  const InitialLogData = {
    [processedMeat]: null, [redMeat]: null, [poultry]: null, [fish]: null, [fv]: null,
    [dairy]: null, [wholeGrain]: null, [refinedGrain]: null, [legumes]: null,
    [saturatedFat]: null, [transFat]: null, [water]: null, [sugar]: null, [sweetener]: null,
    [alcohol]: null, [cigarettes]: null, [calorieRestriction]: null, [fasting]: null,
    [lCardio]: null, [mCardio]: null, [hCardio]: null, [strength]: null, [balance]: null,
    [boneDensity]: null, [stretching]: null, [saunaFreq]: null, [saunaDuration]: null,
    [multivitamin]: false, [vitaminA]: null, [vitaminB6]: null, [vitaminB12]: null,
    [folicAcid]: null, [vitaminC]: null, [vitaminD]: null, [vitaminE]: null, [vitaminK]: null,
    [calcium]: null, [collagenPeptides]: false, [fiber]: null, [omega3]: null, [greenTea]: null,
    [iron]: null, [magnesium]: null, [oliveOil]: null, [probiotics]: false, [protein]: null,
    [zinc]: null, [sleep]: null, [sickDays]: null, [isSick]: false
  }

  const firstName = useMemo(() => {
    let user = localStorage.getItem("mysUser");
    user = user ? JSON.parse(user) : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.firstName;
  });

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
    user = user ? JSON.parse(user) : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  });

  const [loggedDates, setLoggedDates] = useState([]);
  const [grayDates, setGrayDates] = useState([]);
  const [rawgrayDates, setRawGrayDates] = useState([]);
  const [loadedDates, setLoadedDates] = useState(false);
  const [date, setDate] = useState([new Date(), new Date()]);
  const [today, setToday] = useState(new Date());
  const [logSubmitted, setLogSubmitted] = useState(false);
  const [dateLogColors, setDateLogColors] = useState({});

  const [logData, setLogData] = useState(InitialLogData);
  const [submitted, setSubmitted] = useState(false);
  const [numDays, setNumDays] = useState(1);
  const [activeKey, setActiveKey] = useState(['']);
  const [logStatusColor, setLogStatusColor] =useState(['']);
  const [instructions, setInstructions] = useState(false);

// Check for Intake form entry
  useEffect(() => {
    const fetchUserInfo = async (userId) => {
      try {
        const res = await getLifestyleResponseData(userId);
        console.log('res: ', res);
        console.log('here: ', typeof(res.data));
        if(res.data === '' || res.data === null){
          // console.log(res.data['dairy']);
          console.log('no intake form data');
          setInstructions(true);}
        else{
          console.log('yes intake form data');
          setInstructions(false);}
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchUserInfo(userId);
  }, []);

console.log("Log_Data:", logData)
  useEffect(() => {
    const getLogData = async (userId, date) => {
      // Check if the date is in the future
      const requestDate = new Date(date);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0); // Reset time part for accurate date comparison
      requestDate.setHours(0, 0, 0, 0);
      
      if (requestDate > currentDate) {
        console.log('Cannot fetch data for future dates');
        setLogData(InitialLogData);
        setSubmitted(false);
        return;
      }

      try {
        const res = await getLoggedData(userId, date);
        
        // Handle different response scenarios
        if (res && res.status === 404) {
          // No data found for this date
          setLogData(InitialLogData);
          setSubmitted(false);
          setDateLogColors(prev => {
            const newColors = { ...prev };
            delete newColors[date];
            return newColors;
          });
          return;
        }

        if (res && res.data) {
          setLogData(res.data);
          // Only update color if we have valid data and a status color
          if (res.data.log_status_color) {
            setDateLogColors(prev => ({
              ...prev,
              [date]: res.data.log_status_color
            }));
          }
          setSubmitted(true);
        } else {
          setLogData(InitialLogData);
          setSubmitted(false);
        }
      } catch (error) {
        console.error('Error fetching log data:', error);
        setLogData(InitialLogData);
        setSubmitted(false);
        // Clear the color for this date in case of error
        setDateLogColors(prev => {
          const newColors = { ...prev };
          delete newColors[date];
          return newColors;
        });
      }
    }
    
    const calculateNumDays = () => {
      const start = new Date(date[0].getFullYear(), date[0].getMonth(), date[0].getDate());
      const end = new Date(date[1].getFullYear(), date[1].getMonth(), date[1].getDate());
      setNumDays(Math.ceil((end - start) / 86400000) + 1);
    }

    // Only fetch data if we have valid dates
    if (date && date[0] && date[0] instanceof Date) {
      const selectedDate = new Date(date[0]);
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      selectedDate.setHours(0, 0, 0, 0);
      
      if (selectedDate <= currentDate) {
        getLogData(userId, date[0].toDateString());
      }
    }
    
    calculateNumDays();
    setActiveKey(['']);
  }, [date, userId]);

  useEffect(() => {
    
    const fetchLogDates = async (userId) => {
      try {
        const res = await fetchDates(userId);

        if (res.status === 201) {
            const loggedRanges = res.data.Consecutive_Dates.map(([start, end]) => [
              new Date(start + 'T00:00:00'),
              new Date(end + 'T00:00:00')
            ]);
            
            const grayDatesFormatted = [];
            const rawDateObjects = [];
            const colorMap = {};

              res.data.Dates.forEach(({ date, color }) => {
                const parsedDate = new Date(date + 'T00:00:00');
              const localDate = parsedDate.toLocaleDateString();
              grayDatesFormatted.push(localDate);
              rawDateObjects.push(parsedDate);

              if (color) {
                colorMap[parsedDate.toDateString()] = color;
              }
            });

            setLoggedDates(loggedRanges);
            setGrayDates(grayDatesFormatted);
            setRawGrayDates(rawDateObjects);
            setDateLogColors(colorMap);
            setLoadedDates(true);
        } else if (res.status === 204) {
          console.log('No previous submissions');
          setLoadedDates(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchLogDates(userId);
}, [submitted, date]);

  const isCurrentYear = (year) => {
    return year === today.getFullYear();
  };

  const isPastYear = (year) => {
    return year < today.getFullYear();
  };

  const isCurrentMonth = (date) => {
    return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth();
  };

  const isPastMonth = (date) => {
    return date.getFullYear() < today.getFullYear() || 
          (date.getFullYear() === today.getFullYear() && date.getMonth() < today.getMonth());
  };

  const onClickDate = (new_date) => {
    const checkDate = new Date(new_date);
    setSubmitted(false);
      
    for (let [start, end] of loggedDates) {
      const startDate = new Date(start);
      const endDate = new Date(end);

      if (checkDate >= startDate && checkDate <= endDate) {
        setDate([startDate, endDate]);
        setSelectRange(false);
        // console.log('logged');
        return; 
      }
    }
    // console.log('not logged');
    setSelectRange(true);
  }
  const onDateChange = (new_date) => {
    if (selectRange) {
      setDate(new_date);
      setSelectRange(false);
    }
  }

  const [selectRange, setSelectRange] = useState(false);
  console.log('date: ', date);
  console.log('type: ', typeof(date));

  
  console.log("Log_color:", logData.log_status_color)
  return (
    <div style={{ backgroundColor: "#F5F5F5" }}>
      <div className="questionnaire_title">Daily Log</div>
      <div className="cal_container">
        <div className="inlineContainer">
          Hi {firstName}, Track your plan by filling out your Daily Log!
          <p style={{ fontSize: '14px', marginTop: '1rem' }}>Use the calendar to select and view a Day's log</p>
        </div>
        {instructions && <PlanInstructions/>}
        <div className="CalendarForm">
          {!loadedDates && (
            <p>Loading...</p>
          )}
          <div className="CalendarCom">
            {loadedDates && (
              <Calendar
                onChange={onDateChange}
                onClickDay={onClickDate}
                value={date}
                calendarType='gregory'
                selectRange={selectRange}
                tileContent={({ date, view }) => {
                  const dateString = date.toLocaleDateString();
                  if (view === 'month' && grayDates.includes(dateString)) {
                    const color = dateLogColors[date.toDateString()] || '#808080';
                    return (
                      <FaStar
                        className="calendar-star"
                        style={{ 
                          color: color, 
                          fontSize: '14px', 
                          position:'absolute', 
                          float:'right',
                          opacity: color === '#808080' ? 0.5 : 1
                        }}
                      />
                    );
                  }
                  return null;
                }}
                tileClassName={({ date, view }) => {
                  const dateString = date.toLocaleDateString();


                  // Logic for day view
                  if (view === 'month') {
                    if (grayDates.includes(dateString)) {
                      
                      return 'logged-date'; // Default case
                    
                    
                    } else if (date.getDay() === 6 || date.getDay() === 0) {
                      if (date < today && !grayDates.includes(dateString)) {
                        return 'missed-date weekend-date'; // Red for missed weekends
                      }
                      return 'weekend-date'; // Light gray for weekends
                    } else if (date.toDateString() === today.toDateString() && !logSubmitted) {
                      return 'today-date'; // Light blue for today's date
                    } else if (date < today && !grayDates.includes(dateString)) {
                      return 'missed-date'; // Red for missed weekdays
                    }
                  }

                  return null;
                }}
                tileDisabled={({ date }) => date > today}
              />
            )}
          </div>
          <div className="FormCom">
            {loadedDates && (
              <LogForm
                dateProp={date[0].toDateString()}
                date2Prop={date[1].toDateString()}
                grayDates={grayDates}
                setGrayDates={setGrayDates}
                date1={date[0].toLocaleDateString()}
                date2={date[1].toLocaleDateString()}
                rawgrayDates={rawgrayDates}
                rawdate1={date[0]}
                rawdate2={date[1]}
                logData={logData}
                setLogData={setLogData}
                submitted={submitted}
                setSubmitted={setSubmitted}
                loggedDates={loggedDates}
                setLoggedDates={setLoggedDates}
                selectRange={selectRange}
                numDays={numDays}
                activeKey={activeKey}
                setActiveKey={setActiveKey}
                setDate={setDate}
                setSelectRange={setSelectRange}
                onSubmit={() => setLogSubmitted(true)} // Update log submission
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Log;
