import React, {useState, useMemo, useEffect} from "react";
import axios from "axios";
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

//each index corresponds to the score of the question. Initialized at 0.
const current = [-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1,-1];
const baseUrl = process.env.REACT_APP_baseUrl;
const baseUrlAWS =`${baseUrl}api/myprogress`

function BrainFogTest() {

  const [selectedQuestions, setSelectedQuestions] = useState({});
  const [submittedToday, setSubmittedToday] = useState(true)

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'brainfog').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'brainfog')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)

                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

  //used to calculate total value
  const [total, setTotal] = useState(0);
  const [submitted, setSubmitted]   = useState(false)

  //array of questions
  const questions = ["1. Do you have difficulty concentrating?", "2. Do you have difficulty paying attention for extended periods?",
  "3. Do you frequently lose your train of thought?",  "4. Do you find it hard to remember things, such as names or appointments?", "5. Do you struggle with word-finding difficulties or forget words?",
 "6. Do you have trouble following conversations or discussions?", "7. Do you experience mental fatigue or exhaustion?", "8. Do you have difficulty with decision-making?", 
 "9. Do you struggle with problem-solving tasks?", "10. Do you find it challenging to process information quickly?", "11. Do you frequently experience mental confusion?", "12. Do you have trouble organizing your thoughts?",
 "13. Do you feel mentally overwhelmed easily?", "14. Do you struggle with mental clarity?", "15. Do you have difficulty multitasking?", 
 "16. Do you frequently experience memory lapses?", "17. Do you feel mentally slower than usual?", "18. Do you find it hard to concentrate on reading or studying?",
 "19. Do you experience brain fog or mental fogginess?", "20. Do you have trouble recalling recent events or conversations?", "21. Do you find it difficult to focus on tasks that require attention?", 
 "22. Do you experience mental fuzziness?", "23. Do you have difficulty concentrating on work or daily activities?"]

 //calculate total. Add up all the numbers in the array and divide by 28. Round to 1 decimal place
  const calculateTotal = async () => {
    let n = 0; 
    let unansweredQ = false
    console.log(current); 
    for (let i = 0; i<current.length; i++) {
        n+= current[i]; 
        if (current[i] < 0) {
          unansweredQ = true
        }
    }

    if (unansweredQ) {
      return
    }

    setTotal(n);
    setSubmitted(true) 

    let testRes = {}
    testRes["user"] = userId
    testRes["testId"] = "brainfog"
    testRes["score"] = n

    submitData(testRes)
    console.log(testRes)
  }

  //updates the array by updating the element at given index
  const handleAgree = (index, num) => {
      current[index.index] = num;
      setSelectedQuestions((prev) => ({
        [index.index]: num, // Mark the question as selected
      }));

  }

  // call API to submit the form data 
  const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }

   //I used BootStrap CSS so the classNames won't work unless it is installed and imported in index.js 

  return (
    <div className="App">
        <div className="container-fluid m-4">
          <div className="row" style={{ position: "fixed", 
            zIndex: 1100,  // Increased z-index to be above questions
            top: 118, 
            left: 20,  // Changed from 30 to 0 for full coverage
            width: "100%", 
            padding: "8px 0px 2px 2px",
            backgroundColor: 'white',  
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Shadow for separation
            }}>
            <div className="col-5">
              <h5><strong>Questions</strong></h5>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Not all all</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Mild</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Moderate</p>
            </div>
            <div className="col smallfont d-flex justify-content-center">
              <p>Severe</p>
            </div>
          </div>
        {questions.map((item, index) => {
          const score = [0,1,2,3]
          return <div style={{marginTop: index === 0 ? "50px" : "0px",}}>
            <div className ="row d-flex align-items-center" style={{backgroundColor: index % 2 !== 0 ? "#f0f0f0" : "transparent", borderRadius: '8px', padding: '5px 20px',}} key = {index}><div className="col-5 mt-2"><p className = "h6" style={{
            fontWeight: selectedQuestions[index] ? "bold" : "normal",
            color: selectedQuestions[index] ? "#04723A" : "#000000",}} key = {index}>{item}</p></div>
            {/* <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" style={{
                   borderRadius: 50,
                   width: 20,
                   height: 20,
                   borderWidth: 1,
                   borderColor: '#000000',
                 }} name = {index} id ="notatall" onClick = {() => handleAgree({index}, score[0])}/>        
            </div> */}
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" style={{
                   borderRadius: 50,
                   width: 20,
                   height: 20,
                   borderWidth: 1,
                   borderColor: '#000000',
                 }} name = {index} id ="notatall" onChange = {() => handleAgree({index}, score[1])}/>
            </div>
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" style={{
                   borderRadius: 50,
                   width: 20,
                   height: 20,
                   borderWidth: 1,
                   borderColor: '#000000',
                 }} name = {index} id ="mild" onChange = {() => handleAgree({index}, score[1])}/>
            </div>
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" style={{
                   borderRadius: 50,
                   width: 20,
                   height: 20,
                   borderWidth: 1,
                   borderColor: '#000000',
                 }} name = {index} id ="moderate" onChange = {() => handleAgree({index}, score[2])}/>
            </div>
            <div className="col d-flex mt-1" style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
              <input type = "radio" style={{
                   borderRadius: 50,
                   width: 20,
                   height: 20,
                   borderWidth: 1,
                   borderColor: '#000000',
                 }} name = {index} id ="severe" onChange = {() => handleAgree({index}, score[3])}/>
            </div>
            </div>
          </div>
        })}
        <div className="row mt-4" style={{ paddingBottom: "120px" }}>
          <div className="col d-flex flex-column align-items-center text-center ">
            {(!submittedToday && !total) && (
              <button className="btn btn-primary" onClick = {() => calculateTotal()}>Calculate Score</button>
            )}
            {(submittedToday || total) && (
              <button className="btn btn-primary">Already Submitted Today</button>
            )}
            <div>{submitted ? <h3>Your score: {total}</h3> : <div></div>}</div>
            </div>
        </div>
        </div>
    </div>
  );
}

export default BrainFogTest;