import React, { useState, useEffect, useMemo } from "react";
import "./Plan.css";
import { useAuthContext } from "../../hooks/useAuthContext";
import RefreshButton from './components/RefreshButton';

const Benefits = (props) => {
  const [benefits, setBenefits] = useState({
    curr_lifespan: '', curr_cancer: '', curr_type_2_diabetes: '',
    curr_high_blood_pressure: '', curr_cdardiovascular_disease: '', curr_stroke: '', curr_arthiritis: '', 
    curr_depression: '',
    
    opt_lifespan: '', opt_cancer: '', opt_type_2_diabetes: '',
    opt_high_blood_pressure: '', opt_cardiovascular_disease: '', opt_stroke: '', opt_arthiritis: '', 
    opt_depression: '',

    new_lifespan: '', new_cancer: '', new_type_2_diabetes: '',
    new_high_blood_pressure: '', new_cardiovascular_disease: '', new_stroke: '', new_arthiritis: '', 
    new_depression: ''
  });

  useEffect(() => {
    props.passBenefitsData(benefits);
  }, [benefits, props]);

  useEffect(() => {
    setBenefits(props.getData);
  }, [props.getData]);

  const { user } = useAuthContext();
  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
    user = user
      ? JSON.parse(user)
      : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  }, []);

  // Function to convert risk ratio to percentage
  const convertToPercentage = (ratio) => {
    if (ratio) {
      const percentage = (ratio - 1) * 100;
      return `${percentage > 0 ? "+" : ""}${percentage.toFixed(0)}%`;
    }
    return "";
  };

  return (
    <section>
      <div className= "benefits-table"> 
      <div className="row border border-secondary">
          
        <div className="col-5 border-right border-secondary benefit-label-font">
          <strong>Benefits</strong>
        </div>
        <div className="col border-right border-secondary">
          <strong className="curr_label"></strong>
        </div>
        <div className="col border-right border-secondary">
          <strong className="opt_label"></strong>
        </div>
        <div className="col">
          <strong>New</strong>
        </div>
      </div>

      <div className="row border border-secondary">
        <div className="col-5 border-right border-secondary benefit-label-font">
          Lifespan (in years)
        </div>
        <div className="col border-right border-secondary">
          {benefits.curr_lifespan}
        </div>
        <div className="col border-right border-secondary">
          {benefits.opt_lifespan}
        </div>
        <div className="col">
          {benefits.new_lifespan}
        </div>
      </div>

      <div className="row border border-secondary">
        <div className="col-5 border-right border-secondary benefit-label-font">
          Cancer
        </div>
        <div className="col border-right border-secondary">
          {convertToPercentage(benefits.curr_cancer)}
        </div>
        <div className="col border-right border-secondary">
          {convertToPercentage(benefits.opt_cancer)}
        </div>
        <div className="col">
          {convertToPercentage(benefits.new_cancer)}
        </div>
      </div>

      <div className="row border border-secondary">
        <div className="col-5 border-right border-secondary benefit-label-font">
          Type 2 Diabetes
        </div>
        <div className="col border-right border-secondary">
          {convertToPercentage(benefits.curr_type_2_diabetes)}
        </div>
        <div className="col border-right border-secondary">
          {convertToPercentage(benefits.opt_type_2_diabetes)}
        </div>
        <div className="col">
          {convertToPercentage(benefits.new_type_2_diabetes)}
        </div>
      </div>

      {/* <div className="row border border-secondary">
        <div className="col-5 border-right border-secondary benefit-label-font">
            High Blood Pressure
        </div>
        <div className="col border-right border-secondary">
            {benefits.curr_high_blood_pressure}
        </div>
        <div className="col border-right border-secondary">
            {benefits.opt_high_blood_pressure}
        </div>
        <div className="col">
            {benefits.new_high_blood_pressure}
        </div>
      </div> */}

      <div className="row border border-secondary">
        <div className="col-5 border-right border-secondary benefit-label-font">
          Cardiovascular Disease
        </div>
        <div className="col border-right border-secondary">
          {convertToPercentage(benefits.curr_cardiovascular_disease)}
        </div>
        <div className="col border-right border-secondary">
          {convertToPercentage(benefits.opt_cardiovascular_disease)}
        </div>
        <div className="col">
          {convertToPercentage(benefits.new_cardiovascular_disease)}
        </div>
      </div>

      <div className="row border border-secondary">
        <div className="col-5 border-right border-secondary benefit-label-font">
          Stroke
        </div>
        <div className="col border-right border-secondary">
          {convertToPercentage(benefits.curr_stroke)}
        </div>
        <div className="col border-right border-secondary">
          {convertToPercentage(benefits.opt_stroke)}
        </div>
        <div className="col">
          {convertToPercentage(benefits.new_stroke)}
        </div>
      </div>

      {/* <div className="row border border-secondary benefit-label-font">
        <div className="col-5 border-right border-secondary">
            Arthiritis
        </div>
        <div className="col border-right border-secondary">
            {benefits.curr_arthiritis}
        </div>
        <div className="col border-right border-secondary">
            {benefits.opt_arthiritis}
        </div>
        <div className="col">
            {benefits.new_arthiritis}
        </div>
      </div> */}

      <div className="row border border-secondary">
        <div className="col-5 border-right border-secondary benefit-label-font">
          Depression
        </div>
        <div className="col border-right border-secondary">
          {convertToPercentage(benefits.curr_depression)}
        </div>
        <div className="col border-right border-secondary">
          {convertToPercentage(benefits.opt_depression)}
        </div>
        <div className="col">
          {convertToPercentage(benefits.new_depression)}
        </div>
      </div>
      {/* User guidance note */}
      <div className="note">
        <p><strong>Note:</strong> Values represent the percentage change. For example, +25% means the risk is 25% higher than the average, and -25% means the risk is 25% lower than the average.</p>
      </div>
    </div>    
    <RefreshButton />
    </section>

  );
};

export default Benefits;
