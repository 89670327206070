import React, { useState, useMemo, useEffect } from 'react';
import { View, Text, TouchableOpacity, ScrollView } from 'react-native';
import axios from 'axios'
import {getCookie} from "../../../utils/cookie"
import { fetchScores } from "../action";

export default function StressScale() {

  const [submittedToday, setSubmittedToday] = useState(true)
  const [selectedValues, setSelectedValues] = useState(Array(10).fill(''));
  const [showScore, setShowScore] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [unansweredQuestions, setUnansweredQuestions] = useState([]);
  const [highlightedQuestionIndex, setHighlightedQuestionIndex] = useState(null);

  useEffect(() => {
    const fetchTestScores = async (userId) => {
        try {
            const data = await fetchScores(userId)

            if (data.filter(data => data.testId === 'stress').length > 0) {
                const testDate = new Date(data.filter(data => data.testId === 'stress')[0].submission_date.substring(0,10).split('-')).toUTCString().substring(0,16)
                const currDate = new Date().toUTCString().substring(0,16)

                if (testDate === currDate) {
                  setSubmittedToday(true)
                } else {
                  setSubmittedToday(false)
                }
            } else {
              setSubmittedToday(false)
            }

        } catch (error) {
            console.error('Error fetching test scores', error)
        }
    }
    fetchTestScores(userId)
  }, [])
  const baseUrl = process.env.REACT_APP_baseUrl;
  const baseUrlAWS =`${baseUrl}api/myprogress`

  const userId = useMemo(() => {
    let user = localStorage.getItem("mysUser");
      user = user
        ? JSON.parse(user)
        : { userId: "64ad93a2-a276-43e3-8304-be5fbd587ca1" };
    return user.userId;
  })

 const questions = [
   '1. In the last month, how often have you been upset because of something that happened unexpectedly?',
   '2. In the last month, how often have you felt that you were unable to control the important things in your life?',
   '3. In the last month, how often have you felt nervous and "stressed"?',
   '4. In the last month, how often have you not felt confident about your ability to handle your personal problems?',
   '5. In the last month, how often have you felt that things were not going your way?',
   '6. In the last month, how often have you found that you could not cope with all the things that you had to do?',
   '7. In the last month, how often have you not been able to control irritations in your life?',
   '8. In the last month, how often have you felt that you were not on top of things?',
   '9. In the last month, how often have you been angered because of things that were outside of your control?',
   '10. In the last month, how often have you felt difficulties were piling up so high that you could not overcome them?',
 ];

 const categories = [
   { name: 'Never', value: 4 },
   { name: 'Almost Never', value: 3 },
   { name: 'Sometimes', value: 2 },
   { name: 'Fairly Often', value: 1 },
   { name: 'Very Often', value: 0 },
 ];

 const handleAnswerChange = (questionIndex, categoryIndex) => {
   const updatedValues = [...selectedValues];
   updatedValues[questionIndex] = categoryIndex;
   setSelectedValues(updatedValues);
   setHighlightedQuestionIndex(questionIndex); // Track selected question
 };

 const calculateTotalScore = () => {
   let score = 0;
   let unansweredQ = false
   selectedValues.forEach((selectedValue, questionIndex) => {
     const category = categories.find((cat) => cat.name === selectedValue);
     if (category) {
       score += category.value;
     } else {
        //unanswered question
        unansweredQ = true
     }
   });

   if (unansweredQ) {
    return -1
   } else {
    return score
   }
 };

 const handleSubmit = () => {
   const score = calculateTotalScore();
   
   if (score === -1) {
      // Highlight unanswered questions
      const unanswered = selectedValues.map((value, index) => (value === '' ? index : null)).filter(index => index !== null);
      setUnansweredQuestions(unanswered);
      return;
   } 

   setTotalScore(score);
   setShowScore(true);

   let testRes = {}
   testRes["user"] = userId
   testRes["testId"] = "stress"
   testRes["score"] = score

   submitData(testRes)
   console.log(testRes)

 };

 // call API to submit the form data 
 const submitData = (testData) => {
  const csrfToken = getCookie("csrftoken")
  console.log(JSON.stringify(testData))
  axios.post(
      `${baseUrlAWS}/create-progress-test-score-entry/`,
      testData,
      {
          headers: {
              "Content-Type": "application/json",
              "X-CSRFToken": csrfToken,
          },
      })
      .then(response => {
          console.log(response)
      })
      .catch(error => {
          if (error.response && error.response.status === 500) {
              return null
          } else {
              console.error('Error fetching form data:', error)
              throw error
          }
      })
  }

 return (
  <View style={{ flex: 1, padding: 20, paddingBottom: 130 }}>
  {/* Fixed Header */}
  <View style={{ 
    position: 'fixed', 
    top: 100, 
    left: 0, 
    right: 0, 
    backgroundColor: 'white', 
    padding: 10, 
    zIndex: 100,
    elevation: 5 // For Android shadow effect
  }}>
    <Text style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 10, textAlign: 'center', marginTop: 50 }}>
      Perceived Stress Scale
    </Text>
    <Text style={{ marginBottom: 10, fontWeight: 'bold', textAlign: 'center' }}>
      Below are a number of statements about perceived stress. Please answer each question accurately and submit to see score. 
      Higher scores indicate higher perceived stress.
    </Text>
    <View style={{ flexDirection: 'row', marginBottom: 14, width: '60%', justifyContent: 'space-between', marginLeft: '38%' }}>
      {categories.map((category, categoryIndex) => (
        <Text key={categoryIndex} style={{ textAlign: 'center', flex: 1 }}>
          {category.name}
        </Text>
      ))}
    </View>
  </View>

  {/* Scrollable Content */}
  <ScrollView contentContainerStyle={{ paddingTop: 150 }}>  
    {questions.map((question, questionIndex) => (
      <View
        key={questionIndex}
        style={{
          flexDirection: 'row',
          marginBottom: 14,
          backgroundColor: unansweredQuestions.includes(questionIndex) ? '#ffcccc' : (questionIndex % 2 === 1 ? '#f0f0f0' : '#ffffff'),
          minHeight: 50,
          paddingVertical: 8,
          paddingHorizontal: 10,
          borderRadius: 5,
        }}
      >
        <Text style={{ width: '38%', 
          fontWeight: highlightedQuestionIndex === questionIndex ? 'bold' : 'normal', // Make it bold when selected
          color: highlightedQuestionIndex === questionIndex ? '#04723A' : '#000000', // Change color when selected
          flexWrap: 'wrap', 
        }}>{question}</Text>
        
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', flex: 1, marginLeft: '5%', marginRight: '7%' }}>
          {categories.map((category, categoryIndex) => {
            const isSelected = selectedValues[questionIndex] === category.name;
            return (
              <TouchableOpacity
                key={categoryIndex}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 50,
                  width: 20,
                  height: 20,
                  borderWidth: 1,
                  borderColor: '#000000',
                  backgroundColor: isSelected ? '#c0c0c0' : '#ffffff',
                  marginLeft: categoryIndex === 0 ? 0 : '8.6%',
                }}
                onPress={() => handleAnswerChange(questionIndex, category.name)}
              >
                {isSelected && (
                  <View
                    style={{
                      width: 12,
                      height: 12,
                      borderRadius: 6,
                      backgroundColor: '#000000',
                    }}
                  />
                )}
              </TouchableOpacity>
            );
          })}
        </View>
      </View>
    ))}
    
    {(!showScore && !submittedToday) && (
      <TouchableOpacity
        style={{
          alignItems: 'center',
          backgroundColor: '#ffffff',
          borderColor: '#000000',
          borderWidth: 1,
          borderRadius: 5,
          paddingVertical: 5,
          paddingHorizontal: 5,
          width: '10%',
          alignSelf: 'center',
          marginTop: 10,
        }}
        onPress={handleSubmit}
      >
        <Text style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 12 }}>Submit</Text>
      </TouchableOpacity>
    )}

    {(showScore || submittedToday) && (
      <TouchableOpacity
        style={{
          alignItems: 'center',
          backgroundColor: '#ffffff',
          borderColor: '#000000',
          borderWidth: 1,
          borderRadius: 5,
          paddingVertical: 5,
          paddingHorizontal: 5,
          width: '10%',
          alignSelf: 'center',
          marginTop: 10,
        }}
      >
        <Text style={{ textTransform: 'uppercase', fontWeight: 'bold', fontSize: 12 }}>Already Submitted Today</Text>
      </TouchableOpacity>
    )}

    {showScore && (
      <>
        <Text style={{ marginTop: 10, textAlign: 'center', fontWeight: 'bold' }}>
          Total Score: {totalScore} / 40 
        </Text>
      </>
    )}
  </ScrollView>
  </View>

 )
}